import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal, Button, ProgressBar } from 'react-bootstrap';
import { fetchActivityData, speakCharacter } from '../utils/activityUtils.js';
import './SingleSpeakingActivity.css';

const SingleSpeakingActivity = () => {
  const { schoolName, className, subjectName, lessonName, activityName } =
    useParams();
  const navigate = useNavigate();
  const [activityData, setActivityData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [autoPlay, setAutoPlay] = useState(false);
  const [isTouched, setIsTouched] = useState(false); // State to handle animation

  useEffect(() => {
    const loadActivity = async () => {
      const activity = await fetchActivityData(
        schoolName,
        className,
        subjectName,
        lessonName,
        activityName
      );
      setActivityData(activity);
    };
    loadActivity();
  }, [schoolName, className, subjectName, lessonName, activityName]);

  const handleClose = () => {
    navigate(
      `/kids/ssc/${schoolName}/${className}/${subjectName}/${lessonName}`
    );
  };

  const handleNextCharacter = () => {
    if (isSpeaking) return;

    setIsTouched(true); // Trigger bounce animation

    setTimeout(() => setIsTouched(false), 2000); // Reset animation after 500ms

    if (currentIndex < activityData?.list.length) {
      speakCharacter(
        activityData.list[currentIndex],
        activityData,
        isSpeaking,
        setIsSpeaking,
        () => {
          const nextIndex = currentIndex + 1;

          if (nextIndex < activityData.list.length) {
            setCurrentIndex(nextIndex);
          } else {
            handleClose();
          }
        }
      );
    }
  };

  useEffect(() => {
    if (autoPlay && currentIndex < activityData?.list.length) {
      setTimeout(() => handleNextCharacter(), 2000);
    }
  }, [currentIndex, autoPlay]);

  if (!activityData) {
    return (
      <Modal show centered size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Activity Not Found</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>Sorry, the selected activity does not exist.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Back to Lessons
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal show centered size="lg" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{activityName}</Modal.Title>
      </Modal.Header>

      <ProgressBar
        now={(currentIndex / activityData.list.length) * 100}
        label={`${Math.round((currentIndex / activityData.list.length) * 100)}%`}
        variant="success"
      />

      {/* Main Interactive Section */}
      <Modal.Body
        className="text-center position-relative"
        style={{ backgroundColor: activityData.color, cursor: 'pointer' }}
        onClick={!autoPlay ? handleNextCharacter : undefined}
      >
        {/* Finger Touch Emoji Overlay */}
        <span
          className={`finger-touch ${isTouched ? 'bounce' : ''}`}
          style={{
            position: 'absolute',
            top: '50%',
            left: '20%',
            fontSize: '12rem',
            pointerEvents: 'none', // Avoid interfering with clicks
          }}
        >
          👆
        </span>

        {/* Main Text */}
        <h1
          className={`fixed-text text-highlight ${isSpeaking ? 'animate-text' : ''}`}
          style={{
            cursor: isSpeaking ? 'not-allowed' : 'pointer',
            fontSize: '12rem',
            color: '#ffffff',
          }}
        >
          {activityData.multiLingualTEEN
            ? activityData.list[currentIndex].emoji ||
              activityData.list[currentIndex].english
            : activityData.list[currentIndex]}
        </h1>

        <p style={{ color: '#ffffff' }}>
          {autoPlay ? 'Auto-playing...' : 'Click to hear & move to the next.'}
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant={autoPlay ? 'danger' : 'success'}
          onClick={() => setAutoPlay(!autoPlay)}
        >
          {autoPlay ? 'Stop Auto-Play' : 'Start Auto-Play'}
        </Button>
        <Button variant="secondary" onClick={handleClose} disabled={isSpeaking}>
          Close
        </Button>
      </Modal.Footer>

      {/* CSS Styles for Bounce Animation */}
      <style>
        {`
          @keyframes bounce {
            0% { transform: translate(-50%, -120%) scale(1); }
            50% { transform: translate(-50%, -130%) scale(1.2); }
            100% { transform: translate(-50%, -120%) scale(1); }
          }
          
          .bounce {
            animation: bounce 0.5s ease-in-out;
          }
        `}
      </style>
    </Modal>
  );
};

export default SingleSpeakingActivity;
