import { openDB } from 'idb';

const DB_NAME = 'FamilyDB';
const STORE_NAME = 'familyImages';

// ----------------------------------------
// Open IndexedDB Database
// ----------------------------------------
const openDatabase = async () => {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, {
          keyPath: 'id',
          autoIncrement: true,
        });
      }
    },
  });
};

// ----------------------------------------
// Fetch All Family Images
// ----------------------------------------
export const fetchMyFamilyImages = async () => {
  try {
    const db = await openDatabase();
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);
    return await store.getAll();
  } catch (error) {
    console.error('❌ Error fetching family images:', error);
    return [];
  }
};

// ----------------------------------------
// Export Family Data as JSON
// ----------------------------------------
export const exportMyFamilyImages = async () => {
  try {
    const familyImages = await fetchMyFamilyImages();

    if (!familyImages || familyImages.length === 0) {
      console.warn('⚠️ No family images to export.');
      return null;
    }

    // Get current date and time
    const now = new Date();
    const formattedDate = now.toISOString().replace(/[-T:.Z]/g, '');

    // Append metadata
    const exportData = {
      exportedAt: now.toLocaleString(), // Human-readable timestamp
      totalRecords: familyImages.length,
      data: familyImages,
    };

    // Convert to JSON and create Blob
    const dataStr = JSON.stringify(exportData, null, 2);
    const blob = new Blob([dataStr], { type: 'application/json' });

    // Create dynamic file name with timestamp
    const filename = `hlslive_familyImages_${formattedDate}.json`;

    // Create download link
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    console.log(
      '✅ Family images exported successfully at',
      now.toLocaleString()
    );
  } catch (error) {
    console.error('❌ Error exporting family images:', error);
  }
};

// ----------------------------------------
// Import Family Data from JSON
// ----------------------------------------
export const importMyFamilyImages = async (file) => {
  try {
    const db = await openDatabase();
    const reader = new FileReader();

    reader.onload = async (event) => {
      try {
        const importedData = JSON.parse(event.target.result);

        // Validate structure
        if (!importedData.data || !Array.isArray(importedData.data)) {
          throw new Error(
            'Invalid file format. Expected a structured JSON with a "data" array.'
          );
        }

        console.log(
          `📥 Importing ${importedData.totalRecords} records from ${importedData.exportedAt}`
        );

        const tx = db.transaction(STORE_NAME, 'readwrite');
        const store = tx.objectStore(STORE_NAME);

        // Clear existing data before importing
        await store.clear();

        // Add new imported records
        for (const item of importedData.data) {
          await store.add(item);
        }

        console.log('✅ Family images imported successfully!');
      } catch (error) {
        console.error('❌ Error processing import file:', error);
      }
    };

    reader.readAsText(file);
  } catch (error) {
    console.error('❌ Error importing family images:', error);
  }
};
