export const questionsAndAnswersLessonSeven = [
  {
    question: 'Who was Mullah Nasreddin? Why was he considered mysterious?',
    answer:
      'Mullah Nasreddin was a local priest in a small village. He was considered mysterious because of his strange activities. He was deeply involved in spiritual pursuits, and material comforts occupied a secondary place in his life.',
  },
  {
    question: 'Why was Mullah Nasreddin forced to sell his ancestral house?',
    answer:
      "Mullah Nasreddin's financial difficulties forced him to sell his ancestral house to his crooked neighbour.",
  },
  {
    question: 'Who wanted to buy the house? How did he get it from the Mullah?',
    answer:
      "Nasreddin's neighbour wanted to buy the house. He got it from the Mullah with a pitiful price and with a small clause in the contract by the Mullah.",
  },
  {
    question: 'What clause did Nasreddin add to the contract?',
    answer:
      'Nasreddin added the clause that he should be allowed to keep the ownership of the nail on the wall of the living room and do whatever he wanted to do with it.',
  },
  {
    question: "Why was the neighbour's wife angry with him?",
    answer:
      'She was angry because Nasreddin kept returning to worship the nail. The first time he hung his turban, then his scarf, the coat, and ultimately brought the sack full of cow dung to hang. Obviously, the wife was furious with her husband. She threatened to leave him.',
  },
  {
    question:
      'Why did the neighbour call for a meeting with the council of elders? What ruling did they give?',
    answer:
      'The neighbour felt that Nasreddin had crossed his limits, so he called for a meeting of the council of elders. The assembly of wise men studied the contract between him and Nasreddin. They found nothing wrong with what Nasreddin had done and eventually dismissed the case. The neighbour lost the case.',
  },
  {
    question: 'How did Mullah Nasreddin get his house back?',
    answer:
      "The neighbour's wife refused to allow Mullah to continue worshipping the nail in spite of the wise men's verdict. There were long arguments between the husband and wife. Finally, the neighbour asked Mullah to buy his house back at a bargain price. Nasreddin happily agreed and moved back into his house.",
  },
];
