import { getAllSchools } from '../../db/indexedDB.js';
import { schoolDetails } from '../../ssc/data/schools.js';

/** 📌 Fetch activity data from both database and static data */
export const fetchActivityData = async (
  schoolName,
  className,
  subjectName,
  lessonName,
  activityName
) => {
  const data = await getAllSchools();
  const schoolFromDB = data.find((s) => s.schoolName === schoolName);
  const classFromDB = schoolFromDB?.classes.find(
    (c) => c.className === className
  );
  const subjectFromDB = classFromDB?.subjects.find(
    (s) => s.subjectName === subjectName
  );
  const lessonFromDB = subjectFromDB?.lessons.find(
    (l) => l.lessonName === lessonName
  );
  const activityFromDB = lessonFromDB?.activities.find(
    (a) => a.activityName === activityName
  );

  // Find static activity data
  const staticSchoolData = schoolDetails.find(
    (s) => s.schoolName === schoolName
  );
  const staticClassData = staticSchoolData?.classes.find(
    (c) => c.className === className
  );
  const staticSubjectData = staticClassData?.subjects.find(
    (s) => s.subjectName === subjectName
  );
  const staticLessonData = staticSubjectData?.lessons.find(
    (l) => l.lessonName === lessonName
  );
  const staticActivityData = staticLessonData?.activities.find(
    (a) => a.activityName === activityName
  );

  return activityFromDB || staticActivityData;
};

/** 📌 Speak character using Web Speech API */
export const speakCharacter = (
  char,
  activityData,
  isSpeaking,
  setIsSpeaking,
  callback
) => {
  if (isSpeaking) return;

  setIsSpeaking(true);
  const synth = window.speechSynthesis;
  const utterance = new SpeechSynthesisUtterance();
  utterance.rate = 0.6;

  if (activityData?.multiLingualTEEN) {
    utterance.text = char.english;
    utterance.lang = 'en-US';
    synth.speak(utterance);

    utterance.onend = () => {
      const teluguUtterance = new SpeechSynthesisUtterance(char.telugu);
      teluguUtterance.lang = 'te-IN';
      teluguUtterance.rate = 0.6;
      synth.speak(teluguUtterance);

      teluguUtterance.onend = () => {
        setIsSpeaking(false);
        if (callback) callback();
      };
    };
  } else {
    utterance.text = char;
    utterance.lang = activityData?.lang === 'Telugu' ? 'te-IN' : 'en-US';
    utterance.rate = 0.6;

    utterance.onend = () => {
      setIsSpeaking(false);
      if (callback) callback();
    };

    synth.speak(utterance);
  }
};
