import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Form, Card } from 'react-bootstrap';
import { SchoolActivityContentSplitter } from '../../enums/constants.js';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css'; // Import KaTeX CSS for styling

function isMobileDevice() {
  if (typeof navigator === 'undefined') return false;
  return /Mobi|Android/i.test(navigator.userAgent);
}

const MathsPracticeExerciseSolution = ({ activityData = null }) => {
  const [userInput, setUserInput] = useState('Answer: ' + activityData?.answer);

  // eslint-disable-next-line no-unused-vars
  const [language, setLanguage] = useState(activityData?.lang);
  const [voice, setVoice] = useState(null);
  const [preloadedUtterances, setPreloadedUtterances] = useState({});

  const [words, setWords] = useState(
    userInput.replace(/\n/g, ' ').trim().split(' ')
  );
  const [predictedParagraph, setPredictedParagraph] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentWordSet, setCurrentWordSet] = useState([]);
  const [isStarted, setIsStarted] = useState(false);
  const [isRecollecting, setIsRecollecting] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isSpeaking, setSpeaking] = useState(false);

  // NEW: Track auto-playing
  const [isAutoPlaying, setIsAutoPlaying] = useState(false);
  const [activityTitle, setActivityTitle] = useState(false);
  const [isMathsContentType, setIsMathsContentType] = useState(false);
  const [
    finalFormattedPredictedParagraph,
    setFinalFormattedPredictedParagraph,
  ] = useState('');

  const synth = window.speechSynthesis;
  const mobile = isMobileDevice();

  /**
   * This function takes a predicted plain text paragraph (formattedParagraph)
   * and applies block-level markdown styling based on the original markdown
   * present in the userInput string (line-by-line).
   *
   * @param {string} formattedParagraph - The plain text predicted paragraph.
   * @param {string} userInput - The original text with markdown styling.
   * @returns {string} - A new paragraph with markdown re-applied.
   */
  const applyMarkdownToFormattedParagraph = (formattedParagraph, userInput) => {
    // Split both texts into lines.
    const userInputLines = userInput.split('\n');
    const predictedLinesRaw = formattedParagraph.split('\n');
    const numLines = Math.min(userInputLines.length, predictedLinesRaw.length);
    const updatedLines = [];

    // Regex to detect block-level prefixes (headers, blockquotes, list markers, ordered list markers).
    const blockRegex = /^(\s*(?:#+\s*|>\s*|[-*+]\s*|(?:\d+\.)+\s*))/;
    // Regex to detect bold inline markdown (e.g., **text**)
    const boldRegex = /(\*\*)(.+?)(\*\*)/g;

    for (let i = 0; i < numLines; i++) {
      const originalLine = userInputLines[i] || '';
      let predictedLine = predictedLinesRaw[i] || '';

      // Extract block-level markdown prefix from the original line.
      const prefixMatch = originalLine.match(blockRegex);
      const blockPrefix = prefixMatch ? prefixMatch[0] : '';

      // Remove any duplicate block-level prefix from the predicted line.
      predictedLine = predictedLine.replace(blockRegex, '');

      // Reapply bold inline formatting from the original line.
      // Find all bold segments (using ** markers) in the original line.
      const boldMatches = [...originalLine.matchAll(boldRegex)];
      boldMatches.forEach((match) => {
        const fullMatch = match[0]; // e.g., **805,012**
        const innerText = match[2]; // e.g., 805,012

        // Create a regex for the inner text (escaping special regex characters).
        const escapedInnerText = innerText.replace(
          /[.*+?^${}()|[\]\\]/g,
          '\\$&'
        );
        const innerTextRegex = new RegExp(escapedInnerText);
        // Replace the first occurrence of the inner text in the predicted line with the bold formatted version.
        predictedLine = predictedLine.replace(innerTextRegex, fullMatch);
      });

      // Prepend the block prefix only if the predicted line is non-empty.
      if (
        predictedLine.trim().length > 0 &&
        !predictedLine.startsWith(blockPrefix)
      ) {
        predictedLine = blockPrefix + predictedLine;
      }

      updatedLines.push(predictedLine);
    }

    return updatedLines.join('\n');
  };

  // Format predicted paragraph to match original content line breaks
  const formatPredictedParagraph = () => {
    console.log(`userInput: ${userInput}`);
    const originalLines = userInput.split('\n');
    console.log(`Original Lines: ${JSON.stringify(originalLines, null, 4)}`);

    // predictedParagraph is an array of correct words set by handleWordClick.
    let predictedIndex = 0;

    const formattedParagraph = originalLines
      .map((line) => {
        // If the line is completely empty or just spaces, keep it as is.
        if (line.trim() === '') {
          return '';
        }

        // Tokenize the line, preserving spaces.
        const tokens = line.match(/\s+|\S+/g) || [];

        // Count the number of word tokens (non-whitespace tokens) in this line.
        const wordCount = tokens.filter((token) => !/\s+/.test(token)).length;

        // Slice out exactly the words for this line from predictedParagraph.
        const linePredictedTokens = predictedParagraph.slice(
          predictedIndex,
          predictedIndex + wordCount
        );

        // Advance the global index by the number of words in the line.
        predictedIndex += wordCount;

        // Replace non-space tokens with the predicted tokens (if available).
        let localWordIndex = 0;
        const formattedLine = tokens
          .map((token) => {
            if (/\s+/.test(token)) {
              // Preserve whitespace exactly.
              return token;
            } else {
              const replacement = linePredictedTokens[localWordIndex++] || '';
              return replacement;
            }
          })
          .join('');

        return formattedLine;
      })
      .join('\n');

    console.log(`\nFinal Speaking formattedParagraph:\n${formattedParagraph}`);
    const formattedFinalPredictedParagraph = applyMarkdownToFormattedParagraph(
      formattedParagraph,
      userInput
    );
    setFinalFormattedPredictedParagraph(formattedFinalPredictedParagraph);
    // return formattedFinalPredictedParagraph;
  };

  const contentRef = useRef(null);

  // Function to remove Markdown syntax (LaTeX, code blocks, etc.)
  const stripMarkdown = (text) => {
    return text
      .replace(/```[\s\S]*?```/g, '') // Remove fenced code blocks
      .replace(/`[^`]*`/g, '') // Remove inline code
      .replace(/\$\$[\s\S]*?\$\$/g, '') // Remove LaTeX block math ($$...$$)
      .replace(/\$[^$]*\$/g, '') // Remove inline LaTeX ($...$)
      .replace(/\\!\[.*?\]\(.*?\)/g, '') // Remove images ![alt](url)
      .replace(/\[.*?\]\(.*?\)/g, '') // Remove links [text](url)
      .replace(/#{1,6}\s*/g, '') // Remove Markdown headers
      .replace(/>+/g, '') // Remove blockquotes
      .replace(/[*_~`]+/g, ''); // Remove formatting (*, _, ~, `)
  };

  function removeLatexCommands(input) {
    const latexCommands = [
      '\\Big',
      '\\frac',
      '\\left',
      '\\right',
      '\\boxed',
      '\\underbrace',
      '\\text',
    ];

    let cleaned = input;

    latexCommands.forEach((cmd) => {
      const regex = new RegExp(cmd.replace(/\\/g, '\\\\'), 'g');
      cleaned = cleaned.replace(regex, '');
    });

    return cleaned;
  }

  const loadMarkdownFile = async (lessonNo, ExerciseID, psName) => {
    try {
      // Construct the dynamic module path
      const modulePath = `../../ssc/data/schoolData/stJosephsHighSchool/constants/class6/Maths/${lessonNo}/${ExerciseID}/${psName}`;
      console.log(`Loading content for concept File PATH: ${modulePath}`);

      // Dynamically import the module
      const { problemSolution } = await import(
        /* webpackInclude: /\.js$/ */
        `../../ssc/data/schoolData/stJosephsHighSchool/constants/class6/Maths/${lessonNo}/${ExerciseID}/${psName}`
      );

      console.log(`problemSolution: ${problemSolution}`);
      return problemSolution;
    } catch (error) {
      console.error(`Error loading module: ${error.message}`);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      const filePaths = activityData.prompt.split('\\');
      let problemSolution = await loadMarkdownFile(
        filePaths[0],
        filePaths[1],
        filePaths[2]
      );
      problemSolution = problemSolution
        .replaceAll('\\div', '÷')
        .replaceAll('\\times', 'x');
      const questionPreFixList = filePaths[2].split('___');
      console.log(`questionPreFixList: ${questionPreFixList}`);
      const questionPreFix = questionPreFixList[0].split('_').join(' ');
      console.log(`questionPreFix: ${questionPreFix}`);
      setActivityTitle(questionPreFix);
      // Process text without Markdown
      let processedProblemSolution = stripMarkdown(problemSolution);
      const questionAnswer = `**Question:**\n\n ${activityData.question}\n\n **Answer:**\n${problemSolution}`;
      let processedQuestionAnswer = '';

      if (
        problemSolution.includes('\\Big') ||
        problemSolution.includes('\\frac') ||
        problemSolution.includes('\\left') ||
        problemSolution.includes('\\right') ||
        problemSolution.includes('\\boxed') ||
        problemSolution.includes('\\underbrace') ||
        problemSolution.includes('\\text')
      ) {
        setIsMathsContentType(true);
        processedProblemSolution = stripMarkdown(
          removeLatexCommands(processedProblemSolution)
        );
        processedQuestionAnswer = `Question:\n\n ${activityData.question}\n\n Answer:\n ${processedProblemSolution}`;
        setUserInput(processedQuestionAnswer);
      } else {
        // Providing Markdown Text
        setUserInput(questionAnswer);
        processedQuestionAnswer = `Question:\n\n ${activityData.question}\n\n Answer:\n ${processedProblemSolution}`;
      }

      setWords(processedQuestionAnswer.replace(/\n/g, ' ').trim().split(' '));
    };

    loadData();
  }, []);

  // Adding to handle maths formulas rendering in markdown
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        window.MathJax &&
        window.MathJax.startup &&
        window.MathJax.startup.promise
      ) {
        window.MathJax.startup.promise.then(() => {
          window.MathJax.typesetPromise();
        });
      }
    }, 100); // Delay in milliseconds (adjust as needed)

    return () => clearTimeout(timeout); // Clean up on unmount
  }, []);

  useEffect(() => {
    console.log(
      '[useEffect] formattedParagraph changed, scheduling MathJax typesetting...'
    );

    const timeout = setTimeout(() => {
      console.log('[useEffect -> setTimeout] Checking for MathJax');

      if (
        window.MathJax &&
        window.MathJax.startup &&
        window.MathJax.startup.promise &&
        contentRef.current // ✅ Ensure ref is available
      ) {
        console.log('[MathJax] Found MathJax, triggering typesetPromise');
        window.MathJax.startup.promise.then(() => {
          console.log('[MathJax] Running typesetPromise...');
          window.MathJax.typesetPromise([contentRef.current])
            .then(() => {
              console.log('[MathJax] typesetPromise completed!');
            })
            .catch((err) => {
              console.error('[MathJax] typesetPromise error:', err);
            });
        });
      } else {
        console.warn(
          '[MathJax] MathJax not ready or contentRef.current is null!'
        );
      }
    }, 1000); // You can adjust this delay

    return () => {
      console.log('[useEffect] Clearing timeout');
      clearTimeout(timeout);
    };
  }, [finalFormattedPredictedParagraph]);

  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = synth.getVoices();
      if (availableVoices.length > 0) {
        // Map the language to a locale code if needed
        const languageMap = {
          English: 'en-US',
          Hindi: 'hi-IN',
          Telugu: 'te-IN',
          Tamil: 'ta-IN',
        };
        // Use the mapped value or the language directly (or default to 'en-US')
        const selectedLanguage = languageMap[language] || language || 'en-US';
        const defaultVoice = availableVoices.find(
          (v) => v.lang === selectedLanguage
        );
        setVoice(defaultVoice || availableVoices[0]);
      }
    };

    loadVoices();
    synth.onvoiceschanged = loadVoices;

    return () => {
      synth.onvoiceschanged = null;
    };
  }, [language]);

  useEffect(() => {
    if (isStarted || isRecollecting || isAutoPlaying) {
      // Preload for the first (or current) index whenever we start or recollect
      preloadUtterances(currentWordIndex);
    }
  }, [isStarted, isRecollecting, isAutoPlaying]);

  // NEW: Auto-play effect
  // Whenever `isAutoPlaying` is true, and we are not currently speaking,
  // automatically select the correct word until the content is completed.
  useEffect(() => {
    if (isAutoPlaying && isStarted && currentWordIndex < words.length) {
      // Automatically "click" the correct word
      handleWordClick(words[currentWordIndex]);
    }
  }, [isAutoPlaying, currentWordIndex, isStarted, words]);

  const preloadUtterances = (startIndex) => {
    const newUtterances = {};
    // Load a few words ahead (here just 1 for demonstration)
    for (let i = startIndex; i < startIndex + 1 && i < words.length; i++) {
      const cleanWord = words[i].replace(/\\/g, '');
      const utterance = new SpeechSynthesisUtterance(cleanWord);
      utterance.lang = language;
      utterance.voice = voice;

      // Recommended Speech Rate for Kids:
      // Toddlers (2-4 years old): 0.5 - 0.7 (Slower to help with word recognition)
      // Young Kids (5-7 years old): 0.6 - 0.8 (Still slower but more natural)
      // Older Kids (8-12 years old): 0.8 - 1.0 (Closer to normal speech speed)
      // Teenagers (13+ years old): 1.0 - 1.2 (Near or slightly above normal speed)

      utterance.rate = 0.7; // Slower but clear

      newUtterances[i] = utterance;
    }
    setPreloadedUtterances((prev) => ({ ...prev, ...newUtterances }));
  };

  const speakText = (textIndex, callback) => {
    // Cancel any ongoing speech first
    if (synth.speaking) {
      synth.cancel();
    }

    if (preloadedUtterances[textIndex] === Object({})) {
      preloadUtterances(textIndex);
    }
    const utterance = preloadedUtterances[textIndex];
    console.log(
      `preloadedUtterances: ${JSON.stringify(preloadedUtterances, null, 4)}`
    );

    // Set speaking status to true
    setSpeaking(true);
    if (utterance) {
      utterance.onend = () => {
        setSpeaking(false);
        if (callback) callback();
      };
      utterance.onerror = (event) => {
        console.error('Speech synthesis error:', event.error);
        setSpeaking(false);
        if (callback) callback();
      };
      synth.speak(utterance);
    } else {
      console.error('No utterance found for index:', textIndex);
      setSpeaking(false);
      if (callback) callback();
    }
  };

  const generateWordSet = (correctIndex) => {
    // Remove backslashes from the correct word
    const correctWord = words[correctIndex].replace(/\\/g, '');

    // Filter and clean random words (remove \ and empty ones)
    const randomWords = words
      .map((w, index) => ({ word: w.replace(/\\/g, ''), index })) // clean while preserving index
      .filter(
        ({ word, index }) =>
          index !== correctIndex && word !== correctWord && word.trim() !== ''
      )
      .map(({ word }) => word); // extract just the cleaned word

    // Get 2 random distractors
    const distractors = randomWords.sort(() => Math.random() - 0.5).slice(0, 2);

    // Return a shuffled array with the correct word and distractors
    return [correctWord, ...distractors].sort(() => Math.random() - 0.5);
  };

  const startGame = () => {
    if (synth.speaking) synth.cancel();
    setIsCompleted(false);
    setIsStarted(true);
    setIsRecollecting(false);
    setIsAutoPlaying(false); // Make sure auto-play is off if user chooses normal start
    setPredictedParagraph([]);
    setCurrentWordIndex(0);
    preloadUtterances(0);
    setCurrentWordSet(generateWordSet(0));
  };

  const resetGame = () => {
    if (synth.speaking) synth.cancel();
    setIsStarted(false);
    setIsRecollecting(false);
    setIsCompleted(false);
    setIsAutoPlaying(false);
    setPredictedParagraph([]);
    setCurrentWordIndex(0);
    setCurrentWordSet([]);
    setPreloadedUtterances({});
  };

  const startRecollection = () => {
    if (synth.speaking) synth.cancel();
    setIsCompleted(false);
    setIsRecollecting(true);
    setIsStarted(false);
    setIsAutoPlaying(false);
    setPredictedParagraph([]);
    setCurrentWordIndex(0);
    preloadUtterances(0);
    setCurrentWordSet(generateWordSet(0));
  };

  // NEW: Auto start game with auto-playing set to true
  const autoStartGame = () => {
    if (synth.speaking) synth.cancel();
    setIsCompleted(false);
    setIsStarted(true);
    setIsRecollecting(false);
    setIsAutoPlaying(true); // Turn on auto-play
    setPredictedParagraph([]);
    setCurrentWordIndex(0);
    preloadUtterances(0);
    setCurrentWordSet(generateWordSet(0));
  };

  // Handle word selection (manual or auto)
  const handleWordClick = (word) => {
    const correctWord = words[currentWordIndex];
    if (word === correctWord) {
      speakText(currentWordIndex, () => {
        if (correctWord.trim() !== '') {
          setPredictedParagraph((prev) => [...prev, correctWord]);
          // Added formatPredictedParagraph calling as speaking is finished
          formatPredictedParagraph();
        }
        const nextIndex = currentWordIndex + 1;

        if (nextIndex < words.length) {
          setCurrentWordIndex(nextIndex);
          setCurrentWordSet(generateWordSet(nextIndex));
          preloadUtterances(nextIndex);
        } else {
          setCurrentWordSet([]);
          setIsCompleted(true);
          setIsStarted(false);
          setIsAutoPlaying(false); // Stop auto-playing if we reached the end
        }
      });
    }
  };

  const formatParagraph = () => {
    return userInput
      .split('\n')
      .map((line) => {
        if (line.includes(SchoolActivityContentSplitter.QA_ACTIVITY_SPLITTER)) {
          const [questionPart, answerPart] = line.split(
            SchoolActivityContentSplitter.QA_ACTIVITY_SPLITTER
          );
          return `${questionPart},\nAnswer${answerPart}`;
        }
        return line;
      })
      .join('\n');
  };

  return (
    <Container
      fluid
      style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}
    >
      {/* Content Input Section */}
      {!isStarted && !isRecollecting && (
        <Row className="mb-4">
          <Col md={{ span: 8, offset: 2 }}>
            <Card>
              <Card.Header as="h4">📝 {activityTitle}:</Card.Header>
              <Card.Body>
                <Form.Group controlId="userInput">
                  <div
                    style={{
                      border: '1px solid #ced4da',
                      borderRadius: '0.25rem',
                      padding: '0.75rem',
                      height: '250px', // Fixed height
                      overflowY: 'auto', // Makes content scrollable vertically
                      backgroundColor: '#f8f9fa',
                      textAlign: 'left',
                    }}
                  >
                    {isMathsContentType ? (
                      <div>
                        {formatParagraph()
                          .split('\n')
                          .map((line, index) => {
                            const trimmed = line.trim();
                            return trimmed ? (
                              <React.Fragment key={index}>
                                {trimmed}
                                <br />
                              </React.Fragment>
                            ) : null; // skip completely empty lines
                          })}
                      </div>
                    ) : (
                      <ReactMarkdown
                        remarkPlugins={[remarkMath]}
                        rehypePlugins={[rehypeKatex]}
                      >
                        {formatParagraph()}
                      </ReactMarkdown>
                    )}
                  </div>
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {/* Game Controls */}
      <Row className="mb-4 text-center">
        <Col>
          {!isStarted && !isRecollecting && (
            <>
              <Button onClick={startGame} variant="primary" className="me-2">
                🚀 Start
              </Button>
              {/* NEW: Auto Start Button */}
              <Button
                onClick={autoStartGame}
                variant="success"
                className="me-2"
              >
                ⚡ Auto Start
              </Button>
              <Button
                onClick={startRecollection}
                variant="info"
                className="me-2"
              >
                🔄 Recollect
              </Button>
            </>
          )}
          {(isStarted || isRecollecting || isAutoPlaying) && (
            <Button onClick={resetGame} variant="danger" className="me-2">
              🔁 Reset
            </Button>
          )}
        </Col>
      </Row>

      {/* Active Game Section */}
      {(isStarted || isRecollecting) && (
        <>
          {/* Predicted Paragraph */}
          <Row className="mb-4">
            <Col md={{ span: 8, offset: 2 }}>
              <Card>
                <Card.Header as="h4">📝 {activityTitle}:</Card.Header>
                <Card.Body>
                  <Form.Group controlId="predictedParagraph">
                    <div
                      style={{
                        border: '1px solid #ced4da',
                        borderRadius: '0.25rem',
                        padding: '0.75rem',
                        height: '250px', // Fixed height
                        overflowY: 'auto', // Makes content scrollable vertically
                        backgroundColor: '#f8f9fa',
                        textAlign: 'left',
                      }}
                    >
                      {isMathsContentType ? (
                        <div ref={contentRef}>
                          {finalFormattedPredictedParagraph
                            .split('\n')
                            .map((line, index) => {
                              const trimmed = line.trim();
                              return trimmed ? (
                                <React.Fragment key={index}>
                                  {trimmed}
                                  <br />
                                </React.Fragment>
                              ) : null; // skip completely empty lines
                            })}
                        </div>
                      ) : (
                        <ReactMarkdown
                          remarkPlugins={[remarkMath]}
                          rehypePlugins={[rehypeKatex]}
                        >
                          {finalFormattedPredictedParagraph}
                        </ReactMarkdown>
                      )}
                    </div>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Word Buttons */}
          {!isCompleted && (
            <Row className="justify-content-center">
              <Col xs="auto">
                <Card>
                  <Card.Body
                    style={{
                      display: 'flex',
                      gap: '10px',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                    }}
                  >
                    {currentWordSet.map((word, index) => (
                      <Button
                        key={index}
                        variant="light"
                        style={{
                          padding: '10px',
                          borderRadius: '5px',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                          color: isRecollecting
                            ? isSpeaking && word === words[currentWordIndex]
                              ? 'white'
                              : 'inherit'
                            : word === words[currentWordIndex]
                              ? 'white' // Green when not recollecting and the word is correct
                              : 'inherit', // Inherit for all other cases
                          backgroundColor: isRecollecting
                            ? isSpeaking && word === words[currentWordIndex]
                              ? 'green'
                              : 'inherit'
                            : word === words[currentWordIndex]
                              ? 'green' // Green when not recollecting and the word is correct
                              : 'inherit', // Inherit for all other cases
                        }}
                        disabled={isSpeaking || isAutoPlaying}
                        // For mobile browsers: onClick
                        onClick={
                          mobile ? () => handleWordClick(word) : undefined
                        }
                        // For desktop browsers: onMouseEnter
                        onMouseEnter={
                          !mobile ? () => handleWordClick(word) : undefined
                        }
                      >
                        {word}
                      </Button>
                    ))}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </>
      )}

      {/* Completion Message */}
      {isCompleted && (
        <Row className="mt-4">
          <Col md={{ span: 8, offset: 2 }}>
            <Card className="text-center">
              <Card.Body>
                <h4 className="text-success">
                  🎉 Great job! You&apos;ve completed the{' '}
                  {isRecollecting ? (
                    <b>🔄 recollection</b>
                  ) : (
                    <b>🧠 memorization</b>
                  )}
                  !
                </h4>
                <h5 className="text-info">
                  ✍️ Try again to improve your
                  {isRecollecting ? (
                    <b> 🔄 recollection</b>
                  ) : (
                    <b> 🧠 memorization</b>
                  )}
                  !
                </h5>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default MathsPracticeExerciseSolution;
