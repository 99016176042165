export const questionsAndAnswersLessonOne = [
  {
    question: 'What piece of advice did Meg give to her sisters?',
    answer:
      'Meg advised her sisters to mind their books and to read a little every morning as soon as they woke up as it would do them good and help them through the day.',
  },
  {
    question: 'Where did Marmee go on Christmas morning and what did she give?',
    answer:
      'Marmee went to visit the Hummels who came begging on Christmas morning. She gave away food and drink, clothes and firewood.',
  },
  {
    question: 'What did Marmee ask the girls to do on Christmas morning?',
    answer:
      'Marmee asked the girls to give away their breakfast as Christmas gifts to the poor family as they were suffering from hunger and cold.',
  },
  {
    question: 'How did Marmee and her daughters help the poor family?',
    answer:
      'Marmee gave tea and gruel to the mother, and comforted her with promises of help, while she dressed the little baby. The girls spread the table, set the children round the fire and fed them.',
  },
  {
    question: 'What presents did the girls give to their mother on Christmas?',
    answer:
      'The girls presented their mother with a pair of gloves and slippers, a new handkerchief and a bottle of cologne.',
  },
];
