/**
 * Function to load a default PDF from a predefined path.
 * Ensures correct rotation and applies proper zoom.
 */
export const loadDefaultPDF = async (
  pdfjs,
  filePath,
  setPdfDoc,
  setNumPages,
  setPageNumber,
  renderPage,
  canvasRef,
  setPageImageData,
  viewport,
  setViewport,
  textItemsRef,
  hlTextItemsRef
) => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(filePath);

    if (!response.ok) {
      throw new Error(
        `Failed to load PDF: ${response.status} ${response.statusText}`
      );
    }

    const arrayBuffer = await response.arrayBuffer();
    console.log('Default PDF file fetched successfully.');

    // Load the PDF document
    const loadingTask = pdfjs.getDocument({ data: arrayBuffer });
    const doc = await loadingTask.promise;
    console.log('Default PDF loaded successfully:', doc);

    setPdfDoc(doc);
    setNumPages(doc.numPages);
    setPageNumber(1);

    // Render the first page with correct rotation and zoom
    await renderPage(
      doc,
      1,
      canvasRef,
      setPageImageData,
      viewport,
      setViewport,
      textItemsRef,
      hlTextItemsRef
    );
  } catch (error) {
    console.error('Error loading default PDF:', error);
  }
};

/**
 * Handle uploading a PDF file from the user's computer.
 */
export const handleFileUpload = async (
  event,
  pdfjs,
  setPdfDoc,
  setNumPages,
  setPageNumber,
  renderPage
) => {
  try {
    const file = event.target.files[0];
    if (!file) {
      console.warn('No file was uploaded.');
      return;
    }

    // Read file as ArrayBuffer
    const reader = new FileReader();
    reader.onload = async (e) => {
      console.log('File uploaded successfully, loading PDF via PDF.js...');
      try {
        // Initialize PDF loading
        const loadingTask = pdfjs.getDocument({ data: e.target.result });
        const doc = await loadingTask.promise;
        console.log('PDF loaded successfully:', doc);

        setPdfDoc(doc);
        setNumPages(doc.numPages);
        setPageNumber(1);

        // Render the first page once doc is ready
        await renderPage(doc, 1);
      } catch (error) {
        console.error('Error loading or parsing PDF:', error);
      }
    };
    reader.readAsArrayBuffer(file);
  } catch (err) {
    console.error('Failed to read the PDF file:', err);
  }
};
