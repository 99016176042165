export const questionsAndAnswersLessonThree = [
  {
    question: 'Who was the Efriam Duke? What special object did he possess?',
    answer:
      'Efriam Duke was an ancient king of Calabar in Africa. He had a magic drum. Whenever it was beaten, it would provide plenty of good food and drink.',
  },
  {
    question: 'How did the king treat his enemies?',
    answer:
      'The king did not like war. So, whenever his enemies declared a war, he would call all his enemies together and beat his drum. On being presented with a lavish meal, everyone would enjoy the feast and leave without fighting the war.',
  },
  {
    question: 'Why did the tortoise want compensation?',
    answer:
      "The tortoise was plucking palm nuts and dropped one nut accidentally. The king's wife, Ikwor Edem, picked it up and gave it to her daughter. The tortoise then accused the king's wife of stealing his palm nut and wanted compensation from the king.",
  },
  {
    question:
      'How did the tortoise become one of the richest men in the kingdom?',
    answer:
      'The tortoise became one of the richest men in the kingdom with the help of the magic drum. He invited everyone to a lavish feast, and they were impressed by his ability to entertain so many people.',
  },
  {
    question:
      'What happened when the tortoise crossed over a stick while he was holding the drum?',
    answer:
      'When the tortoise crossed over a stick while he was holding the drum, the spell broke at once. When he beat the drum for food, three hundred Egbo men appeared with sticks and whips and thrashed him and his family.',
  },
];
