export const questionsAndAnswersLessonSix = [
  {
    question: 'From whom did Beethoven inherit his musical talents?',
    answer:
      'Beethoven inherited his musical talents from his father, Johann Van Beethoven, who was a court singer. His father began teaching him violin and piano when Beethoven was four years old.',
  },
  {
    question: "How did Beethoven's father treat him when he was a child?",
    answer:
      "Beethoven's father was very strict and treated him very harshly. He would beat him for each hesitation or mistake, and Beethoven was constantly deprived of sleep for extra hours of practice.",
  },
  {
    question: 'How did Beethoven perform as a student?',
    answer:
      'Despite his musical genius, Beethoven was an average student. He struggled with math and spelling all his life. Beethoven felt that music was easier than words and suffered from dyslexia.',
  },
  {
    question: 'Why did Beethoven leave his hometown for Vienna?',
    answer:
      'Beethoven left his hometown for Vienna to dedicate himself wholeheartedly to musical study. He studied piano with Haydn and vocal composition with Antonio Salieri.',
  },
  {
    question:
      'What was the biggest tragedy in Beethoven’s life? Did it stop him from composing?',
    answer:
      'Beethoven became deaf later in his life, which was the biggest tragedy. For a musician, there cannot be a bigger handicap than losing the ability to hear. However, this did not stop him from composing music.',
  },
  {
    question: 'What kind of a person was Beethoven?',
    answer:
      'Beethoven was a hardworking man, but he was also short-tempered and absent-minded. He often felt lonely and was frequently miserable throughout his adult life.',
  },
  {
    question: 'Name some of the greatest late works of Beethoven.',
    answer:
      'Some of Beethoven’s greatest works include the Moonlight Sonata, Symphonies 3 to 9, Missa Solemnis, and String Quartet No. 14.',
  },
];
