import React, { useEffect, useState } from 'react';
import {
  Container,
  Form,
  Button,
  Card,
  Row,
  Col,
  ListGroup,
} from 'react-bootstrap';
import {
  addClass,
  getAllClasses,
  deleteClass,
  getAllSchools,
} from '../../db/indexedDB.js';

const SSCClassManager = () => {
  const [schoolName, setSchoolName] = useState('');
  const [className, setClassName] = useState('');
  const [classColor, setClassColor] = useState('#FFDDC1');
  const [schools, setSchools] = useState([]);
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    fetchSchools();
  }, []);

  const fetchSchools = async () => {
    const data = await getAllSchools();
    setSchools(data);
    if (data.length > 0) {
      setSchoolName(data[0].schoolName); // Auto-select the first school
      fetchClasses(data[0].schoolName);
    }
  };

  const fetchClasses = async (selectedSchool) => {
    if (!selectedSchool) return;
    const data = await getAllClasses(selectedSchool);
    setClasses(data);
  };

  const handleSchoolChange = (e) => {
    const selectedSchool = e.target.value;
    setSchoolName(selectedSchool);
    fetchClasses(selectedSchool);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!schoolName) return; // Ensure a school is selected

    await addClass(schoolName, className, classColor);
    setClassName('');
    setClassColor('#FFDDC1');
    fetchClasses(schoolName); // Refresh class list after adding a new class
  };

  const handleDeleteClass = async (className) => {
    await deleteClass(schoolName, className);
    fetchClasses(schoolName); // Refresh class list after deletion
  };

  return (
    <Container className="py-4">
      <h1 className="text-center mb-4">📚 Manage Classes 🎓</h1>

      {/* Select School */}
      <Card className="p-4 shadow mb-4">
        <Form.Group className="mb-3">
          <Form.Label>Select School</Form.Label>
          <Form.Select onChange={handleSchoolChange} value={schoolName}>
            {schools.map((school) => (
              <option key={school.schoolName} value={school.schoolName}>
                {school.schoolName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Card>

      {/* Existing Classes List */}
      {schoolName && (
        <Card className="p-4 shadow mb-4">
          <h4 className="text-center mb-3">
            📂 Existing Classes in {schoolName}
          </h4>
          {classes.length > 0 ? (
            <ListGroup>
              {classes.map((cls) => (
                <ListGroup.Item
                  key={cls.className}
                  style={{
                    backgroundColor: cls.color,
                    color: '#000',
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <span>{cls.className}</span>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDeleteClass(cls.className)}
                  >
                    ❌ Delete
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <p className="text-center">
              No classes available. Add a new one below.
            </p>
          )}
        </Card>
      )}

      {/* Input Form to Add a Class */}
      {schoolName && (
        <Card className="p-4 shadow">
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md={8}>
                <Form.Group>
                  <Form.Label>Class Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Class Name"
                    value={className}
                    onChange={(e) => setClassName(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Class Color</Form.Label>
                  <Form.Control
                    type="color"
                    value={classColor}
                    onChange={(e) => setClassColor(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit" className="w-100">
              ➕ Add Class
            </Button>
          </Form>
        </Card>
      )}
    </Container>
  );
};

export default SSCClassManager;
