import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  Button,
  ProgressBar,
  Image,
} from 'react-bootstrap';
import './PreschoolSyllabus.css';
import { preSchoolSyllabusList } from './preScholSyllabusData.js';
import BackgroundMusic from '../BackgroundMusic.js';
import FamilyGallery from './FamilyGallery.js';
import { fetchMyFamilyImages } from './db-helper.js';
import Celebration from './Celebration.js';
import ScreenRecorder from '../ScreenRecorder.js';

const PreschoolSyllabus = () => {
  // State for managing the open/close of a single activity
  const [showModal, setShowModal] = useState(false);
  const [activityName, setActivityName] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentList, setCurrentList] = useState([]);
  const [language, setLanguage] = useState('');
  const [isMultiLingualTEEN, setIsMultiLingualTEEN] = useState(false);
  const [isEmojiBased, setIsEmojiBased] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [autoPlay, setAutoPlay] = useState(false);
  const [isLooping, setIsLooping] = useState(false);
  const [isTouched, setIsTouched] = useState(false); // Animation trigger

  // NEW: State for auto-playing all activities
  const [autoPlayAll, setAutoPlayAll] = useState(false);
  const [currentActivityIndex, setCurrentActivityIndex] = useState(0);

  const [isBackgroundMusicAutoPlay, setIsBackgroundMusicAutoPlay] =
    useState(false);

  const [myFamilyImages, setMyFamilyImages] = useState(null);

  // NEW: State attribute to trigger fetching and merging for "My Family"
  const [myFamilyFetchParams, setMyFamilyFetchParams] = useState(null);

  const celebrationRef = useRef(null);

  // useEffect to run the asynchronous code for "My Family" activity
  useEffect(() => {
    const fetchAndMergeMyFamilyImages = async () => {
      if (myFamilyFetchParams) {
        console.log('🔄 Fetching My Family Images...');
        const fetchedImages = await fetchMyFamilyImages();

        // Remove the element with id: "defaultSetFlag" and isSet: true
        const filteredImages = fetchedImages.filter(
          (image) => !(image.id === 'defaultSetFlag' && image.isSet === true)
        );

        console.log(
          '📸 My Family Images Fetched:',
          JSON.stringify(filteredImages, null, 4)
        );

        // Step 1: Merge only if the member exists in filteredImages
        console.log('🔄 Merging myFamilyImages with template list...');

        const mergedList = filteredImages.map((person) => {
          // Find an exact match where `person.name` is the same as `item.content` or `item.alias`
          const matchingItem = myFamilyFetchParams.list.find(
            (item) => item.content === person.name || item.alias === person.name
          );

          // Find a partial match where `person.name` starts with `item.content` OR `item.alias`
          const matchingItemContent = myFamilyFetchParams.list.find(
            (item) =>
              person.name.startsWith(item.content) || // Ensures `person.name` starts with `item.content`
              person.name.startsWith(item.alias) // Also checks if `person.name` starts with `item.alias`
          );

          return {
            name: person.name,
            content: matchingItemContent?.content || person.name, // Assign matched content if a partial match is found
            alias: matchingItem?.alias || person.name, // Use alias from exact match if available, otherwise keep the original name
            image: person.image, // Preserve the image from filteredImages
            emoji: matchingItem?.emoji || '👤', // Use emoji from exact match if available, otherwise assign default emoji
          };
        });

        console.log('✅ Final Merged List:');
        mergedList.forEach((element, index) => {
          console.log(
            `Element ${index + 1}:`,
            JSON.stringify(element, null, 4)
          );
        });

        console.log(`mergedList length: ${mergedList.length}`);

        // Set only filtered fetched images as the final list (source of truth)
        setMyFamilyImages(filteredImages);
        setCurrentList(mergedList);
      }
    };

    fetchAndMergeMyFamilyImages();
  }, [myFamilyFetchParams]);

  // ----------------------------------------
  // 1. Helpers to open/close a single activity (modal)
  // ----------------------------------------
  const handleOpenModal = (
    name,
    list,
    lang,
    multiLingualTEEN = false,
    emojiBased = false
  ) => {
    // Set up state common to all activities
    setActivityName(name);
    setLanguage(lang);
    setIsMultiLingualTEEN(multiLingualTEEN);
    setIsEmojiBased(emojiBased);
    setIsSpeaking(false);
    setAutoPlay(false); // Reset individual auto-play each time we open
    setIsBackgroundMusicAutoPlay(true);
    setCurrentIndex(0); // Always start at index 0

    // If it's a "My Family" activity, trigger the useEffect via state; otherwise, reset
    if (name === '👨‍👩‍👧‍👦 My Family' || name === '👨‍👩‍👧‍👦 నా కుటుంబం') {
      setMyFamilyFetchParams({ name, list });
    } else {
      setMyFamilyFetchParams(null);
      setMyFamilyImages(null);
      setCurrentList(list);
    }
    setShowModal(true);
  };

  const handleAllAutoOpenModal = (
    name,
    list,
    lang,
    multiLingualTEEN = false,
    emojiBased = false
  ) => {
    // Set up state common to all activities
    setActivityName(name);
    setLanguage(lang);
    setIsMultiLingualTEEN(multiLingualTEEN);
    setIsEmojiBased(emojiBased);
    setIsSpeaking(false);
    setAutoPlay(true); // For auto-play mode
    setIsBackgroundMusicAutoPlay(true);
    setCurrentIndex(0); // Always start at index 0

    // Handle "My Family" separately
    if (name === '👨‍👩‍👧‍👦 My Family' || name === '👨‍👩‍👧‍👦 నా కుటుంబం') {
      setMyFamilyFetchParams({ name, list });
      setTimeout(() => {
        console.log('✅ Waiting 2 seconds for family data to be fetched...');
        setShowModal(true); // Show modal after delay
      }, 2000);
    } else {
      setMyFamilyFetchParams(null);
      setMyFamilyImages(null);
      setCurrentList(list);
      setShowModal(true);
    }
  };

  // ----------------------------------------
  // 2. Close the single activity (modal) + check for autoPlayAll
  // ----------------------------------------
  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentIndex(0);
    setIsSpeaking(false);
    setAutoPlay(false);

    // If we're in autoPlayAll mode, move on to the next activity automatically
    if (autoPlayAll) {
      const nextActivityIndex = currentActivityIndex + 1;
      if (nextActivityIndex < preSchoolSyllabusList.length) {
        // Ensure new activity starts from index 0
        setCurrentActivityIndex(nextActivityIndex);
        const { name, list, lang, multiLingualTEEN, emojiBased } =
          preSchoolSyllabusList[nextActivityIndex];
        handleAllAutoOpenModal(name, list, lang, multiLingualTEEN, emojiBased);
      } else {
        // We've reached the end of the list
        setAutoPlayAll(false);
      }
    }
  };

  // ----------------------------------------
  // 3. Text-to-speech for the current character/emoji
  // ----------------------------------------
  const speakCharacter = (char, callback) => {
    if (isSpeaking) return; // Prevent overlapping speeches
    setIsSpeaking(true);
    celebrationRef.current?.triggerConfetti();

    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance();
    utterance.rate = 0.6;

    let textToSpeak = '';

    if (isMultiLingualTEEN) {
      // Multi-language English + Telugu
      textToSpeak = char.english;
      utterance.text = textToSpeak;
      utterance.lang = 'en-US';

      synth.speak(utterance);

      utterance.onend = () => {
        const teluguUtterance = new SpeechSynthesisUtterance(char.telugu);
        teluguUtterance.lang = 'te-IN';
        teluguUtterance.rate = 0.6;
        synth.speak(teluguUtterance);

        // Estimate duration based on character count
        const estimatedDuration =
          textToSpeak.length === 1
            ? 1 // Default minimum time for a single character
            : textToSpeak.length * 0.06 +
              (char.telugu ? char.telugu.length * 0.06 : 0);

        teluguUtterance.onend = () => {
          setTimeout(() => {
            setIsSpeaking(false);
            if (callback) callback();
          }, estimatedDuration * 2000); // Wait 2x the estimated time
        };
      };
    } else {
      textToSpeak = isEmojiBased ? char.content : char;
      utterance.text = textToSpeak;
      utterance.lang =
        language === 'Telugu'
          ? 'te-IN'
          : language === 'Tamil'
            ? 'ta-IN'
            : language === 'Hindi'
              ? 'hi-IN'
              : 'en-US';

      synth.speak(utterance);

      // Estimate duration based on character count
      const estimatedDuration =
        textToSpeak.length === 1
          ? 1 // Default minimum time for a single character
          : textToSpeak.length * 0.06;

      utterance.onend = () => {
        setTimeout(() => {
          setIsSpeaking(false);
          if (callback) callback();
        }, estimatedDuration * 2000); // Wait 2x the estimated time
      };
    }
  };

  // ----------------------------------------
  // 4. Handle moving to the next character
  // ----------------------------------------
  const handleNextCharacter = () => {
    if (isSpeaking) return;

    // Trigger "finger tap" animation
    setIsTouched(true);
    setTimeout(() => setIsTouched(false), 500); // Short bounce

    // Speak the current character, then proceed
    if (currentIndex < currentList.length) {
      speakCharacter(currentList[currentIndex], () => {
        const nextIndex = currentIndex + 1;
        if (nextIndex < currentList.length) {
          // Move to the next character
          setCurrentIndex(nextIndex);
        } else if (isLooping) {
          // If looping, go back to first character
          setCurrentIndex(0);
        } else {
          // If we've reached the end of this activity, close the modal
          // handleCloseModal will check if we should open the next activity if autoPlayAll == true
          handleCloseModal();
        }
      });
    }
  };

  // ----------------------------------------
  // 5. If "autoPlay" is ON, automatically trigger next character after a timeout
  // ----------------------------------------
  useEffect(() => {
    let autoPlayTimer;
    if (autoPlay && showModal && currentIndex < currentList.length) {
      autoPlayTimer = setTimeout(() => handleNextCharacter(), 2000);
    }
    return () => clearTimeout(autoPlayTimer);
  }, [currentIndex, autoPlay, showModal]);

  // ----------------------------------------
  // 6. Whenever we toggle "autoPlayAll" ON, start from the first activity
  //    (Alternatively, you could trigger this from a button or a parent component.)
  // ----------------------------------------
  useEffect(() => {
    const handleAutoPlayAll = () => {
      if (autoPlayAll) {
        // Reset to the first activity in the list
        setCurrentActivityIndex(0);
        const { name, list, lang, multiLingualTEEN, emojiBased } =
          preSchoolSyllabusList[0];
        handleAllAutoOpenModal(name, list, lang, multiLingualTEEN, emojiBased);
      } else {
        handleCloseModal();
      }
    };

    handleAutoPlayAll();
  }, [autoPlayAll]);

  const handleRecordingComplete = (blob) => {
    console.log('Recording complete:', blob);
    // You can save the blob or upload it to a server
  };

  return (
    <>
      <Container>
        <Row className="align-items-center">
          <Col md={6} className="d-flex justify-content-center">
            <BackgroundMusic isAutoPlay={isBackgroundMusicAutoPlay} />
          </Col>
          <Col md={6} className="d-flex justify-content-center">
            <ScreenRecorder
              showRecordingButton={false}
              onRecordingComplete={handleRecordingComplete}
            />
          </Col>
        </Row>
      </Container>
      <Celebration ref={celebrationRef} />

      <Container className="py-4 d-flex flex-column align-items-center justify-content-center w-100">
        {/* Fixed Heading */}
        <h2 className="text-center mb-4 kids-learning-text w-100">
          🎨📚 Learn Anywhere, Grow Everywhere! 🧸✨
        </h2>

        {/* Button to toggle "Play All Activities" */}
        <div className="d-flex justify-content-center align-items-center mt-6 pb-4 w-60 gap-3">
          <Button
            variant={autoPlayAll ? 'danger' : 'primary'}
            onClick={() => setAutoPlayAll(!autoPlayAll)}
          >
            {autoPlayAll ? 'Stop Full Auto-Play' : 'Start Full Auto-Play'}
          </Button>
          <FamilyGallery />
        </div>

        {/* Scrollable Section for Cards */}
        <div className="scrollable-container w-100">
          <Row className="justify-content-center w-100">
            {preSchoolSyllabusList.map(
              (
                { name, list, lang, color, multiLingualTEEN, emojiBased },
                idx
              ) => (
                <Col
                  xs={6}
                  sm={4}
                  md={3}
                  className="mb-4 text-center"
                  key={name}
                >
                  <Card
                    className="p-3 shadow-sm cursor-pointer"
                    onClick={() => {
                      setCurrentActivityIndex(idx); // store which activity
                      handleOpenModal(
                        name,
                        list,
                        lang,
                        multiLingualTEEN,
                        emojiBased
                      );
                    }}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: color,
                      width: '100%',
                    }}
                  >
                    <Card.Body>
                      <h3 className="fw-bold">{name}</h3>
                    </Card.Body>
                  </Card>
                </Col>
              )
            )}
          </Row>
        </div>

        {/* Modal for Activity */}
        <Modal
          show={showModal}
          onHide={!autoPlayAll ? handleCloseModal : undefined} // Prevent closing in autoPlayAll mode
          centered
          size="lg"
          dialogClassName="custom-modal"
          backdropClassName="custom-backdrop"
        >
          {/* Header with Full Width */}
          <Modal.Header
            closeButton={!autoPlayAll} // Hide close button if autoPlayAll is true
            className="w-100 text-center d-flex flex-column align-items-center justify-content-center custom-close-button"
          >
            <Modal.Title
              className="w-100 text-center"
              style={{
                color: 'white',
              }}
            >
              🎉 Loving Mom & Dad! 👩‍👧❤️👨‍👧🎈
              <br />
              <strong>{activityName}</strong>
            </Modal.Title>
          </Modal.Header>

          {/* Progress Bar */}
          <ProgressBar
            now={(currentIndex / currentList.length) * 100}
            label={`${Math.round((currentIndex / currentList.length) * 100)}%`}
            variant="success"
            className="w-100"
          />

          {/* Main Content (Modal Body) */}
          <Modal.Body
            className="d-flex flex-column align-items-center justify-content-center text-center position-relative w-100"
            style={{
              cursor: 'pointer',
            }}
            onClick={!autoPlay ? handleNextCharacter : undefined}
          >
            {/* Finger Touch Emoji Overlay */}
            <span
              className={`finger-touch ${isTouched ? 'bounce' : ''}`}
              style={{
                position: 'absolute',
                top: '45%',
                left: '45%',
                transform: 'translate(20%, 20%)',
                fontSize: '11rem', // Responsive font size
                pointerEvents: 'none',
              }}
            >
              👆
            </span>

            {myFamilyImages ? (
              (() => {
                const currentItem = currentList[currentIndex] || {}; // Ensure currentItem is defined
                const content = currentItem?.content || '';
                const alias = currentItem?.alias || '';

                // Find the first matching image using content OR alias (with safeguards)
                const matchedPerson = myFamilyImages.find(
                  (personImage) =>
                    (personImage?.name?.startsWith(content) ||
                      (alias && personImage?.name?.startsWith(alias))) &&
                    personImage?.image
                );

                if (matchedPerson) {
                  return (
                    <Image
                      key={matchedPerson.name} // Ensure unique key
                      src={matchedPerson.image}
                      alt={matchedPerson.name}
                      fluid
                      rounded
                      className="img-fluid"
                      style={{ maxWidth: '300px', height: 'auto' }}
                    />
                  );
                }

                // If no image is found, render text instead
                return (
                  <h1
                    className={`fixed-text text-highlight ${isSpeaking ? 'animate-text' : ''}`}
                    style={{
                      cursor: isSpeaking ? 'not-allowed' : 'pointer',
                      fontSize: '9rem', // Scales with viewport
                      color: '#ffffff',
                      textAlign: 'center',
                      width: '80%', // Fixed width to maintain layout
                      maxWidth: '90vw', // Ensures it fits within the viewport
                      wordBreak: 'break-word', // Prevents text overflow
                      overflowWrap: 'break-word', // Ensures long words wrap properly
                    }}
                  >
                    {isMultiLingualTEEN
                      ? currentItem?.emoji || currentItem?.english
                      : isEmojiBased
                        ? currentItem?.emoji
                        : currentItem}
                  </h1>
                );
              })()
            ) : (
              <h1
                className={`fixed-text text-highlight ${isSpeaking ? 'animate-text' : ''}`}
                style={{
                  cursor: isSpeaking ? 'not-allowed' : 'pointer',
                  fontSize: '11rem', // Scales with viewport
                  color: '#ffffff',
                  textAlign: 'center',
                  width: '80%', // Fixed width to maintain layout
                  maxWidth: '90vw', // Ensures it fits within the viewport
                  wordBreak: 'break-word', // Prevents text overflow
                  overflowWrap: 'break-word', // Ensures long words wrap properly
                }}
              >
                {isMultiLingualTEEN
                  ? currentList[currentIndex]?.emoji ||
                    currentList[currentIndex]?.english
                  : isEmojiBased
                    ? currentList[currentIndex]?.emoji
                    : currentList[currentIndex]}
              </h1>
            )}

            {!autoPlayAll && !autoPlay && !isSpeaking && (
              <p
                style={{ color: '#ffffff', fontSize: '2vw', marginTop: '1rem' }}
              >
                Tap the text to hear & move to next.
              </p>
            )}
          </Modal.Body>

          {/* Footer with Fixed Position */}
          <Modal.Footer
            className="d-flex flex-wrap justify-content-center align-items-center gap-2 w-100"
            style={{
              zIndex: 1050,
            }}
          >
            {autoPlayAll ? (
              <div className="text-center mt-3 pb-4 w-100">
                <Button
                  variant={autoPlayAll ? 'danger' : 'primary'}
                  onClick={() => setAutoPlayAll(false)}
                >
                  Stop Full Auto-Play
                </Button>
              </div>
            ) : (
              <>
                {/* Toggle Looping */}
                <Button
                  variant={isLooping ? 'danger' : 'success'}
                  onClick={() => setIsLooping((prev) => !prev)}
                  className="mx-2"
                  title={isLooping ? 'Stop Auto-Looping' : 'Start Auto-Looping'}
                  style={{
                    backgroundColor: isLooping ? '#dc3545' : '#28a745', // Red for danger, Green for success
                    borderColor: isLooping ? '#dc3545' : '#28a745',
                    color: '#fff', // Ensures text is always white for contrast
                    transition: 'background-color 0.3s ease-in-out', // Smooth color transition
                  }}
                >
                  {isLooping ? '🔁 Stop Looping' : '🔄 Enable Looping'}
                </Button>

                {/* Toggle Character Auto-Play */}
                <Button
                  variant={autoPlay ? 'danger' : 'success'}
                  onClick={() => setAutoPlay(!autoPlay)}
                  className="mx-2"
                  style={{
                    backgroundColor: autoPlay ? '#dc3545' : '#28a745',
                    borderColor: autoPlay ? '#dc3545' : '#28a745',
                    color: '#fff',
                  }}
                >
                  {autoPlay ? '⏹ Stop Auto-Play' : '▶️ Start Auto-Play'}
                </Button>

                {/* Manually close the modal */}
                {/* <Button
                variant="secondary"
                onClick={handleCloseModal}
                disabled={isSpeaking}
                className="mx-2"
              >
                ❌ Close
              </Button> */}
              </>
            )}
          </Modal.Footer>

          {/* Custom Styling */}
          <style>
            {`
      /* Adjust text size based on screen width */
      @media (max-width: 1200px) {
        h1.fixed-text { font-size: 5vw; }
      }
      
      @media (max-width: 992px) {
        h1.fixed-text { font-size: 4vw; }
      }
      
      @media (max-width: 768px) {
        h1.fixed-text { font-size: 3vw; }
        .finger-touch { font-size: 4vw; }
      }
      
      @media (max-width: 576px) {
        h1.fixed-text { font-size: 2.5vw; }
        .finger-touch { font-size: 3vw; }
      }
      `}
          </style>
        </Modal>
      </Container>
    </>
  );
};

export default PreschoolSyllabus;
