export const questionsAndAnswersNDLessonFive = [
  {
    question: "Why was Amy having a hard time at Aunt March's place?",
    answer:
      "Amy was having a hard time at Aunt March's place because Aunt March was very strict with her rules and orders. However, she liked Amy for her courteous behavior.",
  },
  {
    question:
      'What did Esther tell Amy that made her become a model of obedience?',
    answer:
      "Esther told Amy that she had seen Aunt March's will, which stated that the little turquoise ring would be given to Amy. This made Amy become a model of obedience.",
  },
  {
    question: 'Why did Amy want to wear her turquoise ring?',
    answer:
      'Amy was too young to wear ornaments, but she wanted to wear the turquoise ring as a reminder to be unselfish like her sister, Beth.',
  },
  {
    question: "Why didn't Jo like the idea of Meg marrying John Brooke?",
    answer:
      "Jo didn't like the idea of Meg marrying John Brooke because he was very poor, and Jo wanted her sister to marry a wealthy man.",
  },
  {
    question: 'Who had written the two letters? Why?',
    answer:
      "Laurie had written the two letters because he wanted to take revenge on Jo for not informing him about Meg and John Brooke's relationship.",
  },
];
