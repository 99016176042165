import { forwardRef, useImperativeHandle } from 'react';
import confetti from 'canvas-confetti';

// Function to handle general confetti animation
const handleConfettiAnimation = () => {
  console.log('🎉 Confetti animation started!');

  const duration = 3000; // Confetti duration (3 sec)
  const end = Date.now() + duration;

  const confettiInterval = setInterval(() => {
    if (Date.now() > end) {
      clearInterval(confettiInterval); // Stop confetti after duration
      console.log('✅ Confetti animation ended!');
      return;
    }

    confetti({
      particleCount: 50, // Reduce count per burst for smoother animation
      spread: 120, // Spread angle of confetti
      startVelocity: 60, // High velocity for upward throw
      zIndex: 9999, // Ensure confetti is on top
      origin: { x: 0.5, y: 0.1 }, // Start from bottom
      angle: 270, // Shoot upwards
      gravity: 0.5, // Slow down confetti for realistic float
      scalar: 1.2, // Scale confetti pieces slightly larger
    });
  }, 300); // Launch every 300ms
};

// Function to handle star animation (15 seconds)
const handleStarAnimation = () => {
  console.log('🌟 Star animation started!');

  const duration = 2 * 1000;
  const animationEnd = Date.now() + duration;
  const defaults = {
    startVelocity: 30,
    spread: 360,
    ticks: 60,
    zIndex: 9999,
  };

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  const interval = setInterval(() => {
    const timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      clearInterval(interval);
      console.log('✅ Star animation ended!');
      return;
    }

    const particleCount = 50 * (timeLeft / duration);
    // Confetti bursts on top of everything
    confetti({
      ...defaults,
      particleCount,
      origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
    });
    confetti({
      ...defaults,
      particleCount,
      origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
    });
  }, 250);
};

// Function to handle fireworks-style burst animation
const handleFireworkAnimation = () => {
  console.log('🎆 Firework animation started!');

  const count = 200;
  const defaults = {
    origin: { y: 0.7 },
    zIndex: 9999, // Ensure visibility on top
  };

  function fire(particleRatio, opts) {
    confetti({
      ...defaults,
      ...opts,
      particleCount: Math.floor(count * particleRatio),
    });
  }

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });

  console.log('✅ Firework animation ended!');
};

// Utility function to generate a random number within a range
const randomInRange = (min, max) => Math.random() * (max - min) + min;

// Function for randomized angle and spread animation
const handleRandomAngleAnimation = () => {
  console.log('🎇 Random Angle Confetti started!');

  confetti({
    angle: randomInRange(55, 125),
    spread: randomInRange(50, 70),
    particleCount: randomInRange(50, 100),
    origin: { y: 0.6 },
    zIndex: 9999,
  });

  console.log('✅ Random Angle Confetti ended!');
};

const handleRealisticLookAnimation = () => {
  var count = 200;
  var defaults = {
    origin: { y: 0.7 },
  };

  function fire(particleRatio, opts) {
    confetti({
      ...defaults,
      ...opts,
      zIndex: 9999,
      particleCount: Math.floor(count * particleRatio),
    });
  }

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
};

// Function to randomly trigger either Confetti, Star, or Firework animation
const triggerRandomAnimation = () => {
  const effects = [
    handleConfettiAnimation,
    handleStarAnimation,
    handleFireworkAnimation,
    handleRandomAngleAnimation,
    handleRealisticLookAnimation,
  ];
  const randomEffect = effects[Math.floor(Math.random() * effects.length)];
  randomEffect();
};

const Celebration = forwardRef((props, ref) => {
  // Expose the confetti trigger function
  useImperativeHandle(ref, () => ({
    triggerConfetti: triggerRandomAnimation,
  }));

  return null; // No overlay div needed
});

Celebration.displayName = 'Celebration';

export default Celebration;
