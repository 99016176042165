import React, { useEffect, useState, useRef } from 'react';

const BackgroundMusic = ({ isAutoPlay }) => {
  const musicList = [
    'educational-corporate-music-301376.mp3',
    'epic-uplifting-inspiration-music-303827.mp3',
    'exciting-upbeat-background-music-300654.mp3',
    'exciting-upbeat-happy-background-music-303833.mp3',
    'funny-cartoon-comedy-background-music-298330.mp3',
    'funny-kids-music-298322.mp3',
    'happy-kids-music-292759.mp3',
    'kids-game-gaming-background-music-295075.mp3',
    'upbeat-energetic-happy-background-music-307007.mp3',
    'uplifting-inspiring-background-music-303176.mp3',
  ];
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrack, setCurrentTrack] = useState(null);

  // Function to randomly select a new track, excluding the currently playing one (if possible)
  const getRandomTrack = (excludeTrack) => {
    let newTrack;
    do {
      newTrack = musicList[Math.floor(Math.random() * musicList.length)];
    } while (newTrack === excludeTrack && musicList.length > 1);
    return newTrack;
  };

  // Automatically start music when `isAutoPlay` is true
  useEffect(() => {
    if (isAutoPlay && !isPlaying) {
      startMusic();
    }
  }, [isAutoPlay]); // Re-run when isAutoPlay changes

  // Function to start playing music
  const startMusic = async () => {
    if (!musicList || musicList.length === 0) return;

    // Prevent restarting if already playing
    if (audioRef.current && !audioRef.current.paused) return;

    const newTrack = getRandomTrack(currentTrack);
    setCurrentTrack(newTrack);
    audioRef.current = new Audio('/music/' + newTrack);
    audioRef.current.volume = 0.1; // Set volume to 10%
    audioRef.current.loop = false;

    try {
      await audioRef.current.play();
      setIsPlaying(true);
    } catch (error) {
      console.warn('Autoplay blocked. Waiting for user interaction.');
    }

    // When the track ends, play another random track
    audioRef.current.onended = () => {
      playNextTrack();
    };
  };

  // Function to play the next song after the current one ends
  const playNextTrack = async () => {
    if (!musicList || musicList.length === 0) return;

    const newTrack = getRandomTrack(currentTrack);
    setCurrentTrack(newTrack);

    if (!audioRef.current) {
      audioRef.current = new Audio(newTrack);
    } else {
      audioRef.current.src = newTrack;
    }

    audioRef.current.volume = 0.1;
    audioRef.current.loop = false;

    try {
      await audioRef.current.play();
      setIsPlaying(true);
    } catch (error) {
      console.error('Error playing next audio track:', error);
    }

    audioRef.current.onended = () => {
      playNextTrack();
    };
  };

  // Function to manually stop the music
  const stopMusic = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current = null;
      setIsPlaying(false);
      setCurrentTrack(null);
    }
  };

  return (
    <div>
      {isPlaying ? (
        <button onClick={stopMusic}>⏹ Stop Background Music</button>
      ) : (
        <button onClick={startMusic}>
          🎵 Click to Enable Background Music
        </button>
      )}
      {currentTrack && <p>Now Playing: 🎶 {currentTrack.split('/').pop()}</p>}
    </div>
  );
};

export default BackgroundMusic;
