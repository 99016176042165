export const questionsAndAnswersNDLessonOne = [
  {
    question: 'What was Tom doing in the closet?',
    answer:
      'Tom was eating jam in the closet while Aunt Polly was searching for him. Tom tried to hide from her in the closet, and when found by Aunt Polly, he escaped from her by playing a trick on her.',
  },
  {
    question: 'Why was Tom forced by Aunt Polly to work on a Saturday?',
    answer:
      "Tom was forced by Aunt Polly to work on a Saturday because he had fought with the new boy. They had called each other liars and cowards, and both of them had rolled and tumbled in the dirt, tearing each other's clothes and hair. On seeing his dirty condition, Aunt Polly decided to punish Tom.",
  },
  {
    question: 'How did Aunt Polly reward Tom for whitewashing the fence?',
    answer:
      'Aunt Polly rewarded Tom by giving him a large apple from the cellar and allowing him to go out and play.',
  },
  {
    question: 'Why was Mr. Walters forced to announce Tom as the winner?',
    answer:
      'Mr. Walters was forced to announce Tom as the winner because Tom came forward with nine yellow tickets, nine red tickets, and ten blue ones and demanded a Bible. These tickets were taken from other boys, and none of the others had any tickets to show.',
  },
  {
    question: 'Why did Tom feel miserable on Monday mornings?',
    answer:
      'Tom felt miserable on Monday mornings because it was another week of slow suffering in school. Tom did not enjoy going to school.',
  },
];
