import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getAllSchools } from '../db/indexedDB.js';
import { schoolDetails } from '../ssc/data/schools.js';

const SchoolComponent = () => {
  const { schoolName } = useParams(); // Get selected school from URL
  const navigate = useNavigate();
  const [dbClasses, setDbClasses] = useState([]);

  useEffect(() => {
    fetchSchoolClasses();
  }, [schoolName]);

  const fetchSchoolClasses = async () => {
    const data = await getAllSchools();
    const schoolFromDB = data.find((s) => s.schoolName === schoolName);
    setDbClasses(schoolFromDB ? schoolFromDB.classes : []);
  };

  // Find static school data
  const staticSchoolData = schoolDetails.find(
    (s) => s.schoolName === schoolName
  );
  const staticClasses = staticSchoolData ? staticSchoolData.classes : [];

  // Combine static and dynamic classes
  const allClasses = [...staticClasses, ...dbClasses];

  return (
    <Container className="py-4">
      {/* Back Button */}
      <Button
        onClick={() => navigate('/kids')}
        variant="secondary"
        className="mb-3"
      >
        🔙 Back to Schools
      </Button>

      {/* Add Class Data Button */}
      <Button
        onClick={() => navigate(`/kids/ssc/${schoolName}/add-class`)}
        variant="primary"
        className="mb-3 ms-2"
      >
        ➕ Add Class
      </Button>

      <h2 className="text-center mb-4">{schoolName} - Select Your Class</h2>

      <Row className="justify-content-center">
        {allClasses.length > 0 ? (
          allClasses.map(({ className, color }, index) => (
            <Col xs={6} sm={4} md={3} className="mb-4 text-center" key={index}>
              <Card
                className="p-3 shadow-sm cursor-pointer"
                onClick={() => navigate(`/kids/ssc/${schoolName}/${className}`)}
                style={{ backgroundColor: color, cursor: 'pointer' }}
              >
                <Card.Body>
                  <h3 className="fw-bold">{className}</h3>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <p className="text-center">No classes available. Add a new one!</p>
        )}
      </Row>
    </Container>
  );
};

export default SchoolComponent;
