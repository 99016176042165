import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Card, ListGroup } from 'react-bootstrap';
import {
  getAllSchools,
  getAllClasses,
  addLesson,
  deleteLesson,
} from '../../db/indexedDB.js';

const SSCLessonManager = () => {
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [lessonName, setLessonName] = useState('');
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    fetchSchools();
  }, []);

  const fetchSchools = async () => {
    const data = await getAllSchools();
    setSchools(data);
    if (data.length > 0) {
      setSelectedSchool(data[0].schoolName); // Auto-select first school
      fetchClasses(data[0].schoolName);
    }
  };

  const fetchClasses = async (schoolName) => {
    if (!schoolName) return;
    const data = await getAllClasses(schoolName);
    setClasses(data);
  };

  const handleSchoolChange = (e) => {
    const selectedSchool = e.target.value;
    setSelectedSchool(selectedSchool);
    setSelectedClass('');
    setSelectedSubject('');
    setLessons([]);
    fetchClasses(selectedSchool);
  };

  const handleClassChange = (e) => {
    const className = e.target.value;
    setSelectedClass(className);
    setSelectedSubject('');
    setLessons([]);

    const selectedClassObj = classes.find((cls) => cls.className === className);
    setSubjects(selectedClassObj ? selectedClassObj.subjects : []);
  };

  const handleSubjectChange = (e) => {
    const subjectName = e.target.value;
    setSelectedSubject(subjectName);

    const selectedClassObj = classes.find(
      (cls) => cls.className === selectedClass
    );
    const selectedSubjectObj = selectedClassObj?.subjects.find(
      (subj) => subj.subjectName === subjectName
    );
    setLessons(selectedSubjectObj ? selectedSubjectObj.lessons : []);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !selectedSchool ||
      !selectedClass ||
      !selectedSubject ||
      !lessonName.trim()
    )
      return;

    await addLesson(selectedSchool, selectedClass, selectedSubject, lessonName);

    // Update lessons state immediately
    setLessons((prevLessons) => [
      ...prevLessons,
      { lessonName, activities: [] },
    ]);

    setLessonName('');
  };

  const handleDeleteLesson = async (lessonName) => {
    await deleteLesson(
      selectedSchool,
      selectedClass,
      selectedSubject,
      lessonName
    );

    // Remove deleted lesson from state
    setLessons((prevLessons) =>
      prevLessons.filter((lesson) => lesson.lessonName !== lessonName)
    );
  };

  return (
    <Container className="py-4">
      <h1 className="text-center mb-4">📘 Manage Lessons</h1>

      {/* Select School */}
      <Card className="p-4 shadow mb-4">
        <Form.Group className="mb-3">
          <Form.Label>Select School</Form.Label>
          <Form.Select onChange={handleSchoolChange} value={selectedSchool}>
            {schools.map((school) => (
              <option key={school.schoolName} value={school.schoolName}>
                {school.schoolName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Card>

      {/* Select Class */}
      {selectedSchool && (
        <Card className="p-4 shadow mb-4">
          <Form.Group className="mb-3">
            <Form.Label>Select Class</Form.Label>
            <Form.Select onChange={handleClassChange} value={selectedClass}>
              <option value="">Choose a Class</option>
              {classes.map((cls) => (
                <option key={cls.className} value={cls.className}>
                  {cls.className}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Card>
      )}

      {/* Select Subject */}
      {selectedClass && (
        <Card className="p-4 shadow mb-4">
          <Form.Group className="mb-3">
            <Form.Label>Select Subject</Form.Label>
            <Form.Select onChange={handleSubjectChange} value={selectedSubject}>
              <option value="">Choose a Subject</option>
              {subjects.map((subj) => (
                <option key={subj.subjectName} value={subj.subjectName}>
                  {subj.subjectName}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Card>
      )}

      {/* Display Existing Lessons */}
      {selectedSubject && (
        <Card className="p-4 shadow mb-4">
          <h4 className="text-center mb-3">
            📂 Existing Lessons in {selectedSubject}
          </h4>
          {lessons.length > 0 ? (
            <ListGroup>
              {lessons.map((lesson) => (
                <ListGroup.Item
                  key={lesson.lessonName}
                  style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <span>{lesson.lessonName}</span>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDeleteLesson(lesson.lessonName)}
                  >
                    ❌ Delete
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <p className="text-center">
              No lessons available. Add a new one below.
            </p>
          )}
        </Card>
      )}

      {/* Add New Lesson Form */}
      {selectedSubject && (
        <Card className="p-4 shadow">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Lesson Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Lesson Name"
                value={lessonName}
                onChange={(e) => setLessonName(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100">
              ➕ Add Lesson
            </Button>
          </Form>
        </Card>
      )}
    </Container>
  );
};

export default SSCLessonManager;
