export const questionsAndAnswersLessonSixteen = [
  {
    question: 'Which state was Kalam visiting? Had he been there before?',
    answer:
      'Kalam was visiting Jharkhand. Yes, he had been there before, the hill region about 75 km away from Ranchi.',
  },
  {
    question:
      'What did Kalam see on his helicopter ride? How did the helicopter ride end?',
    answer:
      'Kalam saw vast stretches of forest, hills, and streams on his helicopter ride. The helicopter ride ended in a crash. Fortunately, the engine failed while landing and not in the air.',
  },
  {
    question:
      'Who was not convinced that Kalam was alright? How did Kalam handle this?',
    answer:
      "Kalam's 86-year-old elder brother was not convinced that he was alright. Kalam handled this with grace and talked to his brother for a while.",
  },
  {
    question: 'Why did Kalam say India is poor as a nation?',
    answer:
      'Kalam believed that India is poor as a nation despite its enormous wealth because it does not focus on value addition, be it in minerals, biodiversity products, grain, or fish.',
  },
  {
    question: 'What disappointed Kalam on his visit to Bokaro Steel Plant?',
    answer:
      'Kalam was disappointed to see that there was no industrial estate around the Steel Plant to utilize the steel produced in it to make various products.',
  },
  {
    question: 'What did Kalam think must be the way forward to help Jharkhand?',
    answer:
      'The integrated effort of both the state and the center would be the best way to help Jharkhand.',
  },
];
