export const scenes = [
  {
    title: 'Introduction to Loops 🔄',
    titleEmojis: [{ word: 'Loops', emoji: '🔄' }],
    description:
      'A loop means repeating actions until a task is complete. Imagine clapping 5 times! Loops help in doing repetitive tasks automatically, saving time and effort.',
    descriptionEmojis: [
      { word: 'loop', emoji: '🔄' },
      { word: 'repeating', emoji: '🔁' },
      { word: 'actions', emoji: '🎬' },
      { word: 'task', emoji: '✅' },
      { word: 'complete', emoji: '✅' },
      { word: 'Imagine', emoji: '💭' },
      { word: 'clapping', emoji: '👏' },
      { word: '5', emoji: '5️⃣' },
      { word: 'times', emoji: '⏳' },
      { word: 'Loops', emoji: '🔄' },
      { word: 'help', emoji: '🤝' },
      { word: 'repetitive', emoji: '🔁' },
      { word: 'tasks', emoji: '✅' },
      { word: 'automatically', emoji: '⚙️' },
      { word: 'saving', emoji: '💰' },
      { word: 'time', emoji: '⏳' },
      { word: 'effort', emoji: '💪' },
    ],
    content: [
      '👏 Clap once',
      '👏 Clap again',
      '👏 Keep clapping',
      '👏 Another clap',
      '👏 One last clap',
    ],
    animationType: 'fadeInFromBottom',
    styles: {
      backgroundColor: '#e3f2fd',
      headerBackground: 'linear-gradient(to right, #42a5f5, #1e88e5)',
      headerColor: '#ffffff',
    },
  },
  {
    title: 'Bake and Repeat 👩‍🍳🍪',
    titleEmojis: [
      { word: 'Bake', emoji: '👩‍🍳' },
      { word: 'Repeat', emoji: '🔁' },
    ],
    description:
      'Baking cookies involves repeating steps like mixing dough for multiple cookies. Similarly, e-commerce systems process orders or package items using loops.',
    descriptionEmojis: [
      { word: 'Baking', emoji: '👩‍🍳' },
      { word: 'cookies', emoji: '🍪' },
      { word: 'repeating', emoji: '🔁' },
      { word: 'steps', emoji: '👣' },
      { word: 'mixing', emoji: '🥄' },
      { word: 'dough', emoji: '🥣' },
      { word: 'cookies', emoji: '🍪' },
      { word: 'e-commerce', emoji: '🛒' },
      { word: 'systems', emoji: '🖥️' },
      { word: 'process', emoji: '⚙️' },
      { word: 'orders', emoji: '📦' },
      { word: 'package', emoji: '📦' },
      { word: 'items', emoji: '📦' },
      { word: 'loops', emoji: '🔄' },
    ],
    content: [
      '🍪 Mix dough for Cookie 1',
      '🍪 Mix dough for Cookie 2',
      '🍪 Mix dough for Cookie 3',
    ],
    animationType: 'fadeInFromLeft',
    styles: {
      backgroundColor: '#fff3e0',
      headerBackground: 'linear-gradient(to right, #ffb74d, #f57c00)',
      headerColor: '#ffffff',
    },
  },
  {
    title: 'Nurturing Nature 💧🌻',
    titleEmojis: [{ word: 'Nature', emoji: '🌿' }],
    description:
      'Watering flowers involves repeating the same task for each plant. Similarly, IoT systems automate repetitive tasks like watering plants or managing smart gardens.',
    descriptionEmojis: [
      { word: 'Watering', emoji: '💧' },
      { word: 'flowers', emoji: '🌻' },
      { word: 'repeating', emoji: '🔁' },
      { word: 'task', emoji: '✅' },
      { word: 'plant', emoji: '🌱' },
      { word: 'IoT', emoji: '🤖' },
      { word: 'systems', emoji: '⚙️' },
      { word: 'automate', emoji: '🔄' },
      { word: 'repetitive', emoji: '🔁' },
      { word: 'tasks', emoji: '✅' },
      { word: 'watering', emoji: '💧' },
      { word: 'plants', emoji: '🌱' },
      { word: 'managing', emoji: '🗂️' },
      { word: 'smart', emoji: '🤖' },
      { word: 'gardens', emoji: '🏡' },
    ],
    content: [
      '🌻 Water Flower 1',
      '🌻 Water Flower 2',
      '🌻 Water Flower 3',
      '🌻 Water Flower 4',
    ],
    animationType: 'scaleIn',
    styles: {
      backgroundColor: '#e8f5e9',
      headerBackground: 'linear-gradient(to right, #66bb6a, #43a047)',
      headerColor: '#ffffff',
    },
  },
  {
    title: 'Loops in Music 🎵',
    titleEmojis: [
      { word: 'Loops', emoji: '🔄' },
      { word: 'Music', emoji: '🎵' },
    ],
    description:
      'Loops appear in music too! When you repeat the same part of a song, you’re using loops. Streaming platforms use loops to autoplay songs or videos endlessly.',
    descriptionEmojis: [
      { word: 'Loops', emoji: '🔄' },
      { word: 'appear', emoji: '👀' },
      { word: 'music', emoji: '🎵' },
      { word: 'repeat', emoji: '🔁' },
      { word: 'song', emoji: '🎶' },
      { word: 'loops', emoji: '🔄' },
      { word: 'Streaming', emoji: '📱' },
      { word: 'platforms', emoji: '📱' },
      { word: 'loops', emoji: '🔄' },
      { word: 'autoplay', emoji: '⏯️' },
      { word: 'songs', emoji: '🎶' },
      { word: 'videos', emoji: '📹' },
      { word: 'endlessly', emoji: '∞' },
    ],
    content: ['🎶 La, La, La (repeat 3 times)'],
    animationType: 'textWriting',
    styles: {
      backgroundColor: '#ede7f6',
      headerBackground: 'linear-gradient(to right, #7e57c2, #5e35b1)',
      headerColor: '#ffffff',
    },
  },
  {
    title: 'Social Media Reload 🔄',
    titleEmojis: [
      { word: 'Social', emoji: '🌐' },
      { word: 'Media', emoji: '📱' },
      { word: 'Reload', emoji: '🔄' },
    ],
    description:
      'Social media uses loops to refresh feeds, fetch posts, load comments, and display notifications, ensuring your experience stays up to date in real-time.',
    descriptionEmojis: [
      { word: 'Social', emoji: '🌐' },
      { word: 'media', emoji: '📱' },
      { word: 'loops', emoji: '🔄' },
      { word: 'refresh', emoji: '🔄' },
      { word: 'feeds', emoji: '♻️' },
      { word: 'fetch', emoji: '📥' },
      { word: 'posts', emoji: '📄' },
      { word: 'load', emoji: '📥' },
      { word: 'comments', emoji: '💬' },
      { word: 'notifications', emoji: '🔔' },
      { word: 'date', emoji: '📅' },
      { word: 'real-time', emoji: '⏱️' },
    ],
    content: [
      '📄 Fetch new posts from the server',
      '💬 Load user comments dynamically',
      '🔔 Display notifications (likes, shares, messages)',
      '♻️ Refresh the feed with new content',
    ],
    animationType: 'fadeInFromBottom',
    styles: {
      backgroundColor: '#e3f2fd',
      headerBackground: 'linear-gradient(to right, #1e88e5, #1565c0)',
      headerColor: '#ffffff',
    },
  },
  {
    title: 'Review Analysis 🛒',
    titleEmojis: [
      { word: 'Review', emoji: '⭐' },
      { word: 'Analysis', emoji: '📊' },
    ],
    description:
      'E-commerce platforms process customer reviews to highlight the best ones, find trends, and keep recommendations up to date for every shopper.',
    descriptionEmojis: [
      { word: 'E-commerce', emoji: '🛒' },
      { word: 'platforms', emoji: '🖥️' },
      { word: 'process', emoji: '⚙️' },
      { word: 'customer', emoji: '🧑‍💼' },
      { word: 'reviews', emoji: '⭐' },
      { word: 'highlight', emoji: '💡' },
      { word: 'best', emoji: '🏆' },
      { word: 'find', emoji: '🔍' },
      { word: 'trends', emoji: '📈' },
      { word: 'recommendations', emoji: '📚' },
      { word: 'date', emoji: '📅' },
      { word: 'shopper', emoji: '🛍️' },
    ],
    content: [
      '⭐ Analyze reviews for ratings',
      '💡 Highlight the most helpful ones',
      '📈 Find trending products',
      '📚 Update wishlists and recommendations',
    ],
    animationType: 'fadeInFromLeft',
    styles: {
      backgroundColor: '#fff3e0',
      headerBackground: 'linear-gradient(to right, #fb8c00, #ef6c00)',
      headerColor: '#ffffff',
    },
  },
  {
    title: 'Customer Insights 📊',
    titleEmojis: [
      { word: 'Customer', emoji: '🧑‍💼' },
      { word: 'Insights', emoji: '📊' },
    ],
    description:
      'Businesses analyze customer preferences, habits, and purchase history using loops to create personalized recommendations and targeted marketing.',
    descriptionEmojis: [
      { word: 'Businesses', emoji: '🏢' },
      { word: 'analyze', emoji: '🧠' },
      { word: 'customer', emoji: '🧑‍💼' },
      { word: 'preferences', emoji: '🎯' },
      { word: 'habits', emoji: '📋' },
      { word: 'purchase', emoji: '🛒' },
      { word: 'history', emoji: '📜' },
      { word: 'loops', emoji: '🔄' },
      { word: 'create', emoji: '🎨' },
      { word: 'personalized', emoji: '💖' },
      { word: 'recommendations', emoji: '📚' },
      { word: 'targeted', emoji: '🎯' },
      { word: 'marketing', emoji: '📢' },
    ],
    content: [
      '📂 Track user browsing and purchase history',
      '💖 Create personalized recommendations',
      '📧 Send automated marketing emails',
      '📊 Measure ad performance',
    ],
    animationType: 'fadeInFromTop',
    styles: {
      backgroundColor: '#f3e5f5', // Light lavender background
      headerBackground: 'linear-gradient(to right, #ab47bc, #8e24aa)', // Purple gradient
      headerColor: '#ffffff', // White text for header
    },
  },
  {
    title: 'Smart Helpers 🤖',
    titleEmojis: [
      { word: 'Smart', emoji: '🤖' },
      { word: 'Helpers', emoji: '🤝' },
    ],
    description:
      'Virtual assistants like Alexa or Siri use loops to continuously listen, analyze commands, and perform tasks seamlessly for you.',
    descriptionEmojis: [
      { word: 'Virtual', emoji: '📱' },
      { word: 'assistants', emoji: '🤖' },
      { word: 'Alexa', emoji: '🗣️' },
      { word: 'Siri', emoji: '🎙️' },
      { word: 'loops', emoji: '🔄' },
      { word: 'continuously', emoji: '♾️' },
      { word: 'listen', emoji: '👂' },
      { word: 'analyze', emoji: '🧠' },
      { word: 'commands', emoji: '📜' },
      { word: 'perform', emoji: '✅' },
      { word: 'tasks', emoji: '⚙️' },
      { word: 'seamlessly', emoji: '🧵' },
    ],
    content: [
      '🎙️ Listen for commands in real time',
      '🧠 Analyze voice inputs',
      '✅ Respond or perform tasks',
      '📚 Train AI with datasets',
    ],
    animationType: 'rotateIn',
    styles: {
      backgroundColor: '#e8f5e9', // Light green background
      headerBackground: 'linear-gradient(to right, #43a047, #2e7d32)', // Green gradient
      headerColor: '#ffffff', // White text for header
    },
  },
  {
    title: 'You Did It! 🎉',
    titleEmojis: [{ word: 'It!', emoji: '🎉' }],
    description:
      'Congratulations on learning how loops work! You discovered their role in automating tasks, analyzing data, and even managing everyday tech tools.',
    descriptionEmojis: [
      { word: 'Congratulations', emoji: '🎉' },
      { word: 'learning', emoji: '📘' },
      { word: 'loops', emoji: '🔄' },
      { word: 'work', emoji: '🛠️' },
      { word: 'discovered', emoji: '🔍' },
      { word: 'automating', emoji: '🤖' },
      { word: 'tasks', emoji: '⚙️' },
      { word: 'analyzing', emoji: '🧠' },
      { word: 'data', emoji: '📊' },
      { word: 'managing', emoji: '📋' },
      { word: 'tech', emoji: '💻' },
      { word: 'tools', emoji: '⚙️' },
    ],
    content: [
      '⚙️ Automate repetitive tasks',
      '📊 Process data in industries',
      '🎯 Practice using loops in your projects',
    ],
    animationType: 'scaleIn',
    styles: {
      backgroundColor: '#fbe9e7',
      headerBackground: 'linear-gradient(to right, #e53935, #d32f2f)',
      headerColor: '#ffffff',
    },
  },
];
