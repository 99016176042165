export const questionsAndAnswersLessonFive = [
  {
    question: 'What was the first foreign land the speaker described?',
    answer:
      "The garden lying next door was the first 'foreign land' the speaker described in the poem.",
  },
  {
    question: "What was described as the sky's looking glass?",
    answer:
      "The 'dimpling river pass' was described as the sky's looking glass as the water reflects the clear blue sky.",
  },
  {
    question:
      'What was the third thing the speaker could see from the cherry tree?',
    answer:
      'The third thing that the speaker could see from the cherry tree was the dusty roads going up and down the mountain with people trampling into town.',
  },
  {
    question: 'From where could the speaker see the river flow into the sea?',
    answer:
      'The speaker says that he could see the river flow into the sea from atop of a higher tree, if he could find one.',
  },
  {
    question: 'What were the two things the speaker mentioned about fairyland?',
    answer:
      'The two things the speaker mentioned about the fairy land were that all the children there would dine at five and all play things would come alive.',
  },
];
