export const questionsAndAnswersLessonFive = [
  {
    question:
      "Why does the speaker feel that we have no time to 'stand and stare'?",
    answer:
      "The poet feels that we have no time to 'stand and stare' because we are engaged in our daily activities that we have no time to stop and enjoy the little things in life.",
  },
  {
    question:
      'According to the speaker, what are the simple delights that we miss out on in our busy lives?',
    answer:
      'The boughs, the woods, squirrels hiding their nuts in grass, broad daylight, and streams full of stars at night are the simple delights that we miss out on in our busy life.',
  },
  {
    question: "What does the phrase 'no time' mean?",
    answer:
      "The phrase 'no time' means deep. The speaker is describing that people are engrossed in their lives that they don't have time.",
  },
  {
    question: 'What message does the speaker wish to convey through the poem?',
    answer:
      'We should value time and do constructive work, but at the same time, we should also take out little leisure time to enjoy simple delights. The speaker wishes us to take out leisure time in life to make it more meaningful and enriching.',
  },
];
