import { englishLessons } from '../lessons/class6/englishLessons.js';
import { mathsLessons } from '../lessons/class6/mathsLessons.js';

export const class6Subjects = [
  {
    subjectName: 'English',
    color: '#FFB6C1',
    lessons: englishLessons,
  },
  {
    subjectName: 'Maths',
    color: '#FFB6C1',
    lessons: mathsLessons,
  },
];
