import { SchoolActivityTypes } from '../../../../../../../enums/constants.js';

export const lesson1Exercises1A = {
  lessonName: 'Lesson 1: Exercises 1 A',
  color: '#90EE90',
  activities: [
    {
      activityName: 'Write the numeral for each of the following:',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question: '(i) Eight lakh five thousand twelve.',
          prompt: `1\\1 A\\1_Write_the_numeral_for_each_of_the_following___i__Eight_lakh_five_thousand_twelve_.js`,
        },
        {
          question: '(ii) Thirteen lakh three thousand eight.',
          prompt: `1\\1 A\\1_Write_the_numeral_for_each_of_the_following___ii__Thirteen_lakh_three_thousand_eight_.js`,
        },
        {
          question: '(iii) Three crore three lakh three thousand three',
          prompt: `1\\1 A\\1_Write_the_numeral_for_each_of_the_following___iii__Three_crore_three_lakh_three_thousand_three.js`,
        },
        {
          question: '(iv) Five crore twelve lakh eighteen.',
          prompt: `1\\1 A\\1_Write_the_numeral_for_each_of_the_following___iv__Five_crore_twelve_lakh_eighteen_.js`,
        },
        {
          question: '(v) Nine crore nineteen lakh five thousand eight.',
          prompt: `1\\1 A\\1_Write_the_numeral_for_each_of_the_following___v__Nine_crore_nineteen_lakh_five_thousand_eight_.js`,
        },
        {
          question:
            '(vi) Six crore thirty five lakh nineteen thousand sixteen.',
          prompt: `1\\1 A\\1_Write_the_numeral_for_each_of_the_following___vi__Six_crore_thirty_five_lakh_nineteen_thousand_sixteen_.js`,
        },
        {
          question:
            '(vii) Eleven crore twenty two lakh thirty three thousand four hundred fifty',
          prompt: `1\\1 A\\1_Write_the_numeral_for_each_of_the_following___vii__Eleven_crore_twenty_two_lakh_thirty_three_thousand_four_hundred_fifty.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName: 'Write each of the following in words:',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question: '(i) 8,08,080',
          prompt: `1\\1 A\\2_Write_each_of_the_following_in_words___i__8_08_080.js`,
        },
        {
          question: '(ii)15,07,063',
          prompt: `1\\1 A\\2_Write_each_of_the_following_in_words___ii_15_07_063.js`,
        },
        {
          question: '(iii)87,08,109',
          prompt: `1\\1 A\\2_Write_each_of_the_following_in_words___iii_87_08_109.js`,
        },
        {
          question: '(iv)2,14,05,063',
          prompt: `1\\1 A\\2_Write_each_of_the_following_in_words___iv_2_14_05_063.js`,
        },
        {
          question: '(v)3,03,03,103',
          prompt: `1\\1 A\\2_Write_each_of_the_following_in_words___v_3_03_03_103.js`,
        },
        {
          question: '(vi)10,06,05,368',
          prompt: `1\\1 A\\2_Write_each_of_the_following_in_words___vi_10_06_05_368.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName: 'Write the place values:',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question: '(i) Write the place value of 4 in 8,46,572.',
          prompt: `1\\1 A\\3_Write_the_place_values___i__Write_the_place_value_of_4_in_8_46_572_.js`,
        },
        {
          question: '(ii) Write the place value of 7 in 7,30,493.',
          prompt: `1\\1 A\\3_Write_the_place_values___ii__Write_the_place_value_of_7_in_7_30_493_.js`,
        },
        {
          question: '(iii) Write the place value of 6 in 23,76,400.',
          prompt: `1\\1 A\\3_Write_the_place_values___iii__Write_the_place_value_of_6_in_23_76_400_.js`,
        },
        {
          question: '(iv) Write the place value of 9 in 19,63,605.',
          prompt: `1\\1 A\\3_Write_the_place_values___iv__Write_the_place_value_of_9_in_19_63_605_.js`,
        },
        {
          question: '(v) Write the place value of 8 in 20,07,189.',
          prompt: `1\\1 A\\3_Write_the_place_values___v__Write_the_place_value_of_8_in_20_07_189_.js`,
        },
        {
          question: '(vi) Write the place value of 3 in 23,608.',
          prompt: `1\\1 A\\3_Write_the_place_values___vi__Write_the_place_value_of_3_in_23_608_.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName:
        'Find the difference between the place-values of two sixes in 6,56,348.',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question:
            'Find the difference between the place-values of two sixes in 6,56,348.',
          prompt: `1\\1 A\\4_Find_the_difference_between_the_place_values_of_two_sixes_in_6_56_348__nan.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName:
        'Find the difference between the place-value and the face-value of 8 in the numeral 5,86,273.',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question:
            'Find the difference between the place-value and the face-value of 8 in the numeral 5,86,273.',
          prompt: `1\\1 A\\5_Find_the_difference_between_the_place_value_and_the_face_value_of_8_in_the_numeral_5_86_273__nan.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName: 'Write the number corresponding to each of the following:',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question: '(i) 5,16,287',
          prompt: `1\\1 A\\6_Write_the_number_corresponding_to_each_of_the_following___i__5_16_287.js`,
        },
        {
          question: '(ii) 13,25,694',
          prompt: `1\\1 A\\6_Write_the_number_corresponding_to_each_of_the_following___ii__13_25_694.js`,
        },
        {
          question: '(iii) 8,08,808',
          prompt: `1\\1 A\\6_Write_the_number_corresponding_to_each_of_the_following___iii__8_08_808.js`,
        },
        {
          question: '(iv) 64,72,319',
          prompt: `1\\1 A\\6_Write_the_number_corresponding_to_each_of_the_following___iv__64_72_319.js`,
        },
        {
          question: '(v) 1,36,04,107',
          prompt: `1\\1 A\\6_Write_the_number_corresponding_to_each_of_the_following___v__1_36_04_107.js`,
        },
        {
          question: '(vi) 9,36,50,519',
          prompt: `1\\1 A\\6_Write_the_number_corresponding_to_each_of_the_following___vi__9_36_50_519.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName: 'Write the number corresponding to each of the following:',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question:
            '(i) (5 , 1,00,000) + (1 , I0,000) + (4 x 1,000) + (7 x I00) + (3 x I0) + (8 x 1).',
          prompt: `1\\1 A\\7_Write_the_number_corresponding_to_each_of_the_following___i___5___1_00_000_____1___I0_000_____4_x_1_000_____7_x_I00_____3_x_I0_____8_x_1__.js`,
        },
        {
          question: '(ii) (6 x 1,00,000) + (6 x 1,000) + (3 x 10)+(6 x 1).',
          prompt: `1\\1 A\\7_Write_the_number_corresponding_to_each_of_the_following___ii___6_x_1_00_000_____6_x_1_000_____3_x_10___6_x_1__.js`,
        },
        {
          question:
            '(iii) (1 x 10,00,000) + (2 x 1,00,000) + (3 x 10,000) + (4 x 100) + (6 x 10) + (9 x 1).',
          prompt: `1\\1 A\\7_Write_the_number_corresponding_to_each_of_the_following___iii___1_x_10_00_000_____2_x_1_00_000_____3_x_10_000_____4_x_100_____6_x_10_____9_x_1__.js`,
        },
        {
          question:
            '(iv) (2 x 10,00,000) + (3 x 1,00,000) + (7 x 1,000) + (9 x 100) + (4 x 10) + (5 x 1).',
          prompt: `1\\1 A\\7_Write_the_number_corresponding_to_each_of_the_following___iv___2_x_10_00_000_____3_x_1_00_000_____7_x_1_000_____9_x_100_____4_x_10_____5_x_1__.js`,
        },
        {
          question: '(v) (9 x 10,00,000) + (8 x 1,000) + (8 x 100) + (8 x 1).',
          prompt: `1\\1 A\\7_Write_the_number_corresponding_to_each_of_the_following___v___9_x_10_00_000_____8_x_1_000_____8_x_100_____8_x_1__.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName: 'Write the successor of each of the following:',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question: '(i) 6001',
          prompt: `1\\1 A\\8_Write_the_successor_of_each_of_the_following___i__6001.js`,
        },
        {
          question: '(ii) 1099',
          prompt: `1\\1 A\\8_Write_the_successor_of_each_of_the_following___ii__1099.js`,
        },
        {
          question: '(iii) 12749',
          prompt: `1\\1 A\\8_Write_the_successor_of_each_of_the_following___iii__12749.js`,
        },
        {
          question: '(iv) 2,19,708',
          prompt: `1\\1 A\\8_Write_the_successor_of_each_of_the_following___iv__2_19_708.js`,
        },
        {
          question: '(v) 62399',
          prompt: `1\\1 A\\8_Write_the_successor_of_each_of_the_following___v__62399.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName: 'Write the predecessor of each of the following numbers:',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question: '(i) 99',
          prompt: `1\\1 A\\9_Write_the_predecessor_of_each_of_the_following_numbers___i__99.js`,
        },
        {
          question: '(ii) 1305',
          prompt: `1\\1 A\\9_Write_the_predecessor_of_each_of_the_following_numbers___ii__1305.js`,
        },
        {
          question: '(iii) 32000',
          prompt: `1\\1 A\\9_Write_the_predecessor_of_each_of_the_following_numbers___iii__32000.js`,
        },
        {
          question: '(iv) 1,65,000',
          prompt: `1\\1 A\\9_Write_the_predecessor_of_each_of_the_following_numbers___iv__1_65_000.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName: 'Write the whole number whose successor is:',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question: '(i) 100',
          prompt: `1\\1 A\\10_Write_the_whole_number_whose_successor_is___i__100.js`,
        },
        {
          question: '(ii) 6299',
          prompt: `1\\1 A\\10_Write_the_whole_number_whose_successor_is___ii__6299.js`,
        },
        {
          question: '(iii) 71650',
          prompt: `1\\1 A\\10_Write_the_whole_number_whose_successor_is___iii__71650.js`,
        },
        {
          question: '(iv) 42000',
          prompt: `1\\1 A\\10_Write_the_whole_number_whose_successor_is___iv__42000.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName: 'Write the whole number whose predecessor is:',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question: '(i) 1000',
          prompt: `1\\1 A\\11_Write_the_whole_number_whose_predecessor_is___i__1000.js`,
        },
        {
          question: '(ii) 3189',
          prompt: `1\\1 A\\11_Write_the_whole_number_whose_predecessor_is___ii__3189.js`,
        },
        {
          question: '(iii) 3001',
          prompt: `1\\1 A\\11_Write_the_whole_number_whose_predecessor_is___iii__3001.js`,
        },
        {
          question: '(iv) 8000',
          prompt: `1\\1 A\\11_Write_the_whole_number_whose_predecessor_is___iv__8000.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName:
        'Write down three consecutive whole numbers succeeding 72,597.',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question:
            'Write down three consecutive whole numbers succeeding 72,597.',
          prompt: `1\\1 A\\12_Write_down_three_consecutive_whole_numbers_succeeding_72_597__nan.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName:
        'Write down three consecutive whole numbers just preceding 5,10,001.',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question:
            'Write down three consecutive whole numbers just preceding 5,10,001.',
          prompt: `1\\1 A\\13_Write_down_three_consecutive_whole_numbers_just_preceding_5_10_001__nan.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName: 'How many 6-digit numbers are there in all?',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question: 'How many 6-digit numbers are there in all?',
          prompt: `1\\1 A\\14_How_many_6_digit_numbers_are_there_in_all__nan.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName: 'Write the:',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question: '(i) Write the largest 8-digit number.',
          prompt: `1\\1 A\\15_Write_the___i__Write_the_largest_8_digit_number_.js`,
        },
        {
          question: '(ii) Write the smallest 8-digit number.',
          prompt: `1\\1 A\\15_Write_the___ii__Write_the_smallest_8_digit_number_.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName:
        'Write all possible 2-digit numbers formed by the digits 3, 7 and 9, when repetition of digits is not allowed',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question:
            'Write all possible 2-digit numbers formed by the digits 3, 7 and 9, when repetition of digits is not allowed',
          prompt: `1\\1 A\\16_Write_all_possible_2_digit_numbers_formed_by_the_digits_3__7_and_9__when_repetition_of_digits_is_not_allowed_nan.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName: 'Write all possible:',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question:
            '(i) Write all possible 3-digit numbers that can be formed by the digits 1, 3 and 7, using each digit only once in each number.',
          prompt: `1\\1 A\\17_Write_all_possible___i__Write_all_possible_3_digit_numbers_that_can_be_formed_by_the_digits_1__3_and_7__using_each_digit_only_once_in_each_number_.js`,
        },
        {
          question:
            '(ii) Write all possible 3-digit numbers that can be formed by the digits 9, 2 and 0, using each digit only once in each number.',
          prompt: `1\\1 A\\17_Write_all_possible___ii__Write_all_possible_3_digit_numbers_that_can_be_formed_by_the_digits_9__2_and_0__using_each_digit_only_once_in_each_number_.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName: 'Write the:',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question:
            '(i) Write the smallest 4-digit number that can be formed by the digits 0, 1, 3 and 6, using each digit only once.',
          prompt: `1\\1 A\\18_Write_the___i__Write_the_smallest_4_digit_number_that_can_be_formed_by_the_digits_0__1__3_and_6__using_each_digit_only_once_.js`,
        },
        {
          question:
            '(ii) Write the greatest 4-digit number that can be formed by the digits 0, 2, 7 and 5, using each digit only once.',
          prompt: `1\\1 A\\18_Write_the___ii__Write_the_greatest_4_digit_number_that_can_be_formed_by_the_digits_0__2__7_and_5__using_each_digit_only_once_.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName:
        'Write the smallest 4-digit number of four different digits. [Hint : The smallest 4 different digits are 0, 1, 2 and 3.]',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question:
            'Write the smallest 4-digit number of four different digits. [Hint : The smallest 4 different digits are 0, 1, 2 and 3.]',
          prompt: `1\\1 A\\19_Write_the_smallest_4_digit_number_of_four_different_digits___Hint___The_smallest_4_different_digits_are_0__1__2_and_3___nan.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
    {
      activityName:
        'Write the greatest 4-digit number of four different digits. [Hint : The greatest 4 different digits are 9, 8, 7 and 6]',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question:
            'Write the greatest 4-digit number of four different digits. [Hint : The greatest 4 different digits are 9, 8, 7 and 6]',
          prompt: `1\\1 A\\20_Write_the_greatest_4_digit_number_of_four_different_digits___Hint___The_greatest_4_different_digits_are_9__8__7_and_6__nan.js`,
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
  ],
};
