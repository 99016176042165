export const animationConfigs = {
  fadeInFromBottom: (index) => ({
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { delay: index * 0.2, duration: 0.5 },
  }),
  fadeInFromLeft: (index) => ({
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0 },
    transition: { delay: index * 0.2, duration: 0.5 },
  }),
  scaleIn: (index) => ({
    initial: { opacity: 0, scale: 0 },
    animate: { opacity: 1, scale: 1 },
    transition: { delay: index * 0.15, duration: 0.5 },
  }),
  rotateIn: (index) => ({
    initial: { opacity: 0, rotateX: 90 },
    animate: { opacity: 1, rotateX: 0 },
    transition: { delay: index * 0.2, duration: 0.5, ease: 'easeInOut' },
  }),
  fadeInFromTop: (index) => ({
    initial: { opacity: 0, y: -20 },
    animate: { opacity: 1, y: 0 },
    transition: { delay: index * 0.15, duration: 0.5 },
  }),
  textWriting: (text) => ({
    initial: { width: '0ch' },
    animate: {
      width: `${text.length + 1}ch`,
      borderRight: '0px solid currentColor', // Cursor hidden after animation,
    },
    transition: { duration: 2, ease: 'easeInOut' },
    style: {
      display: 'inline-block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontFamily: 'monospace',
    },
  }),
};
