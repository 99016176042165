export const questionsAndAnswersLessonThirteen = [
  {
    question:
      'Who had recently moved from one school to another? Name the school.',
    answer:
      'Swaminathan had recently moved from Albert Mission School to the Board School.',
  },
  {
    question: 'What was Swaminathan doing when Rajam arrived?',
    answer:
      'When Rajam arrived, Swaminathan was sitting in a dark corner of the house, trying to make a camera with a cardboard box and a spectacle lens. He was about to lose his temper because the hole in the box was a bit too large to hold the lens.',
  },
  {
    question:
      'Who was better at coming up with the names for the team? Which team did the two boys want to beat?',
    answer:
      "Swaminathan was better at coming up with the names for the team. The team that the boys wanted to beat was their own school's team, the Board High School Eleven.",
  },
  {
    question:
      'Why did Rajam want to buy three bats? Who objected to it, and why?',
    answer:
      'Rajam wanted to buy three bats to ensure that they had a spare. During the match, if one of the bats broke, the third bat would be a back-up. Mani objected to this idea because he did not want Rajam to waste money.',
  },
  {
    question: 'Where was Rajam going to order the cricket bats from? Why?',
    answer:
      'Rajam was going to order cricket bats from Messrs. Binns, which was a renowned shop for sports goods. He selected this supplier because their catalogue advertised that the Junior Willard Bats, Seven Eight, were made of the finest seasoned wood and were used by the players of the Cambridge Junior Boys Eleven.',
  },
];
