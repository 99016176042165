export const questionsAndAnswersLessonFifteen = [
  {
    question:
      "What did the speaker's ship encounter after sailing past the island of Fernando de Noronha?",
    answer:
      "The speaker's ship encountered a fearful storm after sailing past the island of Fernando de Noronha. The fierce winds tore at the sails, and the churning waters tossed the ship vigorously.",
  },
  {
    question: 'How did the speaker get stranded on the strange island?',
    answer:
      'The speaker left the damaged ship and headed for land in a small boat. However, a massive wave capsized the boat. Fortunately, another great wave carried the speaker to the shore, leaving him stranded on the strange island.',
  },
  {
    question: 'What did he find on the island during his first day?',
    answer:
      'The speaker discovered that the island had some hills and was deserted, with only animals and birds for company. He found a flat area to build a home and a small cave to store all his belongings.',
  },
  {
    question:
      'What did the speaker discover during his expedition on the island?',
    answer:
      'The speaker started his expedition from the stream and found some grassy plains nearby. Beyond the plains, he came across a forest with many kinds of fruit trees. He also discovered a lush green valley filled with beautiful flowers.',
  },
  {
    question: 'What were the strange sights that the speaker saw one day?',
    answer:
      'The speaker first came across a footprint in the sand. Then, he saw a boat far off in the sea. He also came upon a place where a fire had been built and a circle had been cut into the earth. These sights unnerved him.',
  },
  {
    question: 'How did he describe the island?',
    answer:
      'The island was far away from the mainland except for two small islands nearby. Its strange inhabitants had no contact with the outside world. The marvelous island promised exciting adventures for the speaker.',
  },
];
