export const startSelection = (
  event,
  canvasRef,
  viewport,
  setStartPoint,
  setIsDragging
) => {
  if (!viewport || !canvasRef.current) return;

  // Get the bounding rectangle of the canvas element
  const rect = canvasRef.current.getBoundingClientRect();

  // Compute the scale factors between the PDF viewport and the canvas size
  const scaleX = viewport.width / rect.width;
  const scaleY = viewport.height / rect.height;

  // Define the constant offset in canvas pixels
  const offset = 25;

  let x, y;

  // Use event.offsetX/offsetY if available (gives coordinates relative to the target element)
  if (
    typeof event.offsetX !== 'undefined' &&
    typeof event.offsetY !== 'undefined'
  ) {
    // Add the constant offset and then apply the scale factor
    x = (event.offsetX + offset) * scaleX;
    y = (event.offsetY + offset) * scaleY;
  } else {
    // Fallback for touch events or when offset properties are not defined
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;
    x = (clientX - rect.left + offset) * scaleX;
    y = (clientY - rect.top + offset) * scaleY;
  }

  // Debug logging to verify coordinate values
  console.log('Canvas rect:', rect);
  console.log('Scale factors:', { scaleX, scaleY });
  console.log('Calculated PDF coordinates:', { x, y });

  // Update the start point and set dragging flag
  setStartPoint({ x, y });
  setIsDragging(true);
};

export const updateSelection = (
  event,
  canvasRef,
  viewport,
  isDragging,
  startPoint,
  setSelectedArea
) => {
  if (!isDragging || !viewport || !canvasRef.current) return;

  const rect = canvasRef.current.getBoundingClientRect();
  const scaleX = viewport.width / rect.width;
  const scaleY = viewport.height / rect.height;

  // Detect if it's a touch event
  const clientX = event.touches ? event.touches[0].clientX : event.clientX;
  const clientY = event.touches ? event.touches[0].clientY : event.clientY;

  setSelectedArea({
    x: Math.min(startPoint.x, (clientX - rect.left) * scaleX),
    y: Math.min(startPoint.y, (clientY - rect.top) * scaleY),
    width: Math.abs(startPoint.x - (clientX - rect.left) * scaleX),
    height: Math.abs(startPoint.y - (clientY - rect.top) * scaleY),
  });
};

export const stopSelection = (
  event,
  selectedArea,
  setIsDragging,
  handleCanvasClick
) => {
  setIsDragging(false);
  if (selectedArea && selectedArea.width < 5 && selectedArea.height < 5) {
    handleCanvasClick(event);
  }
};

export const speakSelectedText = (
  window,
  canvasRef,
  getPageImageData,
  textItemsRef,
  updateHighlightByIndex,
  language,
  speed,
  setIsSpeaking,
  clearHighlight,
  selectedArea,
  viewport,
  setClickedItemIndex,
  speakFromClickedItem
) => {
  if (!selectedArea || !viewport || !textItemsRef.current) {
    console.warn('🔴 Exiting `speakSelectedText`: Missing dependencies.');

    if (!selectedArea) console.warn('❌ selectedArea is NULL or UNDEFINED.');
    if (!viewport) console.warn('❌ viewport is NULL or UNDEFINED.');
    if (!textItemsRef.current)
      console.warn('❌ textItemsRef.current is NULL or UNDEFINED.');

    return;
  }

  const selectedTextItems = textItemsRef.current.filter(
    (item) =>
      item.x >= selectedArea.x &&
      item.x + item.width <= selectedArea.x + selectedArea.width &&
      item.y >= selectedArea.y &&
      item.y + item.height <= selectedArea.y + selectedArea.height
  );

  if (selectedTextItems.length === 0) {
    console.warn('🔴 No text items found within the selected area.');
    console.warn('❌ selectedArea:', JSON.stringify(selectedArea, null, 2));
    console.warn('❌ viewport:', JSON.stringify(viewport, null, 2));
    console.warn(
      '❌ textItemsRef.current:',
      textItemsRef.current.length,
      'items available.'
    );

    return { startIndex: null, endIndex: null };
  }

  const startIndex = selectedTextItems[0].index;
  const endIndex = selectedTextItems[selectedTextItems.length - 1].index;

  console.log(
    `selectedTextItems: ${JSON.stringify(selectedTextItems, null, 4)}`
  );

  setClickedItemIndex(startIndex);
  console.log(
    'Selection area start index:',
    startIndex,
    ' Text:',
    textItemsRef.current[startIndex].text
  );

  speakFromClickedItem(
    window,
    canvasRef,
    getPageImageData,
    textItemsRef,
    updateHighlightByIndex,
    language,
    speed,
    setIsSpeaking,
    clearHighlight,
    startIndex,
    endIndex,
    false
  );
};
