import React, { useEffect, useState } from 'react';
import {
  Container,
  Form,
  Button,
  Card,
  Row,
  Col,
  ListGroup,
} from 'react-bootstrap';
import { addSchool, getAllSchools, deleteSchool } from '../../db/indexedDB.js';

const SSCSchoolManager = () => {
  const [schoolName, setSchoolName] = useState('');
  const [schoolColor, setSchoolColor] = useState('#FFDDC1');
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    fetchSchools();
  }, []);

  const fetchSchools = async () => {
    const data = await getAllSchools();
    setSchools(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!schoolName.trim()) return;

    await addSchool(schoolName, schoolColor);
    setSchoolName('');
    setSchoolColor('#FFDDC1');
    fetchSchools(); // Refresh school list after adding a new school
  };

  const handleDeleteSchool = async (schoolName) => {
    await deleteSchool(schoolName);
    fetchSchools(); // Refresh school list after deletion
  };

  return (
    <Container className="py-4">
      <h1 className="text-center mb-4">🏫 Manage Schools</h1>

      {/* Existing Schools List */}
      <Card className="p-4 shadow mb-4">
        <h4 className="text-center mb-3">📂 Existing Schools</h4>
        {schools.length > 0 ? (
          <ListGroup>
            {schools.map((school) => (
              <ListGroup.Item
                key={school.schoolName}
                style={{
                  backgroundColor: school.color,
                  color: '#000',
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span>
                  {school.schoolName} (Syllabus: {school.syllabus})
                </span>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDeleteSchool(school.schoolName)}
                >
                  ❌ Delete
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <p className="text-center">
            No schools available. Add a new one below.
          </p>
        )}
      </Card>

      {/* Input Form to Add a School */}
      <Card className="p-4 shadow">
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col md={8}>
              <Form.Group>
                <Form.Label>School Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter School Name"
                  value={schoolName}
                  onChange={(e) => setSchoolName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>School Color</Form.Label>
                <Form.Control
                  type="color"
                  value={schoolColor}
                  onChange={(e) => setSchoolColor(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit" className="w-100">
            ➕ Add School
          </Button>
        </Form>
      </Card>
    </Container>
  );
};

export default SSCSchoolManager;
