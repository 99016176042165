import React, { useState } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const REACT_APP_BASE_URL = process.env.PUBLIC_URL || ''; // Gets the base URL of the React App

const schoolLearningResources = [
  {
    title: '🧸 Preschool Learning App',
    description: '🎨 Fun games & activities for little learners.',
    board: 'Preschool',
    link: '/kids/preschool',
    bgColor: '#ffebcc', // Light Orange
  },
  {
    title: '📘 SSC Syllabus',
    description: '➕ Math, 📖 Science & More for SSC students.',
    board: 'SSC',
    link: '/kids/ssc',
    bgColor: '#ccffcc', // Light Green
  },
  {
    title: '🔬 ICSE Syllabus',
    description: '⚡ Interactive science modules & projects.',
    board: 'ICSE',
    link: '/kids/icse',
    bgColor: '#cce6ff', // Light Blue
  },
  {
    title: '📜 CBSE Syllabus',
    description: '🏛️ History, 📊 Math & more from CBSE syllabus.',
    board: 'CBSE',
    link: '/kids/cbse',
    bgColor: '#ffcccc', // Light Red
  },
  // Add more resources as needed
];

const SchoolLearningResources = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedBoard, setSelectedBoard] = useState('');

  const handleCardClick = (path, board) => {
    if (
      board === 'Preschool' ||
      board === 'SSC' ||
      board === 'CBSE' ||
      board === 'ICSE'
    ) {
      navigate(`${REACT_APP_BASE_URL}${path}`); // Redirect to PreschoolSyllabus
    } else {
      setSelectedBoard(board);
      setShowModal(true); // Show "In Progress" modal for SSC, CBSE, ICSE
    }
  };

  return (
    <Container>
      <h2 className="my-4 text-center kids-learning-text">
        📚 Interactive Learning Resources
      </h2>
      <Row>
        {schoolLearningResources.map((resource, index) => (
          <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
            <Card
              className="h-100 cursor-pointer text-center"
              style={{
                backgroundColor: resource.bgColor,
                borderRadius: '15px',
              }}
              onClick={() => handleCardClick(resource.link, resource.board)}
            >
              <Card.Body>
                <Card.Title className="fw-bold">{resource.title}</Card.Title>
                <Card.Text>{resource.description}</Card.Text>
              </Card.Body>
              <Card.Footer className="fw-bold">
                <small className="text-muted">{resource.board} 🎓</small>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Modal for "In Progress" Message */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>🚧 Work In Progress</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            🔨 The syllabus for {selectedBoard} is currently under development.
          </p>
          <p>📢 Stay tuned for updates!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default SchoolLearningResources;
