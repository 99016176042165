import { SchoolActivityTypes } from '../../../../../../enums/constants.js';
import { questionsAndAnswersLessonOne } from '../../constants/class5/english/lesson1.js';
import { questionsAndAnswersLessonTwo } from '../../constants/class5/english/lesson2.js';
import { questionsAndAnswersLessonThree } from '../../constants/class5/english/lesson3.js';
import { questionsAndAnswersLessonFour } from '../../constants/class5/english/lesson4.js';
import { questionsAndAnswersLessonFive } from '../../constants/class5/english/lesson5.js';
import { questionsAndAnswersLessonSix } from '../../constants/class5/english/lesson6.js';
import { questionsAndAnswersLessonSeven } from '../../constants/class5/english/lesson7.js';
// import { questionsAndAnswersLessonE } from '../constants/class5/english/lesson8.js';
import { questionsAndAnswersLessonNine } from '../../constants/class5/english/lesson9.js';
// import { questionsAndAnswersLessonTe } from '../constants/class5/english/lesson10.js';
import { questionsAndAnswersLessonEleven } from '../../constants/class5/english/lesson11.js';
import { questionsAndAnswersLessonTwelve } from '../../constants/class5/english/lesson12.js';
// import { questionsAndAnswersLessonTh } from '../constants/class5/english/lesson13.js';
import { questionsAndAnswersLessonFourteen } from '../../constants/class5/english/lesson14.js';
import { questionsAndAnswersLessonFifteen } from '../../constants/class5/english/lesson15.js';
import { questionsAndAnswersNDLessonOne } from '../../constants/class5/english/lessonND1.js';
import { questionsAndAnswersNDLessonTwo } from '../../constants/class5/english/lessonND2.js';
import { questionsAndAnswersNDLessonThree } from '../../constants/class5/english/lessonND3.js';
import { questionsAndAnswersNDLessonFour } from '../../constants/class5/english/lessonND4.js';
import { questionsAndAnswersNDLessonFive } from '../../constants/class5/english/lessonND5.js';
import { questionsAndAnswersNDLessonSix } from '../../constants/class5/english/lessonND6.js';

export const englishLessons = [
  {
    lessonName: 'Lesson 1: A Merry Christmas',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Question Answers',
        activityType: SchoolActivityTypes.QA_SPEAKING,
        list: questionsAndAnswersLessonOne,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
  {
    lessonName: 'Lesson 2: Great, Wide, Beautiful, Woderful World (Poetry)',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Question Answers',
        activityType: SchoolActivityTypes.QA_SPEAKING,
        list: questionsAndAnswersLessonTwo,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
  {
    lessonName: 'Lesson 3:',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Question Answers',
        activityType: SchoolActivityTypes.QA_SPEAKING,
        list: questionsAndAnswersLessonThree,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
  {
    lessonName: 'Lesson 4:',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Question Answers',
        activityType: SchoolActivityTypes.QA_SPEAKING,
        list: questionsAndAnswersLessonFour,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
  {
    lessonName: 'Lesson 5:',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Question Answers',
        activityType: SchoolActivityTypes.QA_SPEAKING,
        list: questionsAndAnswersLessonFive,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
  {
    lessonName: 'Lesson 6:',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Question Answers',
        activityType: SchoolActivityTypes.QA_SPEAKING,
        list: questionsAndAnswersLessonSix,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
  {
    lessonName: 'Lesson 7:',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Question Answers',
        activityType: SchoolActivityTypes.QA_SPEAKING,
        list: questionsAndAnswersLessonSeven,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
  {
    lessonName: 'Lesson 9:',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Question Answers',
        activityType: SchoolActivityTypes.QA_SPEAKING,
        list: questionsAndAnswersLessonNine,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
  {
    lessonName: 'Lesson 11:',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Question Answers',
        activityType: SchoolActivityTypes.QA_SPEAKING,
        list: questionsAndAnswersLessonEleven,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
  {
    lessonName: 'Lesson 12:',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Question Answers',
        activityType: SchoolActivityTypes.QA_SPEAKING,
        list: questionsAndAnswersLessonTwelve,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
  {
    lessonName: 'Lesson 14:',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Question Answers',
        activityType: SchoolActivityTypes.QA_SPEAKING,
        list: questionsAndAnswersLessonFourteen,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
  {
    lessonName: 'Lesson 15:',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Question Answers',
        activityType: SchoolActivityTypes.QA_SPEAKING,
        list: questionsAndAnswersLessonFifteen,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
  {
    lessonName: 'ND Lesson 1:',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Question Answers',
        activityType: SchoolActivityTypes.QA_SPEAKING,
        list: questionsAndAnswersNDLessonOne,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
  {
    lessonName: 'ND Lesson 2:',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Question Answers',
        activityType: SchoolActivityTypes.QA_SPEAKING,
        list: questionsAndAnswersNDLessonTwo,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
  {
    lessonName: 'ND Lesson 3:',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Question Answers',
        activityType: SchoolActivityTypes.QA_SPEAKING,
        list: questionsAndAnswersNDLessonThree,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
  {
    lessonName: 'ND Lesson 4:',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Question Answers',
        activityType: SchoolActivityTypes.QA_SPEAKING,
        list: questionsAndAnswersNDLessonFour,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
  {
    lessonName: 'ND Lesson 5:',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Question Answers',
        activityType: SchoolActivityTypes.QA_SPEAKING,
        list: questionsAndAnswersNDLessonFive,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
  {
    lessonName: 'ND Lesson 6:',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Question Answers',
        activityType: SchoolActivityTypes.QA_SPEAKING,
        list: questionsAndAnswersNDLessonSix,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
];
