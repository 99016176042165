export const questionsAndAnswersLessonFourteen = [
  {
    question:
      'Who, according to the speaker, prays to the morning light? Can you identify the poetic device that has been used here?',
    answer:
      'According to the speaker, the wakening skies pray to the morning light. The poetic device used in the line is personification—wakening skies pray to the morning light.',
  },
  {
    question: 'What is the wind compared to?',
    answer:
      'The wind is compared to a child. It is said that the wind lies asleep in the early hours when the dawn breaks, just like a child that has cried all night.',
  },
  {
    question:
      'What relationships does the speaker describe in stanza 2 of the poem?',
    answer:
      'The speaker discusses the relationships of the fisherman with the sea, the cloud, and the waves. It is said that the sea is their mother, the cloud is their brother, and the waves are their companions.',
  },
  {
    question: 'What does the speaker describe as "sweet" and "sweeter"?',
    answer:
      'The speaker describes the shade of the coconut glade, the scent of the mango grove, the sands under the full moon, and the voices of the loved ones to be "sweet." The kiss of the splashing water and the dance of the gleeful foam amidst the sea is described to be "sweeter."',
  },
  {
    question: 'What do you think is the wealth to the fishermen?',
    answer:
      'The fishes in the sea are the wealth to the fishermen. They are said to be the leaping wealth of the tide in the poem. The fishermen must hurry at dawn to capture this wealth and thereby earn their livelihood.',
  },
];
