import {
  teluguAlphabet,
  teluguConsonants,
} from '../constants/teluguCharacters.js';

export const teluguLessons = [
  {
    lessonName: 'Basic Telugu',
    color: '#90EE90',
    activities: [
      {
        activityName: 'తెలుగు అక్షరమాలు',
        list: teluguAlphabet,
        lang: 'Telugu',
        color: '#ffd1dc',
      },
      {
        activityName: 'తెలుగు వ్యంజనమాలు',
        list: teluguConsonants,
        lang: 'Telugu',
        color: '#ffb347',
      },
    ],
  },
];
