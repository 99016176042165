import React from 'react';
import { useParams } from 'react-router-dom';

// Import different textbook components based on class
import Class5TextbookList from '../ssc/data/schoolData/telanganaGovernmentDistributon/textBooks/Class5TextbookList.js';
import Class6TextbookList from '../ssc/data/schoolData/telanganaGovernmentDistributon/textBooks/Class6TextbookList.js';

const TextbookManager = () => {
  const { schoolName, className } = useParams();

  // Function to render the appropriate textbook component
  const renderTextbookComponent = () => {
    switch (className) {
      case 'Class 5':
        return (
          <Class5TextbookList schoolName={schoolName} className={className} />
        );
      case 'Class 6':
        return (
          <Class6TextbookList schoolName={schoolName} className={className} />
        );
      default:
        return <p className="text-center">No textbook found for {className}</p>;
    }
  };

  return (
    <div className="container mt-4">
      <h2 className="text-center">
        Textbook for school {schoolName} and class {className}
      </h2>
      {renderTextbookComponent()}
    </div>
  );
};

export default TextbookManager;
