// Numbers from 0 to 9
export const numbers = Array.from({ length: 11 }, (_, i) => i.toString());

// Alphabet A to Z
export const alphabet = Array.from({ length: 26 }, (_, i) =>
  String.fromCharCode(65 + i)
);

// Telugu Alphabets (Vowels)
export const teluguAlphabet = [
  'అ',
  'ఆ',
  'ఇ',
  'ఈ',
  'ఉ',
  'ఊ',
  'ఋ',
  'ఎ',
  'ఏ',
  'ఐ',
  'ఒ',
  'ఓ',
  'ఔ',
  'అం',
  'అః',
];

// Telugu Alphabets (Consonants)
export const teluguConsonants = [
  'క',
  'ఖ',
  'గ',
  'ఘ',
  'ఙ',
  'చ',
  'ఛ',
  'జ',
  'ఝ',
  'ఞ',
  'ట',
  'ఠ',
  'డ',
  'ఢ',
  'ణ',
  'త',
  'థ',
  'ద',
  'ధ',
  'న',
  'ప',
  'ఫ',
  'బ',
  'భ',
  'మ',
  'య',
  'ర',
  'ల',
  'వ',
  'శ',
  'ష',
  'స',
  'హ',
  'ళ',
  'క్ష',
  'ఱ',
];

// Animals
export const animals = [
  { emoji: '🦁', english: 'Lion', telugu: 'సింహం' },
  { emoji: '🐘', english: 'Elephant', telugu: 'ఏనుగు' },
  { emoji: '🐅', english: 'Tiger', telugu: 'పులి' },
  { emoji: '🐒', english: 'Monkey', telugu: 'కోతి' },
  { emoji: '🐄', english: 'Cow', telugu: 'ఆవు' },
  { emoji: '🐕', english: 'Dog', telugu: 'కుక్క' },
  { emoji: '🐈', english: 'Cat', telugu: 'పిల్లి' },
  { emoji: '🐎', english: 'Horse', telugu: 'గుర్రం' },
  { emoji: '🐂', english: 'Ox', telugu: 'ఎద్దు' },
  { emoji: '🐏', english: 'Sheep', telugu: 'గొర్రె' },
  { emoji: '🐐', english: 'Goat', telugu: 'మేక' },
  { emoji: '🐖', english: 'Pig', telugu: 'పంది' },
  { emoji: '🦌', english: 'Deer', telugu: 'జింక' },
  { emoji: '🐃', english: 'Buffalo', telugu: 'గేదె' },
  { emoji: '🐍', english: 'Snake', telugu: 'పాము' },
  { emoji: '🐢', english: 'Turtle', telugu: 'తాబేలు' },
  { emoji: '🦎', english: 'Lizard', telugu: 'బల్లి' },
  { emoji: '🦅', english: 'Eagle', telugu: 'రాజు గిద్ద' },
  { emoji: '🦉', english: 'Owl', telugu: 'గుడ్లగూబ' },
  { emoji: '🦆', english: 'Duck', telugu: 'ఆనె' },
  { emoji: '🦢', english: 'Swan', telugu: 'హంస' },
  { emoji: '🦜', english: 'Parrot', telugu: 'గువ్వ' },
  { emoji: '🦩', english: 'Flamingo', telugu: 'ఫ్లామింగో' },
  { emoji: '🦇', english: 'Bat', telugu: 'గబ్బిలం' },
  { emoji: '🐬', english: 'Dolphin', telugu: 'డాల్ఫిన్' },
  { emoji: '🐋', english: 'Whale', telugu: 'తిమింగలం' },
  { emoji: '🦈', english: 'Shark', telugu: 'సొర చేప' },
  { emoji: '🐊', english: 'Crocodile', telugu: 'ముగ్గు' },
  { emoji: '🐜', english: 'Ant', telugu: 'చీమ' },
  { emoji: '🐝', english: 'Bee', telugu: 'తేనెటీగ' },
  { emoji: '🦋', english: 'Butterfly', telugu: 'సీతాకోకచిలుక' },
  { emoji: '🐞', english: 'Ladybug', telugu: 'చివిపుట్టు' },
  { emoji: '🦂', english: 'Scorpion', telugu: 'చెదిరిపుజ్జు' },
];

// Vegetables
export const vegetables = [
  { emoji: '🥕', english: 'Carrot', telugu: 'క్యారెట్' },
  { emoji: '🍆', english: 'Brinjal', telugu: 'వంకాయ' },
  { emoji: '🌽', english: 'Corn', telugu: 'మొక్కజొన్న' },
  { emoji: '🥔', english: 'Potato', telugu: 'బంగాళాదుంప' },
  { emoji: '🍠', english: 'Sweet Potato', telugu: 'చిలగడదుంప' },
  { emoji: '🥒', english: 'Cucumber', telugu: 'దోసకాయ' },
  { emoji: '🥬', english: 'Lettuce', telugu: 'సలాడ్ ఆకులు' },
  { emoji: '🥦', english: 'Broccoli', telugu: 'బ్రోకొలీ' },
  { emoji: '🧅', english: 'Onion', telugu: 'ఉల్లిపాయ' },
  { emoji: '🧄', english: 'Garlic', telugu: 'వెల్లుల్లి' },
  { emoji: '🍄', english: 'Mushroom', telugu: 'పుట్టగొడుగు' },
  { emoji: '🌰', english: 'Chestnut', telugu: 'చెస్ట్నట్' },
  { emoji: '🥜', english: 'Peanut', telugu: 'పల్లి' },
  { emoji: '🥗', english: 'Spinach', telugu: 'పాలకూర' },
  { emoji: '🥦', english: 'Cauliflower', telugu: 'గోబీ' },
  { emoji: '🌶️', english: 'Chili Pepper', telugu: 'మిర్చి' },
  { emoji: '🫑', english: 'Capsicum', telugu: 'క్యాప్సికమ్' },
  { emoji: '🥒', english: 'Zucchini', telugu: 'జుకినీ' },
  { emoji: '🥑', english: 'Avocado', telugu: 'అవొకాడో' },
  { emoji: '🥜', english: 'Soybean', telugu: 'సోయా బీన్' },
];

// Fruits
export const fruits = [
  { emoji: '🍎', english: 'Apple', telugu: 'సేపు' },
  { emoji: '🍌', english: 'Banana', telugu: 'అరటి' },
  { emoji: '🍉', english: 'Watermelon', telugu: 'పుచ్చకాయ' },
  { emoji: '🍇', english: 'Grapes', telugu: 'ద్రాక్ష' },
  { emoji: '🍊', english: 'Orange', telugu: 'నారింజ' },
  { emoji: '🍍', english: 'Pineapple', telugu: 'అనాసపండు' },
  { emoji: '🍒', english: 'Cherry', telugu: 'చెర్రిపండు' },
  { emoji: '🥭', english: 'Mango', telugu: 'మామిడి' },
  { emoji: '🍑', english: 'Peach', telugu: 'పీత' },
  { emoji: '🍓', english: 'Strawberry', telugu: 'స్ట్రాబెర్రీ' },
  { emoji: '🍈', english: 'Melon', telugu: 'దోసకాయ' },
  { emoji: '🍏', english: 'Green Apple', telugu: 'పచ్చ సేపు' },
  { emoji: '🥥', english: 'Coconut', telugu: 'కొబ్బరి' },
  { emoji: '🥝', english: 'Kiwi', telugu: 'కివి పండు' },
  { emoji: '🍋', english: 'Lemon', telugu: 'నిమ్మకాయ' },
  { emoji: '🍐', english: 'Pear', telugu: 'బేరికాయ' },
  { emoji: '🫐', english: 'Blueberry', telugu: 'బ్లూబెర్రీ' },
  { emoji: '🥑', english: 'Avocado', telugu: 'అవొకాడో' },
  { emoji: '🍅', english: 'Tomato', telugu: 'టమోటా' },
  { emoji: '🫒', english: 'Olive', telugu: 'ఒలివ్' },
];

// Colors
export const colors = [
  { emoji: '🔴', english: 'Red', telugu: 'ఎరుపు' },
  { emoji: '🟢', english: 'Green', telugu: 'ఆకుపచ్చ' },
  { emoji: '🔵', english: 'Blue', telugu: 'నీలం' },
  { emoji: '🟡', english: 'Yellow', telugu: 'పసుపు' },
  { emoji: '🟠', english: 'Orange', telugu: 'నారింజ' },
  { emoji: '🟣', english: 'Purple', telugu: 'ఊదా' },
  { emoji: '⚫', english: 'Black', telugu: 'నలుపు' },
  { emoji: '⚪', english: 'White', telugu: 'తెలుపు' },
  { emoji: '🟤', english: 'Brown', telugu: 'గోధుమ' },
  { emoji: '🟡🟢', english: 'Lime', telugu: 'చిట్టపచ్చ' },
  { emoji: '🌸', english: 'Pink', telugu: 'గులాబీ' },
  { emoji: '🧊', english: 'Cyan', telugu: 'నీలి' },
  { emoji: '💜', english: 'Violet', telugu: 'నీలగోధుమ' },
  { emoji: '🌿', english: 'Teal', telugu: 'తురుమ' },
  { emoji: '🌑', english: 'Dark Grey', telugu: 'చీకటి బూడిద' },
  { emoji: '🌕', english: 'Light Grey', telugu: 'తెల్ల బూడిద' },
];

// Shapes
export const shapes = [
  { emoji: '⚫', english: 'Circle', telugu: 'వృత్తం' },
  { emoji: '◼️', english: 'Square', telugu: 'చతురస్రం' },
  { emoji: '🔺', english: 'Triangle', telugu: 'త్రిభుజం' },
  { emoji: '🔷', english: 'Diamond', telugu: 'వజ్రాకారం' },
  { emoji: '⬜', english: 'Rectangle', telugu: 'ఆయతం' },
  { emoji: '🟢', english: 'Oval', telugu: 'అండాకారం' },
  { emoji: '🔻', english: 'Pentagon', telugu: 'పంచభుజం' },
  { emoji: '🔶', english: 'Hexagon', telugu: 'షట్కోణం' },
  { emoji: '🔲', english: 'Parallelogram', telugu: 'సమాంతర చతురస్రం' },
  { emoji: '🛑', english: 'Octagon', telugu: 'అష్టభుజం' },
  { emoji: '📐', english: 'Right Angle Triangle', telugu: 'నిలువు త్రిభుజం' },
  { emoji: '🔄', english: 'Crescent', telugu: 'అర్థచంద్రం' },
  { emoji: '🌀', english: 'Spiral', telugu: 'సుడిగుండం' },
];

// Vehicles
export const vehicles = [
  { emoji: '🚗', english: 'Car', telugu: 'కారు' },
  { emoji: '🚲', english: 'Bicycle', telugu: 'సైకిల్' },
  { emoji: '✈️', english: 'Aeroplane', telugu: 'విమానము' },
  { emoji: '🚕', english: 'Taxi', telugu: 'టాక్సీ' },
  { emoji: '🚌', english: 'Bus', telugu: 'బస్సు' },
  { emoji: '🚎', english: 'Trolleybus', telugu: 'ట్రాలీబస్' },
  { emoji: '🏍️', english: 'Motorbike', telugu: 'మోటార్ బైక్' },
  { emoji: '🚛', english: 'Truck', telugu: 'ట్రక్' },
  { emoji: '🚜', english: 'Tractor', telugu: 'ట్రాక్టర్' },
  { emoji: '🚑', english: 'Ambulance', telugu: 'ఆంబులెన్స్' },
  { emoji: '🚒', english: 'Fire Truck', telugu: 'ఫైర్ ట్రక్' },
  { emoji: '🚓', english: 'Police Car', telugu: 'పోలీసు కారు' },
  { emoji: '🚚', english: 'Delivery Truck', telugu: 'డెలివరీ ట్రక్' },
  { emoji: '🚇', english: 'Metro', telugu: 'మెట్రో రైలు' },
  { emoji: '🚂', english: 'Train', telugu: 'రైలు' },
  { emoji: '🚤', english: 'Speed Boat', telugu: 'తీవ్ర నావ' },
  { emoji: '🛳️', english: 'Ship', telugu: 'ఓడ' },
  { emoji: '🚁', english: 'Helicopter', telugu: 'హెలికాప్టర్' },
  { emoji: '🛵', english: 'Scooter', telugu: 'స్కూటర్' },
  { emoji: '🛶', english: 'Canoe', telugu: 'డుంకా నావ' },
  {
    emoji: '🦼',
    english: 'Wheelchair Scooter',
    telugu: 'వీల్చెయిర్ స్కూటర్',
  },
  { emoji: '🚀', english: 'Rocket', telugu: 'రోకెట్' },
];

export const preSchoolSyllabusList = [
  {
    name: '🔢 Numbers',
    list: numbers,
    lang: 'English',
    color: '#ffebcc',
  },
  {
    name: '🔤 Alphabets',
    list: alphabet,
    lang: 'English',
    color: '#ccffcc',
  },
  {
    name: 'తెలుగు అక్షరమాలు',
    list: teluguAlphabet,
    lang: 'Telugu',
    color: '#ffd1dc',
  },
  {
    name: 'తెలుగు వ్యంజనమాలు',
    list: teluguConsonants,
    lang: 'Telugu',
    color: '#ffb347',
  },
  {
    name: '🐯 Animals',
    list: animals,
    lang: 'English & Telugu',
    color: '#d1e7ff',
    multiLingualTEEN: true,
  },
  {
    name: '🥦 Vegetables',
    list: vegetables,
    lang: 'English & Telugu',
    color: '#ffe6b3',
    multiLingualTEEN: true,
  },
  {
    name: '🍎 Fruits',
    list: fruits,
    lang: 'English & Telugu',
    color: '#ffb3b3',
    multiLingualTEEN: true,
  },
  {
    name: '🎨 Colors',
    list: colors,
    lang: 'English & Telugu',
    color: '#cceeff',
    multiLingualTEEN: true,
  },
  {
    name: '🔺 Shapes',
    list: shapes,
    lang: 'English & Telugu',
    color: '#e6ccff',
    multiLingualTEEN: true,
  },
  {
    name: '🚗 Vehicles',
    list: vehicles,
    lang: 'English & Telugu',
    color: '#ffcc99',
    multiLingualTEEN: true,
  },
];
