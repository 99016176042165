import React, { useState, useEffect } from 'react';
import {
  Container,
  Form,
  Button,
  Card,
  Table,
  ListGroup,
} from 'react-bootstrap';

const NumberSystemPeriods = ({ inputNumber = '987654321' }) => {
  const [number, setNumber] = useState(inputNumber);
  const [formattedNumber, setFormattedNumber] = useState('');
  const [numberName, setNumberName] = useState('');
  const [steps, setSteps] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [expandedForm, setExpandedForm] = useState('');
  const [currentStepIndex, setCurrentStepIndex] = useState(-1);
  const [isSpeaking, setIsSpeaking] = useState(false);

  // Speech function using browser's speech synthesis
  const speakText = (text) => {
    if ('speechSynthesis' in window) {
      setIsSpeaking(false);
      const utterance = new SpeechSynthesisUtterance(text);

      // Set isSpeaking to true when speech starts
      utterance.onstart = () => {
        setIsSpeaking(true);
        console.log('Speech started. isSpeaking:', isSpeaking);
      };

      // Set isSpeaking to false when speech ends
      utterance.onend = () => {
        setIsSpeaking(false);
        console.log('Speech ended. isSpeaking:', isSpeaking);
      };

      window.speechSynthesis.cancel(); // Cancel any previous utterance
      window.speechSynthesis.speak(utterance);
    }
  };

  // Speak the current step whenever it changes
  useEffect(() => {
    if (steps.length > 0) {
      speakText(steps[currentStepIndex]);
    }
  }, [currentStepIndex]);

  // 1. Format the number according to Indian numbering system
  const formatNumber = (num) => {
    let numStr = num.toString();
    let length = numStr.length;
    let stepList = [];

    // Insert commas in the correct places (Indian system)
    if (length > 3) {
      numStr = numStr.slice(0, length - 3) + ',' + numStr.slice(length - 3);
      stepList.push(`Step 1: Insert comma after hundreds - ${numStr}`);
    }
    if (length > 5) {
      numStr = numStr.slice(0, length - 5) + ',' + numStr.slice(length - 5);
      stepList.push(`Step 2: Insert comma after thousands - ${numStr}`);
    }
    if (length > 7) {
      numStr = numStr.slice(0, length - 7) + ',' + numStr.slice(length - 7);
      stepList.push(`Step 3: Insert comma after lakhs - ${numStr}`);
    }
    if (length > 9) {
      numStr = numStr.slice(0, length - 9) + ',' + numStr.slice(length - 9);
      stepList.push(`Step 4: Insert comma after crores - ${numStr}`);
    }

    return { formatted: numStr, stepList };
  };

  // 2. Convert the number to words (Indian numbering system)
  const numberToWords = (num) => {
    if (num === 0) return { words: 'zero', conversionSteps: [] };

    const ones = [
      '',
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine',
    ];
    const teens = [
      '',
      'eleven',
      'twelve',
      'thirteen',
      'fourteen',
      'fifteen',
      'sixteen',
      'seventeen',
      'eighteen',
      'nineteen',
    ];
    const tens = [
      '',
      'ten',
      'twenty',
      'thirty',
      'forty',
      'fifty',
      'sixty',
      'seventy',
      'eighty',
      'ninety',
    ];

    const getTwoDigitWords = (n) => {
      if (n < 10) return ones[n];
      if (n >= 11 && n <= 19) return teens[n - 10];
      if (n >= 10 && n < 100)
        return (
          tens[Math.floor(n / 10)] + (n % 10 !== 0 ? ' ' + ones[n % 10] : '')
        );
      return '';
    };

    let words = '';
    let crore = Math.floor(num / 10000000);
    let lakh = Math.floor((num % 10000000) / 100000);
    let thousand = Math.floor((num % 100000) / 1000);
    let hundred = Math.floor((num % 1000) / 100);
    let tensAndOnes = num % 100;

    let conversionSteps = [];
    if (crore > 0) {
      words += getTwoDigitWords(crore) + ' crore ';
      conversionSteps.push(
        `Step 1: First, we check if our number has any "crore" parts. We found ${getTwoDigitWords(crore)} crore, which means there are ${getTwoDigitWords(crore)} groups of crore in our number.`
      );
    }
    if (lakh > 0) {
      words += getTwoDigitWords(lakh) + ' lakh ';
      conversionSteps.push(
        `Step 2: Next, we look for "lakh" parts. There are ${getTwoDigitWords(lakh)} lakh, meaning ${getTwoDigitWords(lakh)} groups of one hundred thousand.`
      );
    }
    if (thousand > 0) {
      words += getTwoDigitWords(thousand) + ' thousand ';
      conversionSteps.push(
        `Step 3: Then, we check for the "thousand" part. This shows there are ${getTwoDigitWords(thousand)} thousand, which means ${getTwoDigitWords(thousand)} groups of one thousand.`
      );
    }
    if (hundred > 0) {
      words += ones[hundred] + ' hundred ';
      conversionSteps.push(
        `Step 4: Now, we check for hundreds. Our number has ${ones[hundred]} hundred, which means ${ones[hundred]} group(s) of one hundred.`
      );
    }
    if (tensAndOnes > 0) {
      words += getTwoDigitWords(tensAndOnes);
      conversionSteps.push(
        `Step 5: Finally, we look at the last two digits (tens and ones). This part is read as "${getTwoDigitWords(tensAndOnes)}".`
      );
    }

    return { words: words.trim(), conversionSteps };
  };

  // 3. Generate table data for the place values
  const generateTableData = (num) => {
    let numStr = num.toString().padStart(9, '0'); // At least 9 digits for alignment
    return [
      numStr[0],
      numStr[1],
      numStr[2],
      numStr[3],
      numStr[4],
      numStr[5],
      numStr[6],
      numStr[7],
      numStr[8],
    ];
  };

  // 4. Generate the expanded form, e.g. 856 => (8 x 100) + (5 x 10) + (6 x 1)
  const getExpandedForm = (num) => {
    const numStr = num.toString();
    let expandedParts = [];

    for (let i = 0; i < numStr.length; i++) {
      const digit = numStr[i];
      const placeValue = Math.pow(10, numStr.length - i - 1);
      expandedParts.push(`( ${digit} x ${placeValue} )`);
    }
    return expandedParts.join(' + ');
  };

  // 5. Handle the submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const numericValue = parseInt(number, 10) || 0;

    // Format number with commas
    const { formatted, stepList: formatSteps } = formatNumber(numericValue);

    // Convert to words
    const { words, conversionSteps } = numberToWords(numericValue);

    // Use conversionSteps for interactive explanation, fallback to formatSteps if empty
    const combinedSteps =
      conversionSteps.length > 0 ? conversionSteps : formatSteps;

    // Prepare table data
    const tableValues = generateTableData(numericValue);

    // Prepare expanded form
    const expansion = getExpandedForm(numericValue);

    setFormattedNumber(formatted);
    setNumberName(words);
    setTableData(tableValues);
    setExpandedForm(expansion);
    setSteps(combinedSteps);
    // setCurrentStepIndex(0);
    // Reset interactive step index on new submission
  };

  return (
    <Container className="mt-4">
      <Card className="p-4">
        <h3>Interactive Number Formatting Activity</h3>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Enter a Number:</Form.Label>
            <Form.Control
              type="number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </Form.Group>
          <Button type="submit" variant="primary">
            Format Number
          </Button>
        </Form>

        {/* Only show results if the user has submitted */}
        {formattedNumber && (
          <Card className="mt-3 p-3 text-start">
            {/* Interactive Step-by-Step Explanation */}
            <div className="mt-3">
              <h4>Interactive Step-by-Step Explanation:</h4>
              {steps.length > 0 && (
                <div className="d-flex align-items-center">
                  <Button
                    variant="secondary"
                    disabled={isSpeaking || currentStepIndex === 0}
                    onClick={() => setCurrentStepIndex(currentStepIndex - 1)}
                  >
                    Previous
                  </Button>
                  <div className="mx-3 flex-grow-1 text-center">
                    <p>
                      <strong>{steps[currentStepIndex]}</strong>
                    </p>
                  </div>
                  <Button
                    variant="secondary"
                    disabled={
                      isSpeaking || currentStepIndex === steps.length - 1
                    }
                    onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
                  >
                    Next
                  </Button>
                </div>
              )}
            </div>

            <h5>
              <strong>Formatted Number:</strong> {formattedNumber}
            </h5>
            <h5>
              <strong>Number Name:</strong> {numberName}
            </h5>
            <h5>
              <strong>Expanded Form:</strong>
            </h5>
            <pre
              style={{
                fontWeight: 'bold',
                marginLeft: '20px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {expandedForm.split(' + ').join('\n')}
            </pre>

            <div className="d-none d-md-block table-responsive">
              {/* Regular Table for Medium and Large Screens */}
              <Table bordered className="mt-3">
                <thead className="text-center">
                  <tr>
                    <th>Ten Crores</th>
                    <th>Crores</th>
                    <th>Ten Lakhs</th>
                    <th>Lakhs</th>
                    <th>Ten Thousands</th>
                    <th>Thousands</th>
                    <th>Hundreds</th>
                    <th>Tens</th>
                    <th>Ones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center">
                    {tableData.map((digit, index) => (
                      <td key={index} className="p-2">
                        {digit}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
            </div>

            {/* Stacked Layout for Small Screens */}
            <div className="d-md-none">
              <ListGroup>
                {[
                  'Ten Crores',
                  'Crores',
                  'Ten Lakhs',
                  'Lakhs',
                  'Ten Thousands',
                  'Thousands',
                  'Hundreds',
                  'Tens',
                  'Ones',
                ].map((label, index) => (
                  <ListGroup.Item
                    key={index}
                    className="d-flex justify-content-between"
                  >
                    <strong>{label}:</strong> <span>{tableData[index]}</span>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </Card>
        )}
      </Card>
    </Container>
  );
};

export default NumberSystemPeriods;
