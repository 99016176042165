export const questionsAndAnswersLessonTwo = [
  {
    question: 'How does the speaker describe the wind?',
    answer:
      'The speaker describes the wind as wonderful. He is narrating the beauty of wind, how the wind is blowing over the hills, shaking the trees and back chatting to itself.',
  },
  {
    question:
      'Name some of the natural elements of our world that are mentioned in the poem.',
    answer:
      'Water, wind, cliffs, isles are some of the natural elements of our world that are mentioned in the poem.',
  },
  {
    question: 'Why does the world make the speaker tremble?',
    answer:
      'The world makes the speaker tremble because the Earth is a giving planet and we humans are exploiting the earth.',
  },
  {
    question: 'How can we tell that the wind walks over the water?',
    answer:
      'We know that the wind walks over the water when waves and ripples are formed on the water.',
  },
  {
    question: 'Mention the figures of speech used in the poem.',
    answer:
      "The figures of speech mentioned in the poem are: Alliteration - 'tremble to think', Personification - 'wheat fields that nod'.",
  },
];
