import React, { useState, useEffect } from 'react';
import { openDB } from 'idb';

const DB_NAME = 'SyllabusDB';
const STORE_NAME = 'SyllabusStore';

const SyllabusForm = () => {
  const [formData, setFormData] = useState({
    syllabusType: '',
    schoolName: '',
    classNo: '',
    lessonNo: '',
    lessonName: '',
    questionNo: '',
    question: '',
    answer: '',
  });

  const [existingData, setExistingData] = useState([]);
  //   const [classOptions, setClassOptions] = useState([]);
  //   const [lessonOptions, setLessonOptions] = useState([]);
  //   const [questionOptions, setQuestionOptions] = useState([]);

  // Initialize IndexedDB
  const initDB = async () => {
    return openDB(DB_NAME, 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME, {
            keyPath: 'id',
            autoIncrement: true,
          });
        }
      },
    });
  };

  // Save data to IndexedDB
  const saveData = async () => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    await store.add(formData);
    alert('Data saved successfully!');
    loadExistingData(); // Refresh stored data
  };

  // Load all stored data for search suggestions
  const loadExistingData = async () => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);
    const allData = await store.getAll();
    setExistingData(allData);

    // Extract unique values for dropdowns
    // setClassOptions([...new Set(allData.map((item) => item.classNo))]);
    // setLessonOptions([...new Set(allData.map((item) => item.lessonNo))]);
    // setQuestionOptions([...new Set(allData.map((item) => item.questionNo))]);
  };

  useEffect(() => {
    loadExistingData();
  }, []);

  // Autofill fields based on existing data
  const autofillData = (field, value) => {
    const matchedEntry = existingData.find((item) => item[field] === value);
    if (matchedEntry) {
      setFormData({ ...formData, ...matchedEntry });
    } else {
      setFormData({ ...formData, [field]: value });
    }
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Trigger autofill for specific fields
    if (
      [
        'syllabusType',
        'schoolName',
        'classNo',
        'lessonNo',
        'lessonName',
        'questionNo',
      ].includes(name)
    ) {
      autofillData(name, value);
    }
  };

  return (
    <div>
      <h2>Syllabus Data Entry</h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <label>Syllabus Type:</label>
        <input
          type="text"
          name="syllabusType"
          value={formData.syllabusType}
          onChange={handleChange}
          list="syllabusList"
        />
        <datalist id="syllabusList">
          {existingData.map((item, index) => (
            <option key={index} value={item.syllabusType} />
          ))}
        </datalist>

        <label>School Name:</label>
        <input
          type="text"
          name="schoolName"
          value={formData.schoolName}
          onChange={handleChange}
          list="schoolList"
        />
        <datalist id="schoolList">
          {existingData.map((item, index) => (
            <option key={index} value={item.schoolName} />
          ))}
        </datalist>

        <label>Class No:</label>
        <select name="classNo" value={formData.classNo} onChange={handleChange}>
          <option value="">Select Class</option>
          {[...Array(12).keys()].map((num) => (
            <option key={num + 1} value={num + 1}>
              {num + 1}
            </option>
          ))}
        </select>

        <label>Lesson No:</label>
        <select
          name="lessonNo"
          value={formData.lessonNo}
          onChange={handleChange}
        >
          <option value="">Select Lesson</option>
          {[...Array(50).keys()].map((num) => (
            <option key={num + 1} value={num + 1}>
              {num + 1}
            </option>
          ))}
        </select>

        <label>Lesson Name:</label>
        <input
          type="text"
          name="lessonName"
          value={formData.lessonName}
          onChange={handleChange}
          list="lessonList"
        />
        <datalist id="lessonList">
          {existingData.map((item, index) => (
            <option key={index} value={item.lessonName} />
          ))}
        </datalist>

        <label>Question No:</label>
        <select
          name="questionNo"
          value={formData.questionNo}
          onChange={handleChange}
        >
          <option value="">Select Question No</option>
          {[...Array(100).keys()].map((num) => (
            <option key={num + 1} value={num + 1}>
              {num + 1}
            </option>
          ))}
        </select>

        <label>Question:</label>
        <input
          type="text"
          name="question"
          value={formData.question}
          onChange={handleChange}
          list="questionList"
        />
        <datalist id="questionList">
          {existingData.map((item, index) => (
            <option key={index} value={item.question} />
          ))}
        </datalist>

        <label>Answer:</label>
        <textarea
          name="answer"
          value={formData.answer}
          onChange={handleChange}
        ></textarea>

        <button type="button" onClick={saveData}>
          Save Data
        </button>
      </form>
    </div>
  );
};

export default SyllabusForm;
