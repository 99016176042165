import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getAllSchools } from '../db/indexedDB.js';
import { schoolDetails } from './data/schools.js';

const SSCSchools = () => {
  const navigate = useNavigate();
  const [dbSchools, setDbSchools] = useState([]);

  useEffect(() => {
    fetchSchools();
  }, []);

  const fetchSchools = async () => {
    const data = await getAllSchools();
    setDbSchools(data);
  };

  // Combine static and dynamic schools
  const allSchools = [...schoolDetails, ...dbSchools];

  return (
    <Container className="py-4">
      {/* Back Button */}
      <Button
        onClick={() => navigate('/kids')}
        variant="secondary"
        className="mb-3"
      >
        🔙 Back to Kids Home
      </Button>

      {/* Add School Data Button */}
      <Button
        onClick={() => navigate('/kids/ssc/add-school')}
        variant="primary"
        className="mb-3 ms-2"
      >
        ➕ Add School Data
      </Button>

      <h2 className="text-center mb-4 kids-learning-text">
        🏫 Choose Your School! 📖✨
      </h2>

      <Row className="justify-content-center">
        {allSchools.length > 0 ? (
          allSchools.map(({ schoolName, color }, index) => (
            <Col xs={6} sm={4} md={3} className="mb-4 text-center" key={index}>
              <Card
                className="p-3 shadow-sm cursor-pointer"
                onClick={() => navigate(`/kids/ssc/${schoolName}`)}
                style={{ backgroundColor: color, cursor: 'pointer' }}
              >
                <Card.Body>
                  <h3 className="fw-bold">{schoolName}</h3>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <p className="text-center">No schools available. Add a new one!</p>
        )}
      </Row>
    </Container>
  );
};

export default SSCSchools;
