import React, { useState, useEffect } from 'react';
import { animated, useTransition } from 'react-spring';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  ProgressBar,
} from 'react-bootstrap';
import { motion } from 'framer-motion';
import { emojiMapping } from './emojiMapper.js';
import { scenes } from './LoopsConcept.js';
import { animationConfigs } from './animationConfigurations.js';

const ConceptExplanationAnimationKids = () => {
  const [currentScene, setCurrentScene] = useState(0);
  const [headerAnimationComplete, setHeaderAnimationComplete] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [headingWords, setHeadingWords] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [descriptionWords, setDescriptionWords] = useState([]);
  const [currentSubtitle, setCurrentSubtitle] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [speakingWordIndex, setSpeakingWordIndex] = useState(0);
  const [emoji, setEmoji] = useState(null);
  const [scenesAutoPlay, setScenesAutoPlay] = useState(null);

  const transitions = useTransition(currentScene, {
    from: { opacity: 0, transform: 'translateX(100%)' },
    enter: { opacity: 1, transform: 'translateX(0%)' },
    leave: { opacity: 0, transform: 'translateX(-100%)' },
    config: { tension: 150, friction: 20 },
  });

  const removeEmojis = (text) => {
    // Regex to match emojis with the 'u' flag
    return text.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF\uDC00-\uDFFF]|\u0023\u20E3|\u00A9|\u00AE|[\u2000-\u3300])/gu,
      ''
    );
  };

  // eslint-disable-next-line no-unused-vars
  const extractEmojisWithWords = (text) => {
    // Remove special characters and split text into words
    const sanitizedWords = text
      .replace(/[^a-zA-Z0-9\s]/g, '') // Remove special characters
      .split(/\s+/) // Split by whitespace
      .filter(Boolean); // Remove empty strings

    // Map words to emojis based on semantic roles
    return sanitizedWords.map((word) => {
      const lowerCaseWord = word.toLowerCase(); // Normalize word
      return { word, emoji: emojiMapping[lowerCaseWord] || null }; // Pair word with emoji or null
    });
  };

  // Function to find the emoji corresponding to a given word
  const findEmojiForWord = (word, emojisArray) => {
    const emojiEntry = emojisArray.find(
      (entry) => entry.word.toLowerCase() === word.toLowerCase()
    );
    return emojiEntry ? emojiEntry.emoji : null;
  };

  useEffect(() => {
    setHeaderAnimationComplete(false);

    // Get the current scene's title and description emojis
    const { titleEmojis, descriptionEmojis } = scenes[currentScene];

    // Remove emojis from the title and description for speech synthesis
    const sanitizedTitle = removeEmojis(scenes[currentScene].title);
    const sanitizedDescription = removeEmojis(scenes[currentScene].description);
    const titleWords = sanitizedTitle.split(' ');
    const descriptionWords = sanitizedDescription.split(' ');

    setHeadingWords(titleWords);
    setDescriptionWords(descriptionWords);

    const titleUtterance = new SpeechSynthesisUtterance(sanitizedTitle);
    const descriptionUtterance = new SpeechSynthesisUtterance(
      sanitizedDescription
    );

    // State to track if speech synthesis is active
    setIsSpeaking(true);

    // Function to handle boundary events
    const handleBoundaryEvent = (event, wordsArray, emojisArray) => {
      const charIndex = event.charIndex;
      // Determine the current word based on charIndex
      let cumulativeLength = 0;
      for (let i = 0; i < wordsArray.length; i++) {
        cumulativeLength += wordsArray[i].length + 1; // +1 accounts for the space
        if (charIndex < cumulativeLength) {
          setCurrentSubtitle(wordsArray[i]);
          setSpeakingWordIndex(i);

          // Retrieve the emoji for the current word
          const emoji = findEmojiForWord(wordsArray[i], emojisArray);
          setEmoji(emoji);
          break;
        }
      }
    };

    // Event listener for title utterance
    titleUtterance.onboundary = (event) => {
      handleBoundaryEvent(event, titleWords, titleEmojis);
    };

    // Event listener for when title finishes speaking
    titleUtterance.onend = () => {
      setCurrentSubtitle('');
      setEmoji(null);
      // Start speaking the description after the title
      window.speechSynthesis.speak(descriptionUtterance);
    };

    // Event listener for description utterance
    descriptionUtterance.onboundary = (event) => {
      handleBoundaryEvent(event, descriptionWords, descriptionEmojis);
    };

    // Event listener for when description finishes speaking
    descriptionUtterance.onend = () => {
      setIsSpeaking(false);
      setEmoji(null);

      if (scenesAutoPlay && currentScene < scenes.length - 1) {
        nextScene();
      }
    };

    // Start speaking the title
    window.speechSynthesis.speak(titleUtterance);

    // Cleanup on component unmount or scene change
    return () => {
      window.speechSynthesis.cancel();
      setCurrentSubtitle('');
      setSpeakingWordIndex(0); // Reset speakingWordIndex
      setIsSpeaking(false); // Reset isSpeaking
      setEmoji(null); // Clear any residual emoji
    };
  }, [currentScene, scenesAutoPlay]);

  useEffect(() => {
    if (scenesAutoPlay) {
      nextScene();
    }
  }, [scenesAutoPlay]);

  const toggleAutoPlay = () => {
    setScenesAutoPlay((prevState) => !prevState);
  };

  const nextScene = () => {
    if (currentScene < scenes.length - 1) setCurrentScene(currentScene + 1);
  };

  const previousScene = () => {
    if (currentScene > 0) setCurrentScene(currentScene - 1);
  };

  const startScene = () => {
    if (currentScene > 0) setCurrentScene(0);
  };

  const progress = ((currentScene + 1) / scenes.length) * 100;

  return (
    <Container
      fluid
      style={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        backgroundColor: '#f0f8ff',
        textAlign: 'center',
        padding: '20px',
      }}
    >
      {transitions((style, index) => (
        <animated.div style={{ ...style, position: 'absolute', width: '100%' }}>
          <Row className="h-100 d-flex align-items-center justify-content-center">
            <Col xs={10} md={8} lg={6}>
              <Card
                className="shadow-lg"
                style={{
                  height: '400px',
                  borderRadius: '15px',
                  overflow: 'hidden',
                  backgroundColor: scenes[index].styles.backgroundColor,
                }}
              >
                <Card.Header
                  style={{
                    background: scenes[index].styles.headerBackground,
                    color: scenes[index].styles.headerColor,
                    fontSize: '2.0rem',
                    textAlign: 'center',
                    padding: '15px',
                  }}
                >
                  <motion.span
                    {...animationConfigs.textWriting(`${scenes[index].title}`)}
                    onAnimationComplete={() => setHeaderAnimationComplete(true)} // Set state when header animation finishes
                  >
                    {scenes[index].title}
                  </motion.span>
                </Card.Header>
                <Card.Body
                  className="d-flex flex-column"
                  style={{
                    padding: '20px',
                    position: 'relative',
                  }}
                >
                  <Card.Text
                    style={{
                      display: 'flex', // Use flexbox
                      justifyContent: 'center', // Center horizontally
                      alignItems: 'center', // Center vertically
                      textAlign: 'left', // Align text to the center
                      overflowY: 'auto', // Allow vertical scrolling if content overflows
                      flexGrow: 1, // Make it grow to fill available space
                      marginTop: '10px', // Add a slight top margin
                      padding: '10px', // Add padding for better readability
                    }}
                  >
                    {headerAnimationComplete && // Wait until header animation completes
                      (scenes[index].animationType === 'textWriting' ? (
                        <motion.span
                          {...animationConfigs.textWriting(
                            scenes[index].content[0]
                          )}
                        >
                          {scenes[index].content[0]}
                        </motion.span>
                      ) : (
                        <motion.ul
                          initial="hidden"
                          animate="visible"
                          variants={{
                            hidden: { opacity: 0 },
                            visible: {
                              opacity: 1,
                              transition: {
                                staggerChildren: 0.2, // Delay between each child animation
                              },
                            },
                          }}
                        >
                          {scenes[index].content.map((item, itemIndex) => (
                            <motion.li
                              key={itemIndex}
                              {...animationConfigs[scenes[index].animationType](
                                itemIndex
                              )}
                            >
                              {/* Animate each line one after the other */}
                              <motion.span
                                initial={{ width: '0ch' }}
                                animate={{
                                  width: `${item.length + 1}ch`,
                                  borderRight: 'opx solid currentColor', // Cursor effect
                                }}
                                transition={{
                                  delay: itemIndex * 1, // Delay each line by index
                                  duration: 1.5, // Animation duration
                                  ease: 'easeInOut',
                                }}
                                style={{
                                  display: 'inline-block',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  fontFamily: 'monospace',
                                }}
                              >
                                {item}
                              </motion.span>
                            </motion.li>
                          ))}
                        </motion.ul>
                      ))}
                  </Card.Text>
                </Card.Body>
                {isSpeaking ? (
                  <div
                    style={{
                      textAlign: 'center',
                      padding: '10px',
                      fontSize: '1.2rem',
                      color: '#ffffff', // White font color
                      minHeight: '50px', // Consistent height for the subtitle area
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '80%', // Fixed width for subtitle container
                      margin: '0 auto', // Center the container
                      borderRadius: '10px',
                    }}
                  >
                    {currentSubtitle && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.2 }}
                        style={{
                          display: 'flex',
                          gap: '10px', // Spacing between words
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexWrap: 'wrap', // Handle longer subtitles gracefully
                        }}
                      >
                        <span
                          key={index}
                          style={{
                            padding: '5px 10px',
                            borderRadius: '5px',
                            backgroundColor:
                              index === speakingWordIndex ? 'blue' : 'blue', // Blue for the speaking word
                            color:
                              index === speakingWordIndex
                                ? '#ffffff'
                                : '#ffffff', // Ensure contrast
                            fontWeight:
                              index === speakingWordIndex ? 'bold' : 'normal',
                            fontFamily: 'monospace',
                            fontSize: 36,
                            transition: 'background-color 0.2s ease-in-out',
                          }}
                        >
                          {emoji
                            ? `${currentSubtitle}(${emoji})`
                            : currentSubtitle}
                        </span>
                      </motion.div>
                    )}
                  </div>
                ) : null}
              </Card>

              <div className="mt-4">
                <ProgressBar
                  now={progress}
                  label={`${currentScene + 1}/${scenes.length}`}
                  className="mb-4"
                />
                <Button
                  variant="primary"
                  onClick={toggleAutoPlay}
                  className="ms-2"
                >
                  {scenesAutoPlay ? '⏸️ Auto Play' : '▶️ Auto Play'}
                </Button>

                {!scenesAutoPlay && currentScene > 0 && (
                  <Button
                    variant="secondary"
                    onClick={previousScene}
                    className="ms-2"
                  >
                    ⬅️ Back
                  </Button>
                )}

                {!scenesAutoPlay && currentScene < scenes.length - 1 && (
                  <Button
                    variant="primary"
                    onClick={nextScene}
                    className="ms-2"
                  >
                    ➡️ Next
                  </Button>
                )}
                {!isSpeaking && currentScene === scenes.length - 1 && (
                  <Button
                    variant="primary"
                    onClick={startScene}
                    className="ms-2"
                  >
                    🔄 Start Again
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </animated.div>
      ))}
    </Container>
  );
};

export default ConceptExplanationAnimationKids;
