export const SchoolActivityTypes = {
  INDIVIDUAL_SPEAKING: 'Individual Speaking',
  DEFINE_THE_FOLLOWING_TERM: 'Define The Following Term',
  QA_SPEAKING: 'Q&A Speaking',
  MATHS_NUMBER_SYSTEM_PERIODS: 'NUMBER SYSTEM PERIODS',
  MATHS_PRACTICE_EXERCISE_SOLUTION: 'MATHS PRACTICE EXERCISE SOLUTION',
};

export const SchoolActivityContentSplitter = {
  QA_ACTIVITY_SPLITTER: ', Answer',
};
