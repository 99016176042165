import { class5Subjects } from '../subjects/class5Subjects.js';
import { class6Subjects } from '../subjects/class6Subjects.js';

export const telanganaGovernmentDistributon = [
  {
    className: 'Class 5',
    color: '#FFD700',
    subjects: class5Subjects,
  },
  {
    className: 'Class 6',
    color: '#FFD700',
    subjects: class6Subjects,
  },
];
