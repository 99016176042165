export const questionsAndAnswersLessonFour = [
  {
    question:
      'What did Laurence and Tom do for a living? Were they successful?',
    answer:
      'Laurence was a painter by profession and Tom was a farmer. Yes, both of them were fairly successful.',
  },
  {
    question: 'Why was Tom so proud of his bull?',
    answer:
      "Tom's bull was a healthy young animal. His bull was the best among the cattle in the area. That is why Tom was very proud of his bull, Clover Fairy.",
  },
  {
    question: "Why did Tom feel a comforting 'sense of assured superiority'?",
    answer:
      'Tom felt a comforting sense of assured superiority when he compared himself with his half-brother, Laurence. Laurence was just an artist while Tom was a farmer. It was not a big farm but the Helsery farm had a good reputation for the stock raised on it.',
  },
  {
    question: 'How did Laurence react after he saw Clover Fairy?',
    answer:
      "When Laurence saw Clover Fairy, he made one or two comments on the animal's appearance, enquired about his age and some other details, but showed no appreciation at all.",
  },
  {
    question: 'What did Tom do when he felt insulted by Laurence?',
    answer:
      'When Tom felt insulted, he decided to show Laurence his right place by making him realise the difference between a painted and a real bull.',
  },
  {
    question: 'How did Clover Fairy attack Laurence and how was he saved?',
    answer:
      "Clover Fairy tossed Laurence in the air and tried to poke into Laurence's ribs with his horns, but Tom saved Laurence.",
  },
];
