import React, { useState } from 'react';
import { Card, Form, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const textbooks = [
  {
    id: 1,
    title: 'Telugu',
    subject: 'Telugu',
    language: 'Telugu',
    filename: '/pdfs/ssc/class6/6th-EM-Telugu.pdf',
  },
  {
    id: 2,
    title: 'English',
    subject: 'English',
    language: 'English',
    filename: '/pdfs/ssc/class6/6th-EM-English.pdf',
  },
  {
    id: 3,
    title: 'Mathematics',
    subject: 'Math',
    language: 'English',
    filename: '/pdfs/ssc/class6/6th-EM-Maths.pdf',
  },
  {
    id: 4,
    title: 'General Science',
    subject: 'General Science',
    language: 'English',
    filename: '/pdfs/ssc/class6/6th-EM-General-Science.pdf',
  },
  {
    id: 5,
    title: 'Social',
    subject: 'Social',
    language: 'English',
    filename: '/pdfs/ssc/class6/6th-EM-Social.pdf',
  },
];

const Class6TextbookList = () => {
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const filteredBooks = textbooks.filter((book) =>
    book.title.toLowerCase().includes(search.toLowerCase())
  );

  const handleBookClick = (filename, language) => {
    navigate(
      `/kids/ssc/textBooks/${encodeURIComponent(filename)}/${encodeURIComponent(language)}`
    );
  };

  return (
    <Container className="mt-4">
      <Row className="mb-3">
        <Col md={{ span: 6, offset: 3 }}>
          <Form.Control
            type="text"
            placeholder="Search textbooks..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        {filteredBooks.map((book) => (
          <Col key={book.id} md={4} className="mb-4">
            <Card
              onClick={() => handleBookClick(book.filename, book.language)}
              style={{ cursor: 'pointer' }}
            >
              <Card.Body>
                <Card.Title>{book.title}</Card.Title>
                <Card.Text>Subject: {book.subject}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Class6TextbookList;
