import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getAllSchools } from '../db/indexedDB.js';
import { schoolDetails } from '../ssc/data/schools.js';

const LessonComponent = () => {
  const { schoolName, className, subjectName, lessonName } = useParams(); // Get selected school, class, subject & lesson from URL
  const navigate = useNavigate();
  const [dbActivities, setDbActivities] = useState([]);

  useEffect(() => {
    fetchLessonActivities();
  }, [schoolName, className, subjectName, lessonName]);

  const fetchLessonActivities = async () => {
    const data = await getAllSchools();
    const schoolFromDB = data.find((s) => s.schoolName === schoolName);
    const classFromDB = schoolFromDB?.classes.find(
      (c) => c.className === className
    );
    const subjectFromDB = classFromDB?.subjects.find(
      (s) => s.subjectName === subjectName
    );
    const lessonFromDB = subjectFromDB?.lessons.find(
      (l) => l.lessonName === lessonName
    );
    setDbActivities(lessonFromDB ? lessonFromDB.activities : []);
  };

  // Find static lesson data
  const staticSchoolData = schoolDetails.find(
    (s) => s.schoolName === schoolName
  );
  const staticClassData = staticSchoolData?.classes.find(
    (c) => c.className === className
  );
  const staticSubjectData = staticClassData?.subjects.find(
    (s) => s.subjectName === subjectName
  );
  const staticLessonData = staticSubjectData?.lessons.find(
    (l) => l.lessonName === lessonName
  );
  const staticActivities = staticLessonData ? staticLessonData.activities : [];

  // Combine static and dynamic activities
  const allActivities = [...staticActivities, ...dbActivities];

  return (
    <Container className="py-4">
      {/* Back Button */}
      <Button
        onClick={() =>
          navigate(`/kids/ssc/${schoolName}/${className}/${subjectName}`)
        }
        variant="secondary"
        className="mb-3"
      >
        🔙 Back to {subjectName} Lessons
      </Button>

      {/* Add Activity Data Button */}
      <Button
        onClick={() =>
          navigate(
            `/kids/ssc/${schoolName}/${className}/${subjectName}/${lessonName}/add-activity`
          )
        }
        variant="primary"
        className="mb-3 ms-2"
      >
        ➕ Add Activity
      </Button>

      <h2 className="text-center mb-4">{lessonName} - Choose an Activity</h2>

      <Row className="justify-content-center">
        {allActivities.length > 0 ? (
          allActivities.map(
            ({ activityName, list, color }, activityIndex) =>
              list.length > 0 && (
                <Col xs={12} key={activityIndex} className="mb-4">
                  {/* Section Title */}
                  <h4 className="text-center fw-bold mb-3">{activityName}</h4>
                  <Row className="justify-content-center">
                    {list.map(({ question, number }, questionIndex) => (
                      <Col
                        xs={6}
                        sm={4}
                        md={3}
                        className="mb-4 text-center"
                        key={`${activityIndex}-${questionIndex}`}
                      >
                        <Card
                          className="p-3 shadow-sm cursor-pointer"
                          onClick={() =>
                            navigate(
                              `/kids/ssc/${schoolName}/${className}/${subjectName}/${lessonName}/${activityName}/${questionIndex}`
                            )
                          }
                          style={{
                            backgroundColor: color || '#f8f9fa', // Default color if none provided
                            cursor: 'pointer',
                          }}
                        >
                          <Card.Body>
                            <h5 className="fw-bold">
                              Question {questionIndex + 1}
                            </h5>
                            <p className="text-muted">
                              {question} {number}
                            </p>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
              )
          )
        ) : (
          <p className="text-center">No activities available. Add a new one!</p>
        )}
      </Row>
    </Container>
  );
};

export default LessonComponent;
