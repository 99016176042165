import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  Button,
  ProgressBar,
} from 'react-bootstrap';
import './CBSESyllabus.css';
import { preSchoolSyllabusList } from './preScholSyllabusData.js';

const CBSESyllabus = () => {
  // State
  const [showModal, setShowModal] = useState(false);
  const [activityName, setActivityName] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentList, setCurrentList] = useState([]);
  const [language, setLanguage] = useState('');
  const [isMultiLingualTEEN, setIsMultiLingualTEEN] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [autoPlay, setAutoPlay] = useState(false);

  // Open Modal
  const handleOpenModal = (name, list, lang, multiLingualTEEN = false) => {
    setActivityName(name);
    setCurrentList(list);
    setCurrentIndex(0); // Reset to first item
    setShowModal(true);
    setLanguage(lang);
    setIsMultiLingualTEEN(multiLingualTEEN);
    setIsSpeaking(false);
    setAutoPlay(false); // Reset Auto-Play when opening
  };

  // Close Modal
  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentIndex(0); // Reset index when closing modal
    setIsSpeaking(false);
    setAutoPlay(false); // Stop Auto-Play when modal closes
  };

  // Speak Character
  const speakCharacter = (char, callback) => {
    if (isSpeaking) return; // Prevent multiple clicks while speaking
    setIsSpeaking(true); // Start animation & disable clicking

    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance();
    utterance.rate = 0.6;

    if (isMultiLingualTEEN) {
      utterance.text = char.english;
      utterance.lang = 'en-US';
      synth.speak(utterance);

      utterance.onend = () => {
        const teluguUtterance = new SpeechSynthesisUtterance(char.telugu);
        teluguUtterance.lang = 'te-IN';
        teluguUtterance.rate = 0.6;
        synth.speak(teluguUtterance);

        teluguUtterance.onend = () => {
          setIsSpeaking(false); // Re-enable clicking after speech ends
          if (callback) callback();
        };
      };
    } else {
      utterance.text = char;
      utterance.lang = language === 'Telugu' ? 'te-IN' : 'en-US';
      utterance.rate = 0.6;

      utterance.onend = () => {
        setIsSpeaking(false); // Re-enable clicking after speech ends
        if (callback) callback();
      };

      synth.speak(utterance);
    }
  };

  // Handle Next Character
  const handleNextCharacter = () => {
    if (isSpeaking) return; // Prevents clicking while speaking

    if (currentIndex < currentList.length) {
      speakCharacter(
        isMultiLingualTEEN
          ? currentList[currentIndex]
          : currentList[currentIndex],
        () => {
          const nextIndex = currentIndex + 1;

          if (nextIndex < currentList.length) {
            setCurrentIndex(nextIndex); // Move to the next character
          } else {
            handleCloseModal(); // Close modal only after the last character is spoken
          }
        }
      );
    }
  };

  // Auto-play when enabled
  useEffect(() => {
    if (autoPlay && showModal && currentIndex < currentList.length) {
      setTimeout(() => handleNextCharacter(), 2000); // Auto-plays next character after delay
    }
  }, [currentIndex, autoPlay, showModal]);

  return (
    <Container className="py-4 d-flex flex-column">
      {/* Fixed Heading */}
      <h2 className="text-center mb-4 kids-learning-text">
        🎨📚 Learn Anywhere, Grow Everywhere! 🧸✨
      </h2>

      {/* Scrollable Section for Cards */}
      <div className="scrollable-container">
        <Row className="justify-content-center">
          {preSchoolSyllabusList.map(
            ({ name, list, lang, color, multiLingualTEEN }) => (
              <Col xs={6} sm={4} md={3} className="mb-4 text-center" key={name}>
                <Card
                  className="p-3 shadow-sm cursor-pointer"
                  onClick={() =>
                    handleOpenModal(name, list, lang, multiLingualTEEN)
                  }
                  style={{ cursor: 'pointer', backgroundColor: color }}
                >
                  <Card.Body>
                    <h3 className="fw-bold">{name}</h3>
                  </Card.Body>
                </Card>
              </Col>
            )
          )}
        </Row>
      </div>

      {/* Modal with Background Overlay */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        size="lg"
        dialogClassName="custom-modal"
        backdropClassName="custom-backdrop"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            🎉 Happy Mom, Happy Dad! 👩‍👧❤️👨‍👧🎈 Let’s Make Learning Fun! 📚✨
            <br />
            <strong>{activityName}</strong>
          </Modal.Title>
        </Modal.Header>

        {/* Progress Bar */}
        <ProgressBar
          now={(currentIndex / currentList.length) * 100}
          label={`${Math.round((currentIndex / currentList.length) * 100)}%`}
          variant="success"
          className="w-100"
        />

        <Modal.Body
          className="d-flex flex-column justify-content-center align-items-center modal-body-dark"
          style={{ height: '60vh', textAlign: 'center' }}
        >
          <h1
            className={`display-1 fw-bold text-highlight ${
              isSpeaking ? 'animate-text' : ''
            }`}
            style={{
              cursor: isSpeaking ? 'not-allowed' : 'pointer', // Disable clicking while speaking
              fontSize: '12rem',
              color: '#ffffff',
            }}
            onClick={!autoPlay ? handleNextCharacter : undefined} // Only allow clicking when Auto-Play is off
          >
            {isMultiLingualTEEN
              ? currentList[currentIndex].emoji ||
                currentList[currentIndex].english
              : currentList[currentIndex]}
          </h1>
          <p style={{ color: '#ffffff' }}>
            {autoPlay ? 'Auto-playing...' : 'Click to hear & move to the next.'}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={autoPlay ? 'danger' : 'success'}
            onClick={() => setAutoPlay(!autoPlay)}
          >
            {autoPlay ? 'Stop Auto-Play' : 'Start Auto-Play'}
          </Button>
          <Button
            variant="secondary"
            onClick={handleCloseModal}
            disabled={isSpeaking}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default CBSESyllabus;
