export const questionsAndAnswersLessonTwelve = [
  {
    question: 'Who is Peter Pan? Why does he come to the Darling’s house?',
    answer:
      'Peter Pan is a mischievous and fun-loving young boy who can fly. He comes to the Darling’s house to retrieve his shadow, which had come off him.',
  },
  {
    question:
      "Where is Peter Pan's shadow found? How does he try to attach it?",
    answer:
      "Peter Pan's shadow is found in the chest of drawers. He tries to attach it back to himself using soap.",
  },
  {
    question:
      'How did Wendy help Peter? What happened after Wendy helped Peter?',
    answer:
      'Wendy helped Peter by sewing his shadow onto his foot. After Wendy helped him, Peter began bounding around the room, boasting about his cleverness.',
  },
  {
    question:
      'How, according to Peter, are fairies born? Why does a fairy die?',
    answer:
      "According to Peter, when a baby laughs for the first time, its laugh breaks into a thousand pieces, and each piece becomes a fairy. A fairy dies whenever a child says, 'I don’t believe in fairies.'",
  },
  {
    question: 'Why is Tinker Bell called so?',
    answer:
      'Tinker Bell is called so because she mends fairy pots and kettles, just like a tinker.',
  },
];
