export const questionsAndAnswersLessonSix = [
  {
    question: 'What did Tom wish to be on a Monday morning?',
    answer:
      'Tom wished to be sick on a Monday morning so that he could stay home and avoid school.',
  },
  {
    question: 'What ailments did Tom consider having?',
    answer:
      'Tom considered having colicky symptoms or a sore toe with lots of pain or his upper front tooth which was loose as suitable ailments to skip school.',
  },
  {
    question: "How did Tom try to get Sid's attention?",
    answer:
      "Tom tried to get Sid's attention by groaning with considerable spirit and going a little louder every time.",
  },
  {
    question: "How did Aunt Polly react to Tom's condition?",
    answer:
      "Hearing Tom's condition, Aunt Polly ran upstairs with Sid at her heels. Her face grew white, her lips trembled, and she gasped on seeing Tom.",
  },
  {
    question:
      "What did Aunt Polly want to do to ease Tom's toothache? Why did Tom stop her from doing so?",
    answer:
      "Aunt Polly was about to get a silk thread and a chunk of fire out of the kitchen to pull out Tom's loose tooth. Tom stopped her from doing so as it would be very painful.",
  },
];
