import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import SingleSpeakingActivity from './interactiveActivities/SingleSpeakingActivity.js';
import QASpeakingActivity from './interactiveActivities/QASpeakingActivity.js';
import DefineTermSpeakingActivity from './interactiveActivities/DefineTermSpeakingActivity.js';
import MathsPracticeExerciseSolution from './interactiveActivities/MathsPracticeExerciseSolution.js';
import { getAllSchools } from '../db/indexedDB.js';
import { schoolDetails } from '../ssc/data/schools.js';
import { SchoolActivityTypes } from '../enums/constants.js';
import NumberSystemPeriods from '../ssc/data/schoolData/stJosephsHighSchool/constants/class6/Maths/Lesson1/NumberSystemPeriods.js';

const SchoolActivitiesManager = () => {
  const {
    schoolName,
    className,
    subjectName,
    lessonName,
    activityName,
    questionIndex,
  } = useParams();
  const navigate = useNavigate();
  const [activityData, setActivityData] = useState(null);

  useEffect(() => {
    fetchActivityData();
  }, [schoolName, className, subjectName, lessonName, activityName]);

  const fetchActivityData = async () => {
    const data = await getAllSchools();
    const schoolFromDB = data.find((s) => s.schoolName === schoolName);
    const classFromDB = schoolFromDB?.classes.find(
      (c) => c.className === className
    );
    const subjectFromDB = classFromDB?.subjects.find(
      (s) => s.subjectName === subjectName
    );
    const lessonFromDB = subjectFromDB?.lessons.find(
      (l) => l.lessonName === lessonName
    );
    const activityFromDB = lessonFromDB?.activities.find(
      (a) => a.activityName === activityName
    );

    // Find static activity data
    const staticSchoolData = schoolDetails.find(
      (s) => s.schoolName === schoolName
    );
    const staticClassData = staticSchoolData?.classes.find(
      (c) => c.className === className
    );
    const staticSubjectData = staticClassData?.subjects.find(
      (s) => s.subjectName === subjectName
    );
    const staticLessonData = staticSubjectData?.lessons.find(
      (l) => l.lessonName === lessonName
    );
    const staticActivityData = staticLessonData?.activities.find(
      (a) => a.activityName === activityName
    );

    let individualActivityData;

    if (
      staticActivityData.activityType === SchoolActivityTypes.QA_SPEAKING ||
      staticActivityData.activityType ===
        SchoolActivityTypes.DEFINE_THE_FOLLOWING_TERM ||
      staticActivityData.activityType ===
        SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION
    ) {
      individualActivityData = staticActivityData.list[questionIndex];
      individualActivityData.activityType = staticActivityData.activityType;
      individualActivityData.language = staticActivityData.lang;
    }

    if (
      staticActivityData.activityType ===
      SchoolActivityTypes.MATHS_NUMBER_SYSTEM_PERIODS
    ) {
      // Maths Lesson 1
      individualActivityData = staticActivityData.list[questionIndex];
      individualActivityData.activityType = staticActivityData.activityType;
      individualActivityData.language = staticActivityData.lang;
    }

    // Use DB data if available, else fallback to static data
    setActivityData(activityFromDB || individualActivityData);
  };

  const handleClose = () => {
    navigate(
      `/kids/ssc/${schoolName}/${className}/${subjectName}/${lessonName}`
    );
  };

  if (!activityData) {
    return (
      <Modal show centered size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Activity Not Found</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>Sorry, the selected activity does not exist.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Back to Lessons
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const renderActivityComponent = () => {
    return (
      <Modal show centered size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{activityData.activityName || 'Activity'}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">
          {(() => {
            switch (activityData.activityType) {
              case SchoolActivityTypes.INDIVIDUAL_SPEAKING:
                return <SingleSpeakingActivity />;

              case SchoolActivityTypes.QA_SPEAKING:
                return <QASpeakingActivity activityData={activityData} />;

              case SchoolActivityTypes.DEFINE_THE_FOLLOWING_TERM:
                return (
                  <DefineTermSpeakingActivity activityData={activityData} />
                );

              case SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION:
                return (
                  <MathsPracticeExerciseSolution activityData={activityData} />
                );

              case SchoolActivityTypes.MATHS_NUMBER_SYSTEM_PERIODS:
                return (
                  <NumberSystemPeriods inputNumber={activityData.number} />
                );

              default:
                return (
                  <div>
                    <p>
                      The activity type{' '}
                      <strong>{activityData.activityType}</strong> is not
                      supported yet.
                    </p>
                  </div>
                );
            }
          })()}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return <>{renderActivityComponent()}</>;
};

export default SchoolActivitiesManager;
