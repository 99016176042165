export const questionsAndAnswersNDLessonFour = [
  {
    question: 'Who sent a telegram to Mrs. March and why?',
    answer:
      'Mr. S. Hale, from Blank Hospital in Washington, sent a telegram to Mrs. March stating that her husband was very ill and asking her to come to Washington to nurse him.',
  },
  {
    question: "Describe Jo's sacrifice. What were Amy’s and Beth’s reactions?",
    answer:
      "Jo sacrificed her long, beautiful hair and sold it to a wig maker to get money for Marmee's travel expenses to nurse her father. Both Amy and Beth were in awe of her daring act of sacrificing her hair.",
  },
  {
    question: 'How did Beth catch scarlet fever?',
    answer:
      "Beth sneaked off to visit the poor Hummels' children in the cold air. While she was there, Mrs. Hummel's baby died in Beth's lap due to scarlet fever. Since Beth was in close contact with the sick children, she also caught scarlet fever.",
  },
  {
    question: "Why didn't Amy want to go to Aunt March’s place?",
    answer:
      "Amy, who was susceptible to scarlet fever, was sent to Aunt March's house for safety. She did not want to go, saying it was a dull place, and made Laurie promise that he would visit her every day.",
  },
  {
    question: 'How did the girls learn that Beth was very popular?',
    answer:
      'The milkman, the baker, the grocer, and the butcher regularly inquired about Beth’s health. The neighbors sent all sorts of comforts and good wishes. Mr. Laurence locked up the piano, and Laurie visited her every morning and evening. With so many people showing concern for Beth, the girls realized that Beth was very popular.',
  },
  {
    question: 'Which signs told Hannah that Beth’s fever was gone?',
    answer:
      "A change had come over Beth's face, and the look of pain had disappeared. She could sleep and breathe easily and quietly, as if she had just fallen asleep. These signs told Hannah that Beth’s fever had gone.",
  },
];
