import { numbers } from '../constants/numbers.js';

export const mathsLessons = [
  {
    lessonName: 'Number Counting',
    color: '#90EE90',
    activities: [
      {
        activityName: '🔢 Numbers',
        list: numbers,
        lang: 'English',
        color: '#ffebcc',
      },
    ],
  },
  {
    lessonName: 'Shapes',
    color: '#FFA07A',
    activities: [
      {
        activityName: '🔺 Shapes',
        list: ['Circle', 'Square', 'Triangle', 'Rectangle', 'Star'],
        lang: 'English',
        color: '#ffcc99',
      },
    ],
  },
];
