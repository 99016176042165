import * as pdfjs from 'pdfjs-dist/build/pdf.mjs';

/**
 * Make sure you have pdf.worker.min.mjs (or pdf.worker.js) in your public folder.
 * This is required for PDF.js to function in a web environment.
 */
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

// Function to draw a rectangle based on textData properties
// eslint-disable-next-line no-unused-vars
function drawTextDataRect(ctx, textData) {
  ctx.beginPath();
  ctx.rect(textData.x, textData.y, textData.width, textData.height);
  ctx.strokeStyle = 'red'; // You can change the color as needed
  ctx.lineWidth = 2;
  ctx.stroke();
}

/**
 * Render the specified page of the PDF document onto our single canvas.
 * @param {PDFDocumentProxy} doc - The loaded PDF.js document
 * @param {number} pageNum - The page number to render
 */
export const renderPage = async (
  doc,
  pageNum,
  canvasRef,
  setPageImageData,
  viewport,
  setViewport,
  textItemsRef,
  hlTextItemsRef
) => {
  // Helper function to check for Telugu characters in a string
  const containsTelugu = (str) => /[\u0C00-\u0C7F]/.test(str);

  console.log('containsTelugu:', containsTelugu('తెలంగాణ')); // should log: true
  console.log('containsTelugu:', containsTelugu('SCERT TELANGANA')); // should log: false

  if (!doc) {
    console.warn('renderPage called but no PDF document is loaded.');
    return;
  }
  if (pageNum < 1 || pageNum > doc.numPages) {
    console.warn(
      `Page ${pageNum} is out of range. Document has ${doc.numPages} pages.`
    );
    return;
  }

  try {
    console.log(`Rendering page ${pageNum}...`);
    const page = await doc.getPage(pageNum);

    console.log(`📄 Page Rotation: ${page.rotate}°`);
    // Detect page rotation and apply correct orientation
    const rotation = page.rotate || 0;
    console.log(`📄 Page Rotation After Setting: ${rotation}°`);

    // Scale up for clarity – you can adjust 'scale'
    const scale = 2.0;
    const newViewport = page.getViewport({ scale });
    setViewport(newViewport);

    // Prepare the canvas
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Match canvas size to the PDF page dimensions
    canvas.width = newViewport.width;
    canvas.height = newViewport.height;
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Apply correct transformation for rotation
    context.save();
    if (viewport) {
      if (rotation === 90) {
        // Rotate and translate for landscape pages only when rotation is 90 degrees
        context.translate(viewport.width / 2, viewport.height / 2);
        context.rotate((90 * Math.PI) / 180);
        context.translate(-viewport.height / 2, -viewport.width / 2);
      }
    } else {
      console.error('Error: viewport is null or undefined');
    }

    // Render PDF content on the canvas
    await page.render({ canvasContext: context, viewport: newViewport })
      .promise;
    console.log(`Page ${pageNum} rendered successfully.`);

    // Save the rendered image so we can restore it (to erase highlights)
    setPageImageData(context.getImageData(0, 0, canvas.width, canvas.height));

    // Extract text items from the PDF page
    const textContent = await page.getTextContent();
    const uniqueTextItems = [];
    const seenPositions = new Set();

    textContent.items.forEach((item) => {
      const posKey = `${item.transform[4]}-${item.transform[5]}`; // x-y position key
      uniqueTextItems.push(item.str);
      // console.log(`Each Item Text: ${item.str}`);
      console.log(`Each Item: ${JSON.stringify(item, null, 4)}`);
      seenPositions.add(posKey);
    });

    const extractedText = uniqueTextItems.join(' ');
    console.log(`Extracted Text: ${extractedText}`);

    // Debug color if you want to see text drawn over the PDF
    context.fillStyle = 'red';

    // Clear out old references
    textItemsRef.current = [];
    hlTextItemsRef.current = [];

    // For each piece of text, compute bounding box info
    for (let i = 0; i < textContent.items.length; i++) {
      const textItem = textContent.items[i];
      console.log(`\n🔹 Processing TextItem [${i}] - "${textItem.str}"`);
      console.log(`textItem Data: ${JSON.stringify(textItem, null, 4)}`);

      // Combine PDF text transform with viewport transform
      const transform = pdfjs.Util.transform(
        newViewport.transform,
        textItem.transform
      );
      const x = transform[4]; // X position
      const y = transform[5]; // Y position (baseline for horizontal text,
      // or starting top for vertical text)
      const fontSize = Math.hypot(transform[0], transform[1]); // Computed font size

      console.log(`📌 Transform Matrix:`, transform);
      console.log(`📍 Position: X=${x}, Y=${y}, Font Size=${fontSize}`);

      // Default font values and metrics
      let fontFamily = 'Arial';
      let fontWeight = 'normal';
      let fontStyle = 'normal';
      let ascent = 1.0; // fallback value
      let descent = 0.0; // fallback value
      let vertical = false; // default orientation is horizontal

      // Use the embedded font metrics if available (e.g. for fontName "g_d0_f2")
      if (textItem.fontName && textContent.styles) {
        console.log(
          `Available styles: ${JSON.stringify(textContent.styles, null, 4)}`
        );
        const fontData = textContent.styles[textItem.fontName];
        console.log(
          `fontData for "${textItem.fontName}": ${JSON.stringify(fontData, null, 4)}`
        );
        if (fontData) {
          fontFamily = fontData.fontFamily || 'Arial';
          // For now we assume bold/italic flags if available
          fontWeight = fontData.bold ? 'bold' : 'normal';
          fontStyle = fontData.italic ? 'italic' : 'normal';
          ascent = fontData.ascent || 1.0;
          descent = fontData.descent || 0.0;
          vertical = fontData.vertical || false;
          console.log(`🎨 Using Embedded Font: ${fontFamily}`);
          console.log(
            `Ascent: ${ascent}, Descent: ${descent}, Vertical: ${vertical}`
          );
        }
      }

      // ---- Modification for Telugu text processing ----
      // If the extracted text contains Telugu characters, force the Telugu font.
      if (containsTelugu(textItem.str)) {
        fontFamily = 'Noto Sans Telugu Regular';
        console.log(`📝 Detected Telugu text. Forcing font to: ${fontFamily}`);
      }
      // ----------------------------------------------------

      // Set the canvas context font
      context.font = `${fontStyle} ${fontWeight} ${fontSize}px ${fontFamily}`;
      console.log(`🎨 Font Applied: ${context.font}`);

      // Calculate total text width from PDF.js (this is considered the correct width)
      const totalWidth = textItem.width * newViewport.scale;
      const textLength = textItem.str.length;
      console.log(
        `📏 PDF.js Text Width: ${textItem.width} | Scaled Total Width: ${totalWidth}`
      );
      console.log(`🔢 Number of Characters: ${textLength}`);

      // Measure each character using context.measureText()
      let measuredCharWidths = [];
      for (let charIndex = 0; charIndex < textLength; charIndex++) {
        const char = textItem.str[charIndex];
        const measuredWidth = context.measureText(char).width;
        measuredCharWidths.push(measuredWidth);
        console.log(`Measured width for "${char}": ${measuredWidth}`);
      }

      // Compute a scale factor so that the sum of measured widths matches PDF.js width
      const sumMeasuredWidths = measuredCharWidths.reduce((a, b) => a + b, 0);
      console.log(`Sum of measured widths: ${sumMeasuredWidths}`);
      const scaleFactor = totalWidth / sumMeasuredWidths;
      console.log(`Scale Factor to match total width: ${scaleFactor}`);

      // Calculate the final character widths
      let characterWidths = measuredCharWidths.map((w) => w * scaleFactor);
      console.log(`Final character widths:`, characterWidths);

      let calculatedTotalWidth = 0; // For debugging total calculated width
      let charPositions = [];

      if (!vertical) {
        // HORIZONTAL LAYOUT: Place characters side by side.
        let charX = x;
        // For horizontal text, we adjust Y based on baseline and font metrics.
        // Here we assume y is the baseline. Optionally, you could adjust:
        // const adjustedY = y - (ascent * fontSize);
        const adjustedY = y;
        const highlightHeight = (ascent - descent) * fontSize;
        console.log(`Using font metrics: Ascent=${ascent}, Descent=${descent}`);
        console.log(
          `Adjusted Y for drawing: ${adjustedY}, Highlight Height: ${highlightHeight}`
        );

        for (let charIndex = 0; charIndex < textLength; charIndex++) {
          const char = textItem.str[charIndex];
          const charWidth = characterWidths[charIndex];

          // Draw each character at its precise location
          // context.fillText(char, charX, adjustedY);

          // Draw Sqaures around text area
          // drawTextDataRect(context, {
          //   index: i,
          //   text: textItem.str,
          //   x,
          //   y: adjustedY - highlightHeight,
          //   width: totalWidth,
          //   height: highlightHeight,
          // });

          console.log(
            `🔠 Character: "${char}" | X: ${charX} | Y: ${adjustedY} | Width: ${charWidth}`
          );

          calculatedTotalWidth += charWidth;
          charPositions.push({
            char,
            x: charX,
            y: adjustedY,
            width: charWidth,
          });
          charX += charWidth;
        }
        console.log(
          `✅ Total Calculated Width: ${calculatedTotalWidth} | Expected: ${totalWidth}`
        );
        console.log(
          `📏 Width Difference: ${Math.abs(calculatedTotalWidth - totalWidth)}`
        );
      } else {
        // VERTICAL LAYOUT: Place characters one below the other.
        // For vertical text, we ignore horizontal widths and use a uniform height.
        let charY = y; // Starting top position for vertical text
        const adjustedX = x; // X remains fixed
        // Compute a uniform character height using font metrics.
        const charHeight = (ascent - descent) * fontSize;
        console.log(
          `(Vertical) Using font metrics: Ascent=${ascent}, Descent=${descent}`
        );
        console.log(
          `(Vertical) Starting Y: ${charY}, Uniform Character Height: ${charHeight}`
        );

        for (let charIndex = 0; charIndex < textLength; charIndex++) {
          const char = textItem.str[charIndex];
          // Draw each character vertically
          // context.fillText(char, adjustedX, charY);
          console.log(
            `(Vertical) Character: "${char}" | X: ${adjustedX} | Y: ${charY} | Height: ${charHeight}`
          );

          calculatedTotalWidth += charHeight;
          charPositions.push({
            char,
            x: adjustedX,
            y: charY,
            width: charHeight, // In vertical mode, we treat height as the spacing
          });
          charY += charHeight;
        }
        console.log(
          `(Vertical) Total Calculated Height: ${calculatedTotalWidth} | Expected: ${totalWidth}`
        );
        console.log(
          `(Vertical) Height Difference: ${Math.abs(calculatedTotalWidth - totalWidth)}`
        );
      }

      // Build a textData object that stores the computed bounding box and per-character positions.
      // In horizontal mode, height is the highlight height computed via (ascent - descent) * fontSize.
      // In vertical mode, we treat totalWidth as the total height.
      const textData = {
        index: i,
        text: textItem.str,
        x,
        y,
        width: totalWidth,
        height: vertical ? calculatedTotalWidth : (ascent - descent) * fontSize,
        charPositions,
        vertical, // store orientation for later use in highlighting
      };

      textItemsRef.current.push(textData);
      hlTextItemsRef.current.push({ ...textData });
    }
  } catch (error) {
    console.error(`Error rendering page ${pageNum}:`, error);
  }
};

/**
 * Scrolls the viewport to the highlighted word, keeping it in view.
 */
// TODO: Shall rework for precise auto scrolling

export const scrollToHighlightedText = (
  canvasRef,
  viewport,
  textItemsRef,
  itemIndex
) => {
  const canvas = canvasRef.current;
  if (!canvas || !viewport) return;

  // Get the item position
  const item = textItemsRef.current[itemIndex];
  if (!item) return;

  // Extract the viewport scaling factor (vertical scale)
  const scale = viewport.transform[3]; // Typically scale factor for y-coordinates

  // Convert PDF coordinates to screen coordinates using viewport scaling
  const adjustedY = item.y * scale;

  // Calculate a safe scroll offset (shifted above the word)
  const scrollOffset = adjustedY - item.height * scale * 2; // Adjust for scale & word height

  // Ensure canvas moves into view smoothly
  canvas.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

  // Scroll the window to keep highlighted text in the center
  window.scrollTo({
    top: scrollOffset,
    behavior: 'smooth',
  });
};

/**
 * Handle a click event on the canvas to detect which text item was clicked,
 * then store its index in state, and speak from that item onward.
 */

export const handleCanvasClick = (
  event,
  window,
  canvasRef,
  getPageImageData,
  textItemsRef,
  updateHighlightByIndex,
  setClickedItemIndex,
  language,
  speed,
  setIsSpeaking,
  clearHighlight
) => {
  const canvas = canvasRef.current;
  if (!canvas) {
    console.warn('Canvas ref is not set.');
    return;
  }

  const rect = canvas.getBoundingClientRect();
  const scaleX = canvas.width / rect.width;
  const scaleY = canvas.height / rect.height;

  // Determine if it's a touch event or mouse event
  const clientX = event.touches ? event.touches[0].clientX : event.clientX;
  const clientY = event.touches ? event.touches[0].clientY : event.clientY;

  // Convert browser coords to actual canvas coords
  const clickX = (clientX - rect.left) * scaleX;
  const clickY = (clientY - rect.top) * scaleY;

  console.log('Canvas clicked at', clickX, clickY);

  let foundIndex = null;
  for (let i = 0; i < textItemsRef.current.length; i++) {
    const item = textItemsRef.current[i];
    // Because 'y' is the baseline, check between (y - height) and y
    if (
      clickX >= item.x &&
      clickX <= item.x + item.width &&
      clickY >= item.y - item.height &&
      clickY <= item.y
    ) {
      foundIndex = i;
      break;
    }
  }

  if (foundIndex !== null) {
    // We know exactly which item was clicked
    setClickedItemIndex(foundIndex);
    console.log(
      'Clicked item index:',
      foundIndex,
      ' Text:',
      textItemsRef.current[foundIndex].text
    );

    // Read from that index onward
    speakFromClickedItem(
      window,
      canvasRef,
      getPageImageData,
      textItemsRef,
      updateHighlightByIndex,
      language,
      speed,
      setIsSpeaking,
      clearHighlight,
      foundIndex,
      -1,
      false
    );
  }
};

/**
 * Speak from a given text item index onward.
 * By default, it reads items on the same or next lines, but stops if there's a gap in Y.
 * If entireParagraph is true, it tries to gather everything in the "paragraph."
 */
export const speakFromClickedItem = (
  window,
  canvasRef,
  getPageImageData,
  textItemsRef,
  updateHighlightByIndex,
  language,
  speed,
  setIsSpeaking,
  clearHighlight,
  startIndex,
  endIndex = -1,
  entireParagraph = false
) => {
  if (startIndex < 0 || startIndex >= textItemsRef.current.length) {
    console.warn(`Invalid start index ${startIndex}.`);
    return;
  }

  const clickedItem = textItemsRef.current[startIndex];
  if (!clickedItem) {
    console.warn('No item found at start index:', startIndex);
    return;
  }

  let itemsToSpeak = [clickedItem];
  let lastY = clickedItem.y;
  const lineGapThreshold = clickedItem.height * 1.5; // paragraph logic

  const endTextItemsRef =
    endIndex === -1 ? textItemsRef.current.length : endIndex + 1;
  // Gather items from startIndex+1 onward
  for (let i = startIndex + 1; i < endTextItemsRef; i++) {
    const nextItem = textItemsRef.current[i];
    if (entireParagraph) {
      // If the vertical distance from the previous item is small, treat it as same paragraph
      if (Math.abs(nextItem.y - lastY) < lineGapThreshold) {
        itemsToSpeak.push(nextItem);
        lastY = nextItem.y;
      } else {
        // Break on a new paragraph
        break;
      }
    } else {
      // Non-paragraph mode: collect items in normal reading order until a big Y jump
      if (nextItem.y >= lastY) {
        itemsToSpeak.push(nextItem);
        lastY = nextItem.y;
      } else {
        break;
      }
    }
  }

  // Sort items top-to-bottom, then left-to-right
  itemsToSpeak.sort((a, b) => a.y - b.y || a.x - b.x);

  // Build utterance text and track each item's character range
  let utteranceText = '';
  const wordsMapping = [];
  let currentLineY = itemsToSpeak[0].y;

  itemsToSpeak.forEach((item, idx) => {
    let prefix = '';
    if (idx !== 0) {
      // If there's a line break, add '\n'; otherwise, add a space
      if (Math.abs(item.y - currentLineY) > item.height * 0.5) {
        prefix = '\n';
        currentLineY = item.y;
      } else {
        prefix = ' ';
      }
    }
    const startIdx = utteranceText.length;
    utteranceText += prefix + item.text;
    const endIdx = utteranceText.length;

    // Store the index mapping so we can highlight by index
    wordsMapping.push({
      start: startIdx,
      end: endIdx,
      index: item.index, // The item’s unique index in textItemsRef
    });
  });

  console.log('Speaking text:', utteranceText);
  const utterance = new SpeechSynthesisUtterance(utteranceText);

  utterance.rate = speed;
  utterance.lang = language;
  setIsSpeaking(true);

  // Highlight each spoken word by its index
  utterance.onboundary = (event) => {
    if (event.name === 'word') {
      const charIndex = event.charIndex;
      // Find which item the speech engine is on
      const currentWord = wordsMapping.find(
        (w) => charIndex >= w.start && charIndex < w.end
      );
      if (currentWord) {
        console.log(
          'Speaking word at charIndex',
          charIndex,
          ' => item index:',
          currentWord.index
        );
        updateHighlightByIndex(currentWord.index);
      }
    }
  };

  // When speech ends, clear highlight
  utterance.onend = () => {
    setIsSpeaking(false);
    clearHighlight(canvasRef, getPageImageData);
  };

  if (window.speechSynthesis.speaking || window.speechSynthesis.paused) {
    window.speechSynthesis.cancel();
  }
  window.speechSynthesis.speak(utterance);
};
