import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Row,
  Col,
  Card,
  Button,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import './HomePage.css';
import {
  webDevelopmentSkills,
  webDevelopmentSkillsMapping,
} from '../../resourcesDocs/skillsList.js';
import SEOHomePage from '../../resourcesDocs/SEOs/SEOHomePage.js';

const HomePage = () => {
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);
  const reverseHorizontalScrollContainerRef = useRef(null);

  useEffect(() => {
    // Auto-scrolling for horizontal containers
    const setUpAutoScroll = (ref, direction) => {
      const scrollContainer = ref.current;
      if (scrollContainer) {
        const scrollStep = 1;
        const scrollInterval = 20;
        let scrollDirection = direction;

        const scroll = () => {
          if (
            scrollContainer.scrollLeft + scrollContainer.offsetWidth >=
            scrollContainer.scrollWidth
          ) {
            scrollDirection = -1;
          } else if (scrollContainer.scrollLeft <= 0) {
            scrollDirection = 1;
          }
          scrollContainer.scrollLeft += scrollStep * scrollDirection;
        };

        const intervalId = setInterval(scroll, scrollInterval);

        return () => clearInterval(intervalId); // Cleanup on unmount
      }
    };

    const stopScroll1 = setUpAutoScroll(scrollContainerRef, 1);
    const stopScroll2 = setUpAutoScroll(
      reverseHorizontalScrollContainerRef,
      -1
    );

    return () => {
      stopScroll1();
      stopScroll2();
    };
  }, []);

  return (
    <>
      {/* SEO Metadata */}
      <SEOHomePage page="home" />

      {/* ====== NAVBAR ====== */}
      <Navbar bg="light" expand="lg" className="shadow-sm sticky-top">
        <Container>
          {/* Brand and Logo */}
          <Navbar.Brand onClick={() => navigate('/')} aria-label="Homepage">
            <img
              src="/logo.png"
              alt="Happy Learning Solutions Logo"
              width="40"
              height="40"
              loading="lazy"
            />{' '}
            <span className="fw-bold text-primary">HLS</span>
          </Navbar.Brand>

          {/* Navbar Toggle Button for Small Screens */}
          <Navbar.Toggle aria-controls="navbar-hls" />
          <Navbar.Collapse id="navbar-hls">
            <Nav className="ms-auto">
              <Nav.Link
                onClick={() => navigate('/kids')}
                className="text-primary fw-bold kids-learning-text"
                aria-label="Interactive Memorization"
              >
                🎨 Fun Kids Learning! ✨
              </Nav.Link>

              {/* Interactive Memorization Link */}
              <Nav.Link
                onClick={() => navigate('/memorise')}
                className="text-primary fw-bold sparkle-text"
                aria-label="Interactive Memorization"
              >
                Try Memorization
              </Nav.Link>

              {/* Skills Dropdown */}
              <NavDropdown title="Skills" id="skills-nav-dropdown" align="end">
                <div
                  style={{
                    maxHeight: '400px', // Restrict vertical height
                    overflowY: 'auto', // Add scrollbar for vertical overflow
                    width: '600px', // Set consistent width
                    transform: 'translateX(0%)', // Push dropdown to the left
                    right: 0, // Align with the right edge of the dropdown toggle
                  }}
                >
                  <div className="container">
                    <div className="row">
                      {webDevelopmentSkills.map((skill) => (
                        <div
                          className="col-12 col-sm-6 col-md-3"
                          key={webDevelopmentSkillsMapping[skill]}
                        >
                          <NavDropdown.Item
                            onClick={() => navigate(`/${skill}`)}
                            aria-label={`Learn ${webDevelopmentSkillsMapping[skill]}`}
                            className="text-wrap"
                          >
                            {webDevelopmentSkillsMapping[skill]}
                          </NavDropdown.Item>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </NavDropdown>

              {/* Log in and Sign Up Links */}
              <Nav.Link onClick={() => navigate('/login')}>Log in</Nav.Link>
              <Nav.Link onClick={() => navigate('/register')}>Sign Up</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>

        {/* Breadcrumb Navigation */}
        {/* <Container>
          <nav aria-label="breadcrumb" className="my-2">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/" className="text-decoration-none text-primary">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Learn Coding
              </li>
            </ol>
          </nav>
        </Container> */}
      </Navbar>

      {/* ====== HERO / BANNER ====== */}
      <header className="p-5 text-center bg-dark text-white">
        <Container>
          <h1 className="display-4 fw-bold">Learn to Code with Ease</h1>
          <p className="lead">
            Join millions of learners mastering the art of coding, web
            development, and problem-solving with HLS.
          </p>
          <div className="mt-4">
            <Button
              variant="success"
              size="lg"
              className="me-2"
              onClick={() => navigate('/#tutorials')}
            >
              Explore Tutorials
            </Button>
            <Button
              variant="outline-light"
              size="lg"
              onClick={() => navigate('/#search')}
            >
              Find Resources
            </Button>
          </div>
        </Container>
      </header>

      {/* ====== HORIZONTAL SCROLLABLE SKILLS LIST ====== */}
      <main>
        <section className="py-5 bg-light">
          <Container>
            <Row>
              <Col>
                <h2 className="text-center fw-bold">
                  Master Web Development Skills
                </h2>
                <p className="text-center text-muted">
                  Explore interactive tutorials and examples for the most
                  in-demand web development skills.
                </p>
              </Col>
            </Row>
            <Row
              ref={scrollContainerRef}
              className="g-4 justify-content-start flex-nowrap overflow-auto"
              style={{
                scrollSnapType: 'x mandatory',
                scrollbarWidth: 'none',
                paddingBottom: '1rem',
              }}
            >
              {webDevelopmentSkills.map((skill) => (
                <Col
                  xs={12}
                  md={6}
                  lg={4}
                  key={webDevelopmentSkillsMapping[skill]}
                  style={{ flex: '0 0 auto' }}
                >
                  <Card
                    className="h-100 shadow-sm"
                    style={{ minWidth: '300px' }}
                  >
                    <Card.Body className="text-center">
                      <Card.Title className="fw-bold">
                        {webDevelopmentSkillsMapping[skill]}
                      </Card.Title>
                      <Card.Text>
                        Learn {webDevelopmentSkillsMapping[skill]} and take your
                        web development skills to the next level.
                      </Card.Text>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            Click to explore{' '}
                            {webDevelopmentSkillsMapping[skill]}
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="primary"
                          className="w-100"
                          onClick={() => navigate(`/${skill}`)}
                        >
                          Explore {webDevelopmentSkillsMapping[skill]}
                        </Button>
                      </OverlayTrigger>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
        {/* ====== CODE EDITOR ====== */}
        <section className="py-5 bg-dark text-white">
          <Container>
            <Row className="align-items-center">
              <Col md={6}>
                <h2 className="fw-bold">Try Our Code Editor</h2>
                <p>
                  Edit code and view the result in your browser. Our platform
                  supports multiple programming languages for online testing and
                  debugging.
                </p>
                <Button
                  variant="light"
                  size="lg"
                  onClick={() => navigate('/tryit')}
                >
                  Try it Yourself
                </Button>
              </Col>
              <Col md={6} className="mt-4 mt-md-0">
                <Card className="shadow-sm">
                  <Card.Body className="bg-white text-dark">
                    <pre className="m-0">
                      {`<!DOCTYPE html>
<html lang="en">
<head>
  <title>Welcome to Happy Learning Solutions 🎉</title>
</head>
<body>
  <main style="margin-top: 20px;">
    <h2>Hello, World! 🌎</h2>
    <p style="font-size: 1.2em;">This is your first step toward a joyful learning journey! 📚✨</p>
    <p>Explore, create, and grow with us. You’re destined for greatness! 💪😃</p>
  </main>
</body>
</html>
`}
                    </pre>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        {/* ====== EXERCISES & QUIZZES ====== */}

        <section className="py-5 bg-light">
          <Container>
            <Row className="mb-4">
              <Col>
                <h2 className="text-center fw-bold">
                  Master Web Development Skills
                </h2>
                <p className="text-center text-muted">
                  Explore hands-on tutorials, comprehensive references, and
                  interactive examples to master the most in-demand web
                  development skills.
                </p>
              </Col>
            </Row>
            <Row
              ref={reverseHorizontalScrollContainerRef}
              className="g-4 justify-content-start flex-nowrap overflow-auto"
              style={{
                scrollSnapType: 'x mandatory',
                scrollbarWidth: 'thin',
                paddingBottom: '1rem',
              }}
            >
              {webDevelopmentSkills.map((skill) => (
                <Col
                  xs={12}
                  md={6}
                  lg={4}
                  key={webDevelopmentSkillsMapping[skill]}
                  style={{ flex: '0 0 auto' }}
                >
                  <Card
                    className="h-100 shadow-sm"
                    style={{ minWidth: '300px' }}
                  >
                    <Card.Body className="text-center">
                      <Card.Title className="fw-bold">
                        {webDevelopmentSkillsMapping[skill]}
                      </Card.Title>
                      <Card.Text>
                        Enhance your expertise in{' '}
                        {webDevelopmentSkillsMapping[skill]}, a crucial skill in
                        the world of modern web development.
                      </Card.Text>
                      <Button
                        variant="primary"
                        className="w-100"
                        onClick={() => navigate(`/${skill}`)}
                      >
                        Start Practicing {webDevelopmentSkillsMapping[skill]}
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </main>

      {/* ====== FOOTER ====== */}
      <footer className="bg-light text-dark py-4">
        <Container>
          <Row>
            <p className="small text-muted">
              <strong>Happy Learning Solutions (HLS)</strong> is your gateway to
              interactive and engaging learning experiences. Designed for
              learners of all levels, HLS offers tutorials, references, and
              real-world examples. Our content is meticulously reviewed and
              updated to ensure accuracy and clarity, helping you grasp even the
              most complex concepts with ease.
            </p>
            <Col md={6} className="mb-3">
              <h5>Follow Us</h5>
              <div>
                <a
                  href="https://www.youtube.com/@HLS"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none me-2"
                >
                  <i className="fab fa-youtube"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/HLS.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none me-2"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
                <a
                  href="https://discord.com/invite/HLS"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none me-2"
                >
                  <i className="fab fa-discord"></i>
                </a>
              </div>
            </Col>
          </Row>
          <hr />
          <div className="text-center small text-muted">
            © {new Date().getFullYear()} Happy Learning Solutions(HLS). All
            Rights Reserved.
          </div>
        </Container>
      </footer>
    </>
  );
};

export default HomePage;
