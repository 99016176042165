import { englishLessons } from '../lessons/englishLessons.js';
import { mathsLessons } from '../lessons/mathsLessons.js';
import { teluguLessons } from '../lessons/teluguLessons.js';

export const class1Subjects = [
  {
    subjectName: 'English',
    color: '#FFB6C1',
    lessons: englishLessons,
  },
  {
    subjectName: 'Math',
    color: '#ADD8E6',
    lessons: mathsLessons,
  },
  {
    subjectName: 'Telugu',
    color: '#FFD700',
    lessons: teluguLessons,
  },
];
