/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
// import Register from './pages/Auth/Register.js';
import Login from './pages/Auth/Login.js';
import QuizSubmission from './pages/Quizzes/QuizSubmission.js';
import CodingSubmission from './pages/Coding/CodingSubmission.js';
import LearnerDashboard from './pages/Dashboard/LearnerDashboard.js';
import JobHomepage from './pages/Home/JobHomepage.js';
import CourseCatalog from './pages/Course/CourseCatalog.js';
// import OngoingCoursePage from './pages/Course/OngoingCoursePage.js';
import OngoingCoursePageNew from './pages/Course/OngoingCoursePageNew.js';
import ProfileDashboard from './pages/Profile/Profile.js';
import CourseContentPage from './pages/Course/CourseContentPage.js';
import ProjectView from './pages/Projects/ProjectView.js';
import ProjectSubmissionView from './pages/Projects/ProjectSubmissionView.js';
// import ActivityPage from './pages/Course/ActivityPage.js';
import ActivityPageNew from './pages/Course/ActivityPageNew.js';
import AdminDashboard from './pages/Admin/AdminDashboard.js';
import UserManagement from './pages/Admin/UserManagement.js';
import CourseManagement from './pages/Admin/CourseManagement.js';
import BadgeManagement from './pages/Admin/BadgeManagement.js';
import CourseContentForm from './pages/Admin/CourseContentForm.js';
import AboutUs from './components/AboutUs.js';
import Careers from './components/Careers.js';
import Terms from './components/Terms.js';
import PrivacyPolicy from './components/PrivacyPolicy.js';
import Contact from './components/Contact.js';
import Pricing from './components/Pricing.js';
import Blog from './components/Blog.js';
import Community from './components/Community.js';
import SuccessStories from './components/SuccessStories.js';
import PublicCoursePage from './pages/Course/PublicCoursePage.js';
import Events from './pages/Events/Events.js';
import Forums from './components/Forums.js';
import Clubs from './components/Clubs.js';
import CustomModal from './components/CustomModal.js'; // Import your CustomModal component
import CareerPathDetails from './components/static/CareerPathDetails.js';
import OngoingCareerPathPageNew from './pages/CareerPath/OngoingCareerPathPageNew.js';
import CareerPathActivityPageNew from './pages/CareerPath/CareerPathActivityPageNew.js';
import TopicsList from './resourcesDocs/TopicsList.js';
import TopicDetails from './resourcesDocs/TopicDetails.js';
import ChooseHome from './pages/Home/ChooseHome.js';
import BTechHome from './resourcesDocs/BTechHome.js';
import { jwtDecode } from 'jwt-decode';
import HomePage from './pages/Home/HomePage.js';
import InteractiveMemorizer from './startComponents/InteractiveMemorizer.js';
import LoopsAnimation from './conceptAnimations/LoopsAnimation.js';
import ConceptExplanationAnimationKids from './conceptAnimations/ConceptExplanationAnimationKids.js';
import SchoolLearningResources from './school/SchoolLearningResources.js';
import SyllabusManager from './school/SyllabusManager.js';
import SSCSchoolManager from './school/ssc/dataManagers/SSCSchoolManager.js';

import SchoolComponent from './school/components/SchoolComponent.js';
import ClassComponent from './school/components/ClassComponent.js';
import SubjectComponent from './school/components/SubjectComponent.js';
import LessonComponent from './school/components/LessonComponent.js';

import SSCClassManager from './school/ssc/dataManagers/SSCClassManager.js';
import SSCSubjectManager from './school/ssc/dataManagers/SSCSubjectManager.js';
import SSCLessonManager from './school/ssc/dataManagers/SSCLessonManager.js';
import SSCActivityManager from './school/ssc/dataManagers/SSCActivityManager.js';

import SchoolActivitiesManager from './school/components/SchoolActivitiesManager.js';
import PdfReaderLayoutSpeaking from './school/components/interactiveActivities/PDFComponent/PdfReaderLayoutSpeaking.js';

import Class6TextbookList from './school/ssc/data/schoolData/telanganaGovernmentDistributon/textBooks/Class6TextbookList.js';
import TextbookManager from './school/components/TextbookManager.js';

const trackPageView = (url) => {
  window.gtag('config', 'G-ETPH5G1NN9', { page_path: url });
};

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [redirectPath, setRedirectPath] = useState('/'); // Store the path to redirect after closing the modal

  // Get the role and token directly from localStorage
  const userRole = localStorage.getItem('role');
  const token = localStorage.getItem('token');

  // Utility function to check token expiration
  const isTokenExpired = (token) => {
    if (!token) return true; // If no token, consider it expired
    try {
      const decoded = jwtDecode(token);
      return decoded.exp * 1000 < Date.now(); // Check if token expiration time is in the past
    } catch (error) {
      console.error('Invalid token:', error);
      return true;
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      console.log = console.warn = console.error = () => {};
    }
  }, []);

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  useEffect(() => {
    // Redirect based on role and token validity
    if (location.pathname === '/' && userRole) {
      if (isTokenExpired(token)) {
        // Token is invalid or expired
        localStorage.removeItem('token'); // Clear the expired token
        localStorage.removeItem('role'); // Clear the role as well
        navigate('/', { replace: true }); // Redirect to the homepage
      } else {
        // Navigate to the appropriate dashboard based on role
        if (userRole === 'admin') {
          navigate('/admin');
        } else if (userRole === 'student') {
          navigate('/learn');
        }
      }
    }
  }, [location.pathname, userRole, token, navigate]);

  useEffect(() => {
    // Display the unauthorized access modal if needed
    if (location.state?.unauthorized) {
      setModalMessage(
        location.state.message || 'You do not have access to this page.'
      );
      setRedirectPath(location.state.redirectPath || '/');
      setShowModal(true);
    }
  }, [location.state]);

  const handleModalClose = () => {
    setShowModal(false);
    navigate(redirectPath); // Redirect to the specified path after the modal is closed
  };

  return (
    <>
      <Helmet>
        <title>
          Learn HTML, CSS, JavaScript, and React.js - Happy Learning Solutions
        </title>
        <meta
          name="description"
          content="Master web development with Happy Learning Solutions. Learn HTML, CSS, JavaScript, and React.js through interactive tutorials, examples, and exercises. Start your coding journey today!"
        />
        <meta
          name="keywords"
          content="HTML tutorials, CSS tutorials, JavaScript tutorials, React.js tutorials, web development, front-end development, coding courses, learn programming"
        />
        <meta name="author" content="Happy Learning Solutions" />
        <meta
          property="og:title"
          content="Learn HTML, CSS, JavaScript, and React.js - Happy Learning Solutions"
        />
        <meta
          property="og:description"
          content="Enhance your web development skills with HTML, CSS, JavaScript, and React.js tutorials and exercises. Join Happy Learning Solutions to grow as a developer!"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://hlslive.in" />
        <meta property="og:image" content="https://hlslive.in/og-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Learn HTML, CSS, JavaScript, and React.js - Happy Learning Solutions"
        />
        <meta
          name="twitter:description"
          content="Interactive tutorials and resources to master HTML, CSS, JavaScript, and React.js. Start your web development journey with Happy Learning Solutions!"
        />
        <meta
          name="twitter:image"
          content="https://hlslive.in/twitter-image.jpg"
        />
      </Helmet>
      <div className="wrapper">
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<HomePage />} />
          {/* <Route path="/OCRToSpeech" element={<OCRToSpeech />} /> */}
          <Route
            path="/PdfReaderLayoutSpeaking"
            element={<PdfReaderLayoutSpeaking />}
          />

          {/* <Route path="/" element={<ChooseHome />} /> */}
          <Route path="/jobspace" element={<JobHomepage />} />
          <Route path="/btechspace" element={<BTechHome />} />

          <Route path="/docs" element={<TopicsList />} />
          <Route path="/:topicNameParam" element={<TopicDetails />} />

          <Route path="/memorise" element={<InteractiveMemorizer />} />
          <Route path="/kids" element={<SchoolLearningResources />} />

          <Route path="/kids/:syllabus" element={<SyllabusManager />} />

          <Route path="/kids/ssc/add-school" element={<SSCSchoolManager />} />
          <Route
            path="/kids/ssc/:schoolName/add-class"
            element={<SSCClassManager />}
          />
          <Route
            path="/kids/ssc/:schoolName/:className/add-subject"
            element={<SSCSubjectManager />}
          />
          <Route
            path="/kids/ssc/:schoolName/:className/:subjectName/add-lesson"
            element={<SSCLessonManager />}
          />
          <Route
            path="/kids/ssc/:schoolName/:className/:subjectName/:lessonName/add-activity"
            element={<SSCActivityManager />}
          />

          <Route path="/kids/ssc/:schoolName" element={<SchoolComponent />} />
          <Route
            path="/kids/ssc/:schoolName/:className"
            element={<ClassComponent />}
          />
          <Route
            path="/kids/ssc/:schoolName/:className/textBooks"
            element={<TextbookManager />}
          />

          <Route
            path="/kids/ssc/textBooks/:fileName/:speakingLanguage"
            element={<PdfReaderLayoutSpeaking />}
          />

          <Route
            path="/kids/ssc/:schoolName/:className/:subjectName"
            element={<SubjectComponent />}
          />
          <Route
            path="/kids/ssc/:schoolName/:className/:subjectName/:lessonName"
            element={<LessonComponent />}
          />
          <Route
            path="/kids/ssc/:schoolName/:className/:subjectName/:lessonName/:activityName/:questionIndex"
            element={<SchoolActivitiesManager />}
          />

          <Route
            path="/concept-animation"
            element={<ConceptExplanationAnimationKids />}
          />

          <Route
            path="/career-paths/:coursePath"
            element={<CareerPathDetails />}
          />
          {/* Pausing User Signup */}
          {/* <Route path="/register" element={<Register />} /> */}
          <Route path="/register" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/community" element={<Community />} />
          <Route path="/community/events" element={<Events />} />
          <Route path="/community/forums" element={<Forums />} />
          <Route path="/community/clubs" element={<Clubs />} />
          <Route
            path="/community/success-stories"
            element={<SuccessStories />}
          />
          <Route path="/public/courses" element={<CourseCatalog />} />
          <Route path="/public/course/:id" element={<PublicCoursePage />} />
          {/* Admin Routes */}
          {userRole === 'admin' ? (
            <>
              <Route path="/admin" element={<AdminDashboard />} />
              <Route path="/admin/users" element={<UserManagement />} />
              <Route path="/admin/courses" element={<CourseManagement />} />
              <Route path="/admin/badges" element={<BadgeManagement />} />
              <Route
                path="/admin/courses/new"
                element={<CourseContentForm />}
              />
              <Route
                path="/admin/courses/edit/:id"
                element={<CourseContentForm />}
              />
            </>
          ) : (
            userRole && (
              <Route
                path="*"
                element={
                  <Navigate
                    to="/"
                    replace
                    state={{
                      unauthorized: true,
                      message:
                        'You are not authorized to access the admin routes.',
                    }}
                  />
                }
              />
            )
          )}
          {/* Student Routes */}
          {userRole === 'guest' ||
          userRole === 'promotional' ||
          userRole === 'student' ? (
            <>
              <Route path="/learn" element={<LearnerDashboard />} />
              <Route path="/submit-quiz" element={<QuizSubmission />} />
              <Route path="/submit-coding" element={<CodingSubmission />} />
              <Route path="/catalog" element={<CourseCatalog />} />
              <Route path="/courses" element={<CourseCatalog />} />
              <Route path="/profile" element={<ProfileDashboard />} />
              {/* <Route path="/course/:id" element={<OngoingCoursePage />} /> */}
              <Route path="/course/:id" element={<OngoingCoursePageNew />} />
              <Route
                path="/career-path/:id"
                element={<OngoingCareerPathPageNew />}
              />
              <Route
                path="/course/:courseId/:sectionId/activity/:activityType/:activityId"
                // element={<ActivityPage />}
                element={<ActivityPageNew />}
              />
              <Route
                path="/career-path/:careerPathId/:courseId/:sectionId/activity/:activityType/:activityId"
                // element={<ActivityPage />}
                element={<CareerPathActivityPageNew />}
              />
              <Route
                path="/course/:courseId/lesson/:lessonId"
                element={<CourseContentPage />}
              />
              <Route path="/project/:projectId" element={<ProjectView />} />
              <Route
                path="/project/:projectId/submit"
                element={<ProjectSubmissionView />}
              />
            </>
          ) : (
            userRole && (
              <Route
                path="*"
                element={
                  <Navigate
                    to="/"
                    replace
                    state={{
                      unauthorized: true,
                      message:
                        'You are not authorized to access the student routes.',
                    }}
                  />
                }
              />
            )
          )}
          {/* Fallback Redirect for Unauthorized Access */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

        {/* Modal for Unauthorized Access */}
        <CustomModal
          show={showModal}
          onHide={handleModalClose}
          title="Access Denied"
          message={modalMessage}
        />
      </div>
    </>
  );
}

function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default AppWrapper;
