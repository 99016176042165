import React, { useEffect, useState } from 'react';
import {
  Container,
  Form,
  Button,
  Card,
  ListGroup,
  Alert,
} from 'react-bootstrap';
import {
  getAllSchools,
  getAllClasses,
  addActivity,
  deleteActivity,
} from '../../db/indexedDB.js';
import { SchoolActivityTypes } from '../../enums/constants.js';

const SSCActivityManager = () => {
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [lessons, setLessons] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState('');
  const [activities, setActivities] = useState([]);
  const [activityName, setActivityName] = useState('');
  const [activityType, setActivityType] = useState(
    SchoolActivityTypes.INDIVIDUAL_SPEAKING
  );
  const [activityLang, setActivityLang] = useState('English');
  const [activityData, setActivityData] = useState('');
  const [activityColor, setActivityColor] = useState('#ccffcc');

  useEffect(() => {
    fetchSchools();
  }, []);

  const fetchSchools = async () => {
    const data = await getAllSchools();
    setSchools(data);
  };

  const fetchClasses = async (schoolName) => {
    const data = await getAllClasses(schoolName);
    setClasses(data);
  };

  const handleSchoolChange = (e) => {
    const schoolName = e.target.value;
    setSelectedSchool(schoolName);
    setSelectedClass('');
    setSelectedSubject('');
    setSelectedLesson('');
    setActivities([]);
    fetchClasses(schoolName);
  };

  const handleClassChange = (e) => {
    const className = e.target.value;
    setSelectedClass(className);
    setSelectedSubject('');
    setSelectedLesson('');
    setActivities([]);

    const selectedClassObj = classes.find((cls) => cls.className === className);
    setSubjects(selectedClassObj ? selectedClassObj.subjects : []);
  };

  const handleSubjectChange = (e) => {
    const subjectName = e.target.value;
    setSelectedSubject(subjectName);
    setSelectedLesson('');
    setActivities([]);

    const selectedClassObj = classes.find(
      (cls) => cls.className === selectedClass
    );
    const selectedSubjectObj = selectedClassObj?.subjects.find(
      (subj) => subj.subjectName === subjectName
    );

    setLessons(selectedSubjectObj ? selectedSubjectObj.lessons : []);
  };

  const handleLessonChange = (e) => {
    const lessonName = e.target.value;
    setSelectedLesson(lessonName);

    const selectedClassObj = classes.find(
      (cls) => cls.className === selectedClass
    );
    const selectedSubjectObj = selectedClassObj?.subjects.find(
      (subj) => subj.subjectName === selectedSubject
    );
    const selectedLessonObj = selectedSubjectObj?.lessons.find(
      (lesson) => lesson.lessonName === lessonName
    );

    setActivities(selectedLessonObj ? selectedLessonObj.activities || [] : []);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !selectedSchool ||
      !selectedClass ||
      !selectedSubject ||
      !selectedLesson ||
      !activityName.trim()
    )
      return;

    let formattedList = [];
    if (activityType === SchoolActivityTypes.INDIVIDUAL_SPEAKING) {
      formattedList = activityData.split(',').map((item) => item.trim());
    } else {
      formattedList = activityData;
    }

    const newActivity = {
      activityName,
      activityType,
      list: formattedList,
      lang: activityLang,
      color: activityColor,
    };

    await addActivity(
      selectedSchool,
      selectedClass,
      selectedSubject,
      selectedLesson,
      newActivity
    );

    // Update local state
    setActivities((prevActivities) => [...prevActivities, newActivity]);

    setActivityName('');
    setActivityData('');
  };

  const handleDeleteActivity = async (activityName) => {
    await deleteActivity(
      selectedSchool,
      selectedClass,
      selectedSubject,
      selectedLesson,
      activityName
    );

    // Remove from state
    setActivities((prevActivities) =>
      prevActivities.filter(
        (activity) => activity.activityName !== activityName
      )
    );
  };

  // Sample input format based on activity type
  const sampleDataFormat =
    activityType === SchoolActivityTypes.INDIVIDUAL_SPEAKING
      ? 'Apple, Banana, Cat, Dog'
      : 'Question: What is your name?, Answer: My name is John.';

  return (
    <Container className="py-4">
      <h1 className="text-center mb-4">📝 Manage Activities</h1>

      {/* Select School */}
      <Card className="p-4 shadow mb-4">
        <Form.Group className="mb-3">
          <Form.Label>Select School</Form.Label>
          <Form.Select onChange={handleSchoolChange} value={selectedSchool}>
            <option value="">Choose a School</option>
            {schools.map((school) => (
              <option key={school.schoolName} value={school.schoolName}>
                {school.schoolName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Card>

      {/* Select Class */}
      {selectedSchool && (
        <Card className="p-4 shadow mb-4">
          <Form.Group className="mb-3">
            <Form.Label>Select Class</Form.Label>
            <Form.Select onChange={handleClassChange} value={selectedClass}>
              <option value="">Choose a Class</option>
              {classes.map((cls) => (
                <option key={cls.className} value={cls.className}>
                  {cls.className}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Card>
      )}

      {/* Select Subject */}
      {selectedClass && (
        <Card className="p-4 shadow mb-4">
          <Form.Group className="mb-3">
            <Form.Label>Select Subject</Form.Label>
            <Form.Select onChange={handleSubjectChange} value={selectedSubject}>
              <option value="">Choose a Subject</option>
              {subjects.map((subj) => (
                <option key={subj.subjectName} value={subj.subjectName}>
                  {subj.subjectName}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Card>
      )}

      {/* Select Lesson */}
      {selectedSubject && (
        <Card className="p-4 shadow mb-4">
          <Form.Group className="mb-3">
            <Form.Label>Select Lesson</Form.Label>
            <Form.Select onChange={handleLessonChange} value={selectedLesson}>
              <option value="">Choose a Lesson</option>
              {lessons.map((lesson) => (
                <option key={lesson.lessonName} value={lesson.lessonName}>
                  {lesson.lessonName}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Card>
      )}

      {/* Display Existing Activities */}
      {selectedLesson && (
        <Card className="p-4 shadow mb-4">
          <h4 className="text-center mb-3">📂 Existing Activities</h4>
          {activities.length > 0 ? (
            <ListGroup>
              {activities.map((activity) => (
                <ListGroup.Item key={activity.activityName}>
                  {activity.activityName} - {activity.activityType} (
                  {activity.lang})
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDeleteActivity(activity.activityName)}
                    className="float-end"
                  >
                    ❌ Delete
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <p className="text-center">
              No activities available. Add a new one below.
            </p>
          )}
        </Card>
      )}

      {/* Add New Activity Form */}
      {selectedLesson && (
        <Card className="p-4 shadow">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Activity Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Activity Name"
                value={activityName}
                onChange={(e) => setActivityName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Activity Type</Form.Label>
              <Form.Select
                onChange={(e) => setActivityType(e.target.value)}
                value={activityType}
              >
                <option value={SchoolActivityTypes.INDIVIDUAL_SPEAKING}>
                  {SchoolActivityTypes.INDIVIDUAL_SPEAKING}
                </option>
                <option value={SchoolActivityTypes.QA_SPEAKING}>
                  {SchoolActivityTypes.QA_SPEAKING}
                </option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Language</Form.Label>
              <Form.Select
                onChange={(e) => setActivityLang(e.target.value)}
                value={activityLang}
              >
                <option value="English">English</option>
                <option value="Telugu">Telugu</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Activity List</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={sampleDataFormat}
                value={activityData}
                onChange={(e) => setActivityData(e.target.value)}
              />
              <Alert variant="info" className="mt-2">
                Example Format: <strong>{sampleDataFormat}</strong>
              </Alert>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Activity Color</Form.Label>
              <Form.Control
                type="color"
                value={activityColor}
                onChange={(e) => setActivityColor(e.target.value)}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100">
              ➕ Add Activity
            </Button>
          </Form>
        </Card>
      )}
    </Container>
  );
};

export default SSCActivityManager;
