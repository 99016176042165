export const questionsAndAnswersLessonSeven = [
  {
    question: 'Who was the narrator and why did he set out on a voyage?',
    answer:
      'The narrator was a young boy named Jim Hawkins. He had found a map leading to hidden treasure and set sail in search of it, along with a group of men, on a ship named Hispaniola.',
  },
  {
    question:
      'Describe how the Hispaniola began her voyage to the Isle of Treasure.',
    answer:
      'A little before dawn, the crew took their positions on the ship, ready to sail. As soon as the anchor was hauled up, the sails began to catch the wind, and the land started to recede on either side. The Hispaniola had begun her voyage to the Isle of Treasure.',
  },
  {
    question: 'Who was Mr. Arrow? What did the narrator tell you about him?',
    answer:
      "Mr. Arrow was an old sailor and the ship's mate. He was an alcoholic who drank excessively, but the crew could never figure out where he obtained his drink.",
  },
  {
    question:
      'Who was Long John Silver? What did the rest of the crew call him? Describe his appearance briefly.',
    answer:
      'Long John Silver was the ship’s one-legged cook. The rest of the crew called him Barbecue. He carried his crutch by a strap around his neck to keep both hands free. The crew respected and even obeyed him.',
  },
  {
    question: 'Name the men who accompanied the narrator on his journey.',
    answer:
      'The men who accompanied the narrator on his journey were Mr. Arrow, Mr. Trelawney, Job Anderson, Israel Hands, Long John Silver, and the squire.',
  },
];
