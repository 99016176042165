export const questionsAndAnswersLessonFourteen = [
  {
    question: 'What do birds do during spring?',
    answer:
      'Birds welcome spring with a cheerful song and wake up all the flowers.',
  },
  {
    question: 'What happens to the flowers during spring?',
    answer:
      'The flowers wake up and blossom, spreading joy and fragrance all around during spring.',
  },
  {
    question: 'Write any two words that the speaker uses to describe spring.',
    answer: 'The words used to describe spring are "reviving" and "joyous."',
  },
  {
    question:
      'According to the speaker, what do the earth, hills, woods, and valleys do during this time?',
    answer:
      'The earth, hills, woods, and valleys join together in a shout of celebration, making joyous sounds.',
  },
  {
    question: "What does the speaker advise us to do during nature's holiday?",
    answer:
      'The speaker advises us to cast aside all selfish concerns and join the grateful, happy throng to welcome the spring.',
  },
];
