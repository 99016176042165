export const questionsAndAnswersLessonNine = [
  {
    question: 'Who was Mr. Craven? What was the name of his house?',
    answer:
      "Mr. Craven was Mary Lennox's uncle. The name of his house was Misselthwaite Manor.",
  },
  {
    question: 'What did Mary do during her first few days at the Manor?',
    answer:
      'During her first few days at the manor, Mary was often cross with the people around her and behaved in an unfriendly manner.',
  },
  {
    question: 'Why was one of the gardens shut?',
    answer:
      "One of the gardens belonged to Mr. Craven's wife. When she died ten years ago, he was deeply saddened and decided to shut the garden, hiding the key so that no one could enter.",
  },
  {
    question: 'How did Mary open the secret garden?',
    answer:
      'Mary opened the secret garden using the key, which she found near the wall.',
  },
  {
    question: 'Who was Dickon? How did he help Mary?',
    answer:
      "Dickon was Martha's twelve-year-old brother. He helped Mary by bringing garden tools, planting seeds, and working alongside her in the garden.",
  },
];
