import { SchoolActivityTypes } from '../../../../../../enums/constants.js';
import {
  numberSystemLessonOneExampleFive,
  numberSystemLessonOneExampleFour,
  numberSystemLessonOneExampleOne,
  numberSystemLessonOneExampleThree,
  numberSystemLessonOneExercise1A2,
} from '../../constants/class6/Maths/lesson1.js';
import { lesson1Exercises1A } from '../../constants/class6/Maths/lesson1Exercises1A.js';
import { lessons6Exercise6A } from '../../constants/class6/Maths/lessons6Exercise6A.js';

export const mathsLessons = [
  {
    lessonName: 'Lesson 1: Number System',
    color: '#90EE90',
    activities: [
      {
        activityName: 'Example 1',
        activityType: SchoolActivityTypes.MATHS_NUMBER_SYSTEM_PERIODS,
        list: numberSystemLessonOneExampleOne,
        lang: 'English',
        color: '#ccffcc',
      },
      {
        activityName: 'Example 2',
        activityType: SchoolActivityTypes.MATHS_NUMBER_SYSTEM_PERIODS,
        list: numberSystemLessonOneExampleOne,
        lang: 'English',
        color: '#ccffcc',
      },
      {
        activityName: 'Example 3',
        activityType: SchoolActivityTypes.MATHS_NUMBER_SYSTEM_PERIODS,
        list: numberSystemLessonOneExampleThree,
        lang: 'English',
        color: '#ccffcc',
      },
      {
        activityName: 'Example 4',
        activityType: SchoolActivityTypes.MATHS_NUMBER_SYSTEM_PERIODS,
        list: numberSystemLessonOneExampleFour,
        lang: 'English',
        color: '#ccffcc',
      },
      {
        activityName: 'Example 5',
        activityType: SchoolActivityTypes.MATHS_NUMBER_SYSTEM_PERIODS,
        list: numberSystemLessonOneExampleFive,
        lang: 'English',
        color: '#ccffcc',
      },
      {
        activityName: 'Exercise 1A: 2',
        activityType: SchoolActivityTypes.MATHS_NUMBER_SYSTEM_PERIODS,
        list: numberSystemLessonOneExercise1A2,
        lang: 'English',
        color: '#ccffcc',
      },
    ],
  },
  lesson1Exercises1A,
  lessons6Exercise6A,
];
