export const questionsAndAnswersLessonNine = [
  {
    question: 'What did Professor Higgins do?',
    answer:
      'Professor Higgins was an expert in phonetics and a brilliant linguist. His academic interest was to document and study different dialects and ways of speaking. He views people merely as useful subjects for his research.',
  },
  {
    question: "What was Eliza Doolittle's ambition?",
    answer:
      'Eliza Doolittle was a working class Londoner who sold flowers on the streets. Her ambition was to work in a fancy flower shop, but her Cockney accent needs to go. So, she wants to learn to speak proper English, like a lady, and then apply for a position in a flower shop.',
  },
  {
    question:
      'Describe the appearance of Eliza when she met Higgins for the first time.',
    answer:
      'When Eliza met Higgins for the first time, she looked like a member of the lower class and was wearing a shoddy old coat and a nearly clean dress. She also had on a frightful-looking hat with brightly coloured ostrich feathers.',
  },
  {
    question: 'What did Pickering say he would do if Higgins succeeded?',
    answer:
      "Pickering says that he will humbly agree that Higgins is the greatest teacher in the land, if Higgins is able to pass off Fliza as a lady at the Ambassador's garden party. Pickering also promised to pay for her lessons.",
  },
  {
    question: 'Who was Alfred Doolittle and what did he want?',
    answer:
      "Alfred Doolittle was Eliza's father, an elderly garbage collector who simply wanted money from Higgins. He was aware that there was money to be made from the situation.",
  },
  {
    question:
      'What did Professor Higgins vow to do in a period of three months?',
    answer:
      'Professor Higgins vowed that he would be successful in introducing Eliza to high-class gatherings if she had a keen sense of hearing and sharp speaking skills. He claimed that he would fool everyone into believing that Eliza was an upper-class lady.',
  },
];
