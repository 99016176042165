import React, { useEffect, useState } from 'react';
import {
  Container,
  Button,
  Form,
  Card,
  Row,
  Col,
  Modal,
} from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import { openDB } from 'idb';
import { exportMyFamilyImages, importMyFamilyImages } from './db-helper.js';

const DB_NAME = 'FamilyDB';
const STORE_NAME = 'familyImages';
const FAMILY_MEMBERS = [
  'Mother',
  'Father',
  'Paternal Grandmother',
  'Paternal Grandfather',
  'Maternal Grandmother',
  'Maternal Grandfather',
  'Elder Aunt',
  'Elder Uncle',
  'Elder Sister',
  'Younger Sister',
  'Elder Brother',
  'Younger Brother',
  'Grandfather',
  'Aunt',
  'Uncle',
  'Younger Aunt',
  'Younger Uncle',
];

const FamilyGallery = () => {
  // const [familyMembers, setFamilyMembers] = useState([]);
  const [modalMembers, setModalMembers] = useState([]);

  // For batch-adding:
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [newMember, setNewMember] = useState({ name: '', image: null });

  // For editing:
  const [isEditing, setIsEditing] = useState(false);
  const [editMemberId, setEditMemberId] = useState(null);

  // Modal controls:
  const [showModal, setShowModal] = useState(false);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null);

  useEffect(() => {
    populateDefaultFamilyMembers();
  }, []);

  const [importStatus, setImportStatus] = useState(null);

  // Handle File Upload for Import
  const handleImport = (event) => {
    const file = event.target.files[0];
    if (file) {
      importMyFamilyImages(file)
        .then(() => setImportStatus('✅ Import Successful!'))
        .catch((error) => {
          console.error('❌ Import Error:', error);
          setImportStatus('❌ Import Failed. Check File Format.');
        });
    }
  };

  // Trigger File Input for Import
  const triggerImport = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'application/json';
    fileInput.addEventListener('change', handleImport);
    fileInput.click();
  };

  // ----------------------------------------
  // IndexedDB Helpers
  // ----------------------------------------
  const openDatabase = async () => {
    return openDB(DB_NAME, 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME, {
            keyPath: 'id',
            autoIncrement: true,
          });
        }
      },
    });
  };

  const fetchImages = async () => {
    console.log('🔄 Fetching family members from IndexedDB...');

    const db = await openDatabase();
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);
    let allItems = await store.getAll();

    console.log(`📦 Total Records Retrieved: ${allItems.length}`);

    // Filter out the defaultSetFlag entry
    allItems = allItems.filter((item) => item.id !== 'defaultSetFlag');

    console.log(
      `⚡ After Filtering 'defaultSetFlag': ${allItems.length} records remaining.`
    );

    // Sort by ID first, then prioritize items with images
    allItems.sort((a, b) => {
      // First, prioritize items where image is NOT null
      if (a.image && !b.image) return -1;
      if (!a.image && b.image) return 1;
      // Then, sort by ID in ascending order
      return a.id - b.id;
    });

    console.log(
      '📋 Final Sorted List:',
      allItems.map((item) => ({
        ID: item.id,
        Name: item.name,
        Image: item.image ? '✅ Has Image' : '❌ No Image',
      }))
    );

    setModalMembers(allItems);
    console.log('🎉 Family members list updated in state!');
  };

  const deleteMember = async (id) => {
    const db = await openDatabase();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    await store.delete(id);
    await tx.done;
    await fetchImages();
  };

  const getCroppedImg = async (imageSrc, cropAreaPixels) => {
    const image = new Image();
    image.src = imageSrc;
    await new Promise((resolve) => (image.onload = resolve));

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = cropAreaPixels.width;
    canvas.height = cropAreaPixels.height;

    ctx.drawImage(
      image,
      cropAreaPixels.x,
      cropAreaPixels.y,
      cropAreaPixels.width,
      cropAreaPixels.height,
      0,
      0,
      cropAreaPixels.width,
      cropAreaPixels.height
    );

    return new Promise((resolve) => {
      resolve(canvas.toDataURL('image/jpeg')); // Returns Base64 instead of Blob URL
    });
  };

  const handleCropComplete = async (croppedArea, croppedAreaPixels) => {
    const croppedImageBase64 = await getCroppedImg(
      newMember.image,
      croppedAreaPixels
    );
    setCroppedImage(croppedImageBase64);
  };

  const populateDefaultFamilyMembers = async () => {
    const db = await openDatabase();

    // Open a transaction to check if the default members have already been set
    const checkTx = db.transaction(STORE_NAME, 'readonly');
    const checkStore = checkTx.objectStore(STORE_NAME);
    const defaultSetFlag = await checkStore.get('defaultSetFlag');

    // If flag exists, skip setting default family members
    if (defaultSetFlag?.isSet) {
      console.log(
        '✅ Default family members are already set. Skipping population.'
      );
      return;
    }

    console.log('🚀 Setting default family members...');

    // Open a new transaction for writing
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);

    // Get existing members to avoid duplicates
    const existingMembers = await store.getAll();
    const existingNames = new Set(existingMembers.map((member) => member.name));

    // Add only missing family members
    for (const member of FAMILY_MEMBERS) {
      if (!existingNames.has(member)) {
        await store.add({ name: member, image: null });
      }
    }

    // Mark that default members have been set
    await store.put({ id: 'defaultSetFlag', isSet: true });

    await tx.done;
    console.log('🎉 Default family members have been set successfully!');

    // Refresh the local list of members (for the modal)
    await fetchImages();
  };

  const resetFamilyMembersOrder = async () => {
    const db = await openDatabase();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);

    console.log('🔄 Resetting family members order...');

    // Delete all existing family members
    await store.clear();
    console.log('🗑️ Cleared all existing family members.');

    // Reinsert family members in the original order
    for (const member of FAMILY_MEMBERS) {
      await store.add({ name: member, image: null });
    }
    console.log('✅ Reinserted family members in original order.');

    // Set default value flag to true
    await store.put({ id: 'defaultSetFlag', isSet: true });
    console.log('🚀 Default family members flag updated.');

    await tx.done;

    // Refresh the local list of members (for the modal)
    await fetchImages();
    console.log('🔄 Refreshed the local list of family members.');
  };

  // ----------------------------------------
  // Modal Opening / Closing
  // ----------------------------------------
  const openModalForAdd = async () => {
    // This modal is for batch adding
    setIsEditing(false);
    setEditMemberId(null);
    setNewMember({ name: '', image: null });
    setSelectedMembers([]);
    await fetchImages();
    setShowModal(true);
  };

  const openModalForEdit = async (member) => {
    // This modal is for editing a single existing record
    setIsEditing(true);
    setEditMemberId(member.id || null);
    // Pre-fill the form with existing data
    setNewMember({ name: member.name, image: member.image });
    setSelectedMembers([]);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    // Reset states for safety
    setIsEditing(false);
    setEditMemberId(null);
    setNewMember({ name: '', image: null });
    setSelectedMembers([]);
  };

  // ----------------------------------------
  // Handling Image Upload
  // ----------------------------------------
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewMember((prev) => ({ ...prev, image: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  // ----------------------------------------
  // Adding Members (Batch)
  // ----------------------------------------
  const handleAddMember = () => {
    if (!newMember.name || !croppedImage) return; // Ensure a valid entry

    let newName = newMember.name;
    const existingCount = modalMembers.filter((member) =>
      member.name.startsWith(newName)
    ).length;

    // If the name already exists, append a unique number
    if (existingCount > 0) {
      newName = `${newMember.name} (${existingCount + 1})`;
    }

    setSelectedMembers((prev) => {
      const updatedMembers = [
        ...prev,
        { ...newMember, name: newName, image: croppedImage },
      ];
      return updatedMembers;
    });

    // Reset input states & dropdown
    setNewMember({ name: '', image: null });
    const dropdown = document.getElementById('family-member-select');
    if (dropdown) dropdown.selectedIndex = 0;
  };

  const handleBatchSave = async () => {
    if (selectedMembers.length === 0) {
      return;
    }

    const db = await openDatabase();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);

    for (const member of selectedMembers) {
      await store.add(member);
    }
    await tx.done;

    // Refresh the local list of members (for the modal)
    await fetchImages();

    // Clear the selected members
    setSelectedMembers([]);
  };

  // ----------------------------------------
  // Editing an Existing Member
  // ----------------------------------------
  const handleUpdate = async () => {
    // If the user didn't pick a name or image, skip
    if (!newMember.name || !croppedImage || !editMemberId) return;

    const db = await openDatabase();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);

    // Put overwrites the object with the matching key
    await store.put({
      id: editMemberId,
      name: newMember.name,
      image: croppedImage,
    });

    await tx.done;

    // Fetch updated data
    await fetchImages();

    // Reset after editing
    setIsEditing(false);
    setEditMemberId(null);
    setNewMember({ name: '', image: null });

    closeModal();
  };

  // ----------------------------------------
  // Rendering the Modal
  // ----------------------------------------
  const renderModalBody = () => {
    if (isEditing) {
      // ==========================
      // ✏️ Editing a single member
      // ==========================
      return (
        <Form>
          <h5 className="text-primary">✏️ Edit Family Member</h5>
          <Form.Group>
            <Form.Label>📝 Family Member Name</Form.Label>
            <Form.Control
              type="text"
              value={newMember.name}
              onChange={(e) =>
                setNewMember((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Label>📸 Upload New Image</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
            />
          </Form.Group>
          {newMember.image ? (
            <div
              style={{ position: 'relative', width: '100%', height: '200px' }}
            >
              <Cropper
                image={newMember.image}
                crop={crop}
                zoom={zoom}
                aspect={4 / 3}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={handleCropComplete}
              />
            </div>
          ) : null}
          <Button className="mt-3" variant="success" onClick={handleUpdate}>
            ✅ Update Member
          </Button>
        </Form>
      );
    } else {
      // ==========================
      // ➕ Batch-adding members
      // ==========================
      return (
        <Form>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <h5 className="mb-0">➕ Add a New Family Member 👨‍👩‍👧‍👦</h5>
            <Button variant="danger" onClick={resetFamilyMembersOrder}>
              🔄 Re-Set Members
            </Button>
          </div>

          <Form.Group>
            <Form.Label>👤 Select Family Member</Form.Label>
            <Form.Control
              as="select"
              id="family-member-select"
              onChange={(e) =>
                setNewMember({ ...newMember, name: e.target.value })
              }
            >
              <option value="">🔽 Choose a member</option>
              {FAMILY_MEMBERS.map((member, index) => (
                <option key={index} value={member}>
                  {member} {index % 2 === 0 ? '👨' : '👩'}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label>📷 Upload Image</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
            />
          </Form.Group>

          {newMember.image ? (
            <div
              style={{ position: 'relative', width: '100%', height: '200px' }}
            >
              <Cropper
                image={newMember.image}
                crop={crop}
                zoom={zoom}
                aspect={4 / 3}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={handleCropComplete}
              />
            </div>
          ) : (
            <p className="text-center text-muted mt-2">🖼️ No image selected</p>
          )}

          <Button
            className="mt-3 me-2"
            variant="secondary"
            onClick={handleAddMember}
          >
            ➕ Add Another Member
          </Button>
          <Button className="mt-3" variant="primary" onClick={handleBatchSave}>
            💾 Save Members
          </Button>

          {selectedMembers.length > 0 && (
            <p className="mt-3 text-success">
              ✅ You have {selectedMembers.length} member(s) ready to save.
            </p>
          )}
        </Form>
      );
    }
  };

  // ----------------------------------------
  // Rendering the Gallery in Modal
  // ----------------------------------------
  const renderModalGalleryWithEdit = () => {
    return (
      <div style={{ maxHeight: '30vh', overflowY: 'auto' }}>
        <Row className="mt-4">
          {modalMembers.map((member, index) => (
            <Col key={index} md={4} sm={6} className="mb-3">
              <Card className="position-relative shadow-lg border-0">
                {/* Member Name Overlay */}
                <div
                  className="position-absolute top-0 start-0 w-100 text-center py-2 rounded-top"
                  style={{
                    background: 'rgba(0, 0, 0, 0.7)', // Dark semi-transparent background
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    textShadow: '1px 1px 4px rgba(0, 0, 0, 0.5)',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                  }}
                >
                  {member.name} {member.image ? '📸' : '❌'}
                </div>

                {/* Member Image or Placeholder */}
                {member.image ? (
                  <Card.Img
                    variant="top"
                    src={member.image}
                    alt={member.name}
                    className="rounded"
                    style={{
                      width: '100%',
                      height: '20vh',
                      objectFit: 'cover',
                    }}
                  />
                ) : (
                  <div
                    className="d-flex align-items-center justify-content-center text-muted bg-light"
                    style={{ width: '100%', height: '20vh' }}
                  >
                    📷 No Image Available
                  </div>
                )}

                {/* Action Buttons */}
                <div className="d-flex justify-content-around p-2 bg-light rounded-bottom">
                  <Button
                    variant="info"
                    className="d-flex align-items-center"
                    onClick={() => openModalForEdit(member)}
                  >
                    ✏️ Edit
                  </Button>
                  <Button
                    variant="danger"
                    className="d-flex align-items-center"
                    onClick={() => deleteMember(member.id)}
                  >
                    🗑️ Delete
                  </Button>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  // ----------------------------------------
  // Rendering the Main Component
  // ----------------------------------------
  return (
    <Container className="mt-4 text-center">
      {/* Add/Update Button with Better Spacing */}
      <div className="d-flex flex-wrap gap-3 justify-content-center my-3">
        {/* Add or Update Family Member */}
        <Button
          variant="primary"
          className="px-4 py-2 fw-bold shadow rounded-pill d-flex align-items-center"
          onClick={openModalForAdd}
          style={{ fontSize: '1rem', display: 'flex', gap: '8px' }}
        >
          🏡 <span>Add / Update Family Member ✏️</span>
        </Button>

        {/* Import Family Members */}
        <Button
          variant="info"
          className="px-4 py-2 fw-bold shadow rounded-pill d-flex align-items-center text-white"
          onClick={triggerImport}
          style={{
            fontSize: '1rem',
            display: 'flex',
            gap: '8px',
            backgroundColor: '#17a2b8',
            border: 'none',
          }}
        >
          📥 <span>Import My Family Members</span>
        </Button>

        {/* Export Family Members */}
        <Button
          variant="warning"
          className="px-4 py-2 fw-bold shadow rounded-pill d-flex align-items-center text-dark"
          onClick={exportMyFamilyImages}
          style={{
            fontSize: '1rem',
            display: 'flex',
            gap: '8px',
            backgroundColor: '#ffc107',
            border: 'none',
          }}
        >
          📤 <span>Export My Family Members</span>
        </Button>

        {/* Import Status Message */}
        {importStatus && (
          <p className="text-center fw-bold mt-2">{importStatus}</p>
        )}
      </div>

      {/* Main Modal: for both adding and editing */}
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton className="bg-light">
          <Modal.Title className="fw-bold text-primary">
            {isEditing
              ? '✏️ Edit Family Member'
              : '➕ Add or Update Family Members'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-4">
          <h5 className="pt-3 text-info">
            🛠️ Modify the default family members as needed 👨‍👩‍👧‍👦
          </h5>

          {renderModalGalleryWithEdit()}

          <hr className="my-3" />

          <div className="mb-4">{renderModalBody()}</div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default FamilyGallery;
