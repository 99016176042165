import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getAllSchools } from '../db/indexedDB.js';
import { schoolDetails } from '../ssc/data/schools.js';

const SubjectComponent = () => {
  const { schoolName, className, subjectName } = useParams(); // Get selected school, class & subject from URL
  const navigate = useNavigate();
  const [dbLessons, setDbLessons] = useState([]);

  useEffect(() => {
    fetchSubjectLessons();
  }, [schoolName, className, subjectName]);

  const fetchSubjectLessons = async () => {
    const data = await getAllSchools();
    const schoolFromDB = data.find((s) => s.schoolName === schoolName);
    const classFromDB = schoolFromDB?.classes.find(
      (c) => c.className === className
    );
    const subjectFromDB = classFromDB?.subjects.find(
      (s) => s.subjectName === subjectName
    );
    setDbLessons(subjectFromDB ? subjectFromDB.lessons : []);
  };

  // Find static subject data
  const staticSchoolData = schoolDetails.find(
    (s) => s.schoolName === schoolName
  );
  const staticClassData = staticSchoolData?.classes.find(
    (c) => c.className === className
  );
  const staticSubjectData = staticClassData?.subjects.find(
    (s) => s.subjectName === subjectName
  );
  const staticLessons = staticSubjectData ? staticSubjectData.lessons : [];

  // Combine static and dynamic lessons
  const allLessons = [...staticLessons, ...dbLessons];

  return (
    <Container className="py-4">
      {/* Back Button */}
      <Button
        onClick={() => navigate(`/kids/ssc/${schoolName}/${className}`)}
        variant="secondary"
        className="mb-3"
      >
        🔙 Back to {className} Subjects
      </Button>

      {/* Add Lesson Data Button */}
      <Button
        onClick={() =>
          navigate(
            `/kids/ssc/${schoolName}/${className}/${subjectName}/add-lesson`
          )
        }
        variant="primary"
        className="mb-3 ms-2"
      >
        ➕ Add Lesson
      </Button>

      <h2 className="text-center mb-4">{subjectName} - Choose a Lesson</h2>

      <Row className="justify-content-center">
        {allLessons.length > 0 ? (
          allLessons.map(({ lessonName, color }, index) => (
            <Col xs={6} sm={4} md={3} className="mb-4 text-center" key={index}>
              <Card
                className="p-3 shadow-sm cursor-pointer"
                onClick={() =>
                  navigate(
                    `/kids/ssc/${schoolName}/${className}/${subjectName}/${lessonName}`
                  )
                }
                style={{ backgroundColor: color, cursor: 'pointer' }}
              >
                <Card.Body>
                  <h3 className="fw-bold">{lessonName}</h3>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <p className="text-center">No lessons available. Add a new one!</p>
        )}
      </Row>
    </Container>
  );
};

export default SubjectComponent;
