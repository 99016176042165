export const questionsAndAnswersLessonOne = [
  {
    question: 'What is a family?',
    answer:
      'Family is a basic unit of society where parents and their children live together.',
  },
  {
    question: 'What is a nuclear family?',
    answer:
      'Parents living with one or two children is called a nuclear family.',
  },
  {
    question: 'How do grandparents interact with children in a joint family?',
    answer:
      'In a joint family grandparents: \n1. Children many good things that round stories.\n2. Help them to solve their problems.',
  },
];

export const oneLineAnswersLessonOne = [
  {
    answer: 'Exogamy - Marriage outside one family or caste is called exogamy.',
  },
  {
    answer:
      'Endogamy - Marriage within one family or caste is called endogamy.',
  },
];
