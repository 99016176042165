import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Card } from 'react-bootstrap';
import { SchoolActivityContentSplitter } from '../../enums/constants.js';

function isMobileDevice() {
  if (typeof navigator === 'undefined') return false;
  return /Mobi|Android/i.test(navigator.userAgent);
}

const DefineTermSpeakingActivity = ({ activityData = null }) => {
  const [userInput, setUserInput] = useState('Answer: ' + activityData?.answer);

  // eslint-disable-next-line no-unused-vars
  const [language, setLanguage] = useState(activityData?.lang);
  const [voice, setVoice] = useState(null);
  const [preloadedUtterances, setPreloadedUtterances] = useState({});
  const words = userInput.replace(/\n/g, ' ').trim().split(' ');
  const [predictedParagraph, setPredictedParagraph] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentWordSet, setCurrentWordSet] = useState([]);
  const [isStarted, setIsStarted] = useState(false);
  const [isRecollecting, setIsRecollecting] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isSpeaking, setSpeaking] = useState(false);

  // NEW: Track auto-playing
  const [isAutoPlaying, setIsAutoPlaying] = useState(false);

  const synth = window.speechSynthesis;
  const mobile = isMobileDevice();

  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = synth.getVoices();
      if (availableVoices.length > 0) {
        // Map the language to a locale code if needed
        const languageMap = {
          English: 'en-US',
          Hindi: 'hi-IN',
          Telugu: 'te-IN',
          Tamil: 'ta-IN',
        };
        // Use the mapped value or the language directly (or default to 'en-US')
        const selectedLanguage = languageMap[language] || language || 'en-US';
        const defaultVoice = availableVoices.find(
          (v) => v.lang === selectedLanguage
        );
        setVoice(defaultVoice || availableVoices[0]);
      }
    };

    loadVoices();
    synth.onvoiceschanged = loadVoices;

    return () => {
      synth.onvoiceschanged = null;
    };
  }, [language]);

  useEffect(() => {
    if (isStarted || isRecollecting || isAutoPlaying) {
      // Preload for the first (or current) index whenever we start or recollect
      preloadUtterances(currentWordIndex);
    }
  }, [isStarted, isRecollecting, isAutoPlaying]);

  // NEW: Auto-play effect
  // Whenever `isAutoPlaying` is true, and we are not currently speaking,
  // automatically select the correct word until the content is completed.
  useEffect(() => {
    if (
      isAutoPlaying &&
      isStarted &&
      !isSpeaking &&
      currentWordIndex < words.length
    ) {
      // Automatically "click" the correct word
      handleWordClick(words[currentWordIndex]);
    }
  }, [isAutoPlaying, isSpeaking, currentWordIndex, isStarted, words]);

  const preloadUtterances = (startIndex) => {
    const newUtterances = {};
    // Load a few words ahead (here just 1 for demonstration)
    for (let i = startIndex; i < startIndex + 1 && i < words.length; i++) {
      const utterance = new SpeechSynthesisUtterance(words[i]);
      utterance.lang = language;
      utterance.voice = voice;

      // Recommended Speech Rate for Kids:
      // Toddlers (2-4 years old): 0.5 - 0.7 (Slower to help with word recognition)
      // Young Kids (5-7 years old): 0.6 - 0.8 (Still slower but more natural)
      // Older Kids (8-12 years old): 0.8 - 1.0 (Closer to normal speech speed)
      // Teenagers (13+ years old): 1.0 - 1.2 (Near or slightly above normal speed)

      utterance.rate = 0.7; // Slower but clear

      newUtterances[i] = utterance;
    }
    setPreloadedUtterances((prev) => ({ ...prev, ...newUtterances }));
  };

  const speakText = (textIndex, callback) => {
    // Set speaking status to true
    setSpeaking(true);

    // Cancel any ongoing speech first
    if (synth.speaking) {
      synth.cancel();
    }

    const utterance = preloadedUtterances[textIndex];
    if (utterance) {
      utterance.onend = () => {
        setSpeaking(false);
        if (callback) callback();
      };
      utterance.onerror = (event) => {
        console.error('Speech synthesis error:', event.error);
        setSpeaking(false);
        if (callback) callback();
      };
      synth.speak(utterance);
    } else {
      console.error('No utterance found for index:', textIndex);
      setSpeaking(false);
      if (callback) callback();
    }
  };

  const generateWordSet = (correctIndex) => {
    const correctWord = words[correctIndex];

    const randomWords = words.filter(
      (w, index) =>
        index !== correctIndex && w !== correctWord && w.trim() !== '' // Exclude empty or whitespace-only strings
    );

    const distractors = randomWords.sort(() => Math.random() - 0.5).slice(0, 2);

    return [correctWord, ...distractors].sort(() => Math.random() - 0.5);
  };

  const startGame = () => {
    if (synth.speaking) synth.cancel();
    setIsCompleted(false);
    setIsStarted(true);
    setIsRecollecting(false);
    setIsAutoPlaying(false); // Make sure auto-play is off if user chooses normal start
    setCurrentWordIndex(0);
    preloadUtterances(0);
    setCurrentWordSet(generateWordSet(0));
  };

  const resetGame = () => {
    if (synth.speaking) synth.cancel();
    setIsStarted(false);
    setIsRecollecting(false);
    setIsCompleted(false);
    setIsAutoPlaying(false);
    setPredictedParagraph([]);
    setCurrentWordIndex(0);
    setCurrentWordSet([]);
    setPreloadedUtterances({});
  };

  const startRecollection = () => {
    if (synth.speaking) synth.cancel();
    setIsCompleted(false);
    setIsRecollecting(true);
    setIsStarted(false);
    setIsAutoPlaying(false);
    setCurrentWordIndex(0);
    preloadUtterances(0);
    setCurrentWordSet(generateWordSet(0));
  };

  // NEW: Auto start game with auto-playing set to true
  const autoStartGame = () => {
    if (synth.speaking) synth.cancel();
    setIsCompleted(false);
    setIsStarted(true);
    setIsRecollecting(false);
    setIsAutoPlaying(true); // Turn on auto-play
    setCurrentWordIndex(0);
    preloadUtterances(0);
    setCurrentWordSet(generateWordSet(0));
  };

  // Handle word selection (manual or auto)
  const handleWordClick = (word) => {
    const correctWord = words[currentWordIndex];
    if (word === correctWord) {
      speakText(currentWordIndex, () => {
        if (correctWord.trim() !== '') {
          setPredictedParagraph((prev) => [...prev, correctWord]);
        }
        const nextIndex = currentWordIndex + 1;

        if (nextIndex < words.length) {
          setCurrentWordIndex(nextIndex);
          setCurrentWordSet(generateWordSet(nextIndex));
          preloadUtterances(nextIndex);
        } else {
          setCurrentWordSet([]);
          setIsCompleted(true);
          setIsStarted(false);
          setIsAutoPlaying(false); // Stop auto-playing if we reached the end
        }
      });
    }
  };

  // Format predicted paragraph to match original content line breaks
  const formatPredictedParagraph = () => {
    console.log(`userInput: ${userInput}`);
    const originalLines = userInput.split('\n');
    console.log(`Original Lines: ${JSON.stringify(originalLines, null, 4)}`);

    // predictedParagraph is an array of correct words set by handleWordClick.
    let predictedIndex = 0;

    const formattedParagraph = originalLines
      .map((line) => {
        // If the line is completely empty or just spaces, keep it as is.
        if (line.trim() === '') {
          return '';
        }

        // Tokenize the line, preserving spaces.
        const tokens = line.match(/\s+|\S+/g) || [];

        // Count the number of word tokens (non-whitespace tokens) in this line.
        const wordCount = tokens.filter((token) => !/\s+/.test(token)).length;

        // Slice out exactly the words for this line from predictedParagraph.
        const linePredictedTokens = predictedParagraph.slice(
          predictedIndex,
          predictedIndex + wordCount
        );

        // Advance the global index by the number of words in the line.
        predictedIndex += wordCount;

        // Replace non-space tokens with the predicted tokens (if available).
        let localWordIndex = 0;
        const formattedLine = tokens
          .map((token) => {
            if (/\s+/.test(token)) {
              // Preserve whitespace exactly.
              return token;
            } else {
              const replacement = linePredictedTokens[localWordIndex++] || '';
              return replacement;
            }
          })
          .join('');

        return formattedLine;
      })
      .join('\n');

    console.log(`\nFinal Speaking formattedParagraph:\n${formattedParagraph}`);
    return formattedParagraph;
  };

  const formatParagraph = () => {
    return userInput
      .split('\n')
      .map((line) => {
        if (line.includes(SchoolActivityContentSplitter.QA_ACTIVITY_SPLITTER)) {
          const [questionPart, answerPart] = line.split(
            SchoolActivityContentSplitter.QA_ACTIVITY_SPLITTER
          );
          return `${questionPart},\nAnswer${answerPart}`;
        }
        return line;
      })
      .join('\n');
  };

  return (
    <Container
      fluid
      style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}
    >
      {/* Content Input Section */}
      {!isStarted && !isRecollecting && (
        <Row className="mb-4">
          <Col md={{ span: 8, offset: 2 }}>
            <Card>
              <Card.Header as="h4">📝 Practice Question Answer:</Card.Header>
              <Card.Body>
                <Form.Group controlId="userInput">
                  <Form.Control
                    as="textarea"
                    rows={5}
                    value={formatParagraph()} // ✅ Now correctly formatted as text
                    onChange={(e) => setUserInput(e.target.value)}
                    disabled={isStarted || isRecollecting}
                    readOnly={true} // Makes the textarea uneditable
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {/* Game Controls */}
      <Row className="mb-4 text-center">
        <Col>
          {!isStarted && !isRecollecting && (
            <>
              <Button onClick={startGame} variant="primary" className="me-2">
                🚀 Start
              </Button>
              {/* NEW: Auto Start Button */}
              <Button
                onClick={autoStartGame}
                variant="success"
                className="me-2"
              >
                ⚡ Auto Start
              </Button>
              <Button
                onClick={startRecollection}
                variant="info"
                className="me-2"
              >
                🔄 Recollect
              </Button>
            </>
          )}
          {(isStarted || isRecollecting || isAutoPlaying) && (
            <Button onClick={resetGame} variant="danger" className="me-2">
              🔁 Reset
            </Button>
          )}
        </Col>
      </Row>

      {/* Active Game Section */}
      {(isStarted || isRecollecting) && (
        <>
          {/* Predicted Paragraph */}
          <Row className="mb-4">
            <Col md={{ span: 8, offset: 2 }}>
              <Card>
                <Card.Body>
                  <Form.Group controlId="predictedParagraph">
                    <Form.Control
                      as="textarea"
                      rows={5}
                      value={formatPredictedParagraph()} // ✅ Formatted as text
                      disabled={isStarted || isRecollecting}
                      readOnly={true} // Makes the textarea uneditable
                    />
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Word Buttons */}
          {!isCompleted && (
            <Row className="justify-content-center">
              <Col xs="auto">
                <Card>
                  <Card.Body
                    style={{
                      display: 'flex',
                      gap: '10px',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                    }}
                  >
                    {currentWordSet.map((word, index) => (
                      <Button
                        key={index}
                        variant="light"
                        style={{
                          padding: '10px',
                          borderRadius: '5px',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                          color: isRecollecting
                            ? isSpeaking && word === words[currentWordIndex]
                              ? 'white'
                              : 'inherit'
                            : word === words[currentWordIndex]
                              ? 'white' // Green when not recollecting and the word is correct
                              : 'inherit', // Inherit for all other cases
                          backgroundColor: isRecollecting
                            ? isSpeaking && word === words[currentWordIndex]
                              ? 'green'
                              : 'inherit'
                            : word === words[currentWordIndex]
                              ? 'green' // Green when not recollecting and the word is correct
                              : 'inherit', // Inherit for all other cases
                        }}
                        disabled={isSpeaking || isAutoPlaying}
                        // For mobile browsers: onClick
                        onClick={
                          mobile ? () => handleWordClick(word) : undefined
                        }
                        // For desktop browsers: onMouseEnter
                        onMouseEnter={
                          !mobile ? () => handleWordClick(word) : undefined
                        }
                      >
                        {word}
                      </Button>
                    ))}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </>
      )}

      {/* Completion Message */}
      {isCompleted && (
        <Row className="mt-4">
          <Col md={{ span: 8, offset: 2 }}>
            <Card className="text-center">
              <Card.Body>
                <h4 className="text-success">
                  🎉 Great job! You&apos;ve completed the{' '}
                  {isRecollecting ? (
                    <b>🔄 recollection</b>
                  ) : (
                    <b>🧠 memorization</b>
                  )}
                  !
                </h4>
                <h5 className="text-info">
                  ✍️ Try again to improve your
                  {isRecollecting ? (
                    <b> 🔄 recollection</b>
                  ) : (
                    <b> 🧠 memorization</b>
                  )}
                  !
                </h5>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default DefineTermSpeakingActivity;
