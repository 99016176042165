import { class3Subjects } from '../subjects/class3Subjects.js';
import { class5Subjects } from '../subjects/class5Subjects.js';
import { class6Subjects } from '../subjects/class6Subjects.js';

export const stJosephsHighSchoolClasses = [
  {
    className: 'Class 3',
    color: '#FFD700',
    subjects: class3Subjects,
  },
  {
    className: 'Class 5',
    color: '#FFD700',
    subjects: class5Subjects,
  },
  {
    className: 'Class 6',
    color: '#FFD700',
    subjects: class6Subjects,
  },
];
