export const questionsAndAnswersNDLessonFive = [
  {
    question: 'Why did the boys go to the smaller tavern?',
    answer:
      'The boys assumed that the money was hidden in the smaller tavern. So, to acquire the same, they went to the smaller tavern.',
  },
  {
    question: 'Describe what happened to Tom on Thursday.',
    answer:
      'On Thursday night, Huck stood guarding while Tom crept towards the tavern in the dark. He disappeared for a long time and later returned with a flash of light, tearing down the alley shouting, "Run for your life!"',
  },
  {
    question: 'What did both Tom and Huck finally decide to do?',
    answer:
      'The boys sensed danger in the tavern. So, they decided to keep a watch on the tavern every night till Injun Joe, the Spaniard, left the room.',
  },
  {
    question:
      'Why did the treasure and Injun Joe become less important for Tom?',
    answer:
      "When Tom heard that Judge Thatcher's family had come back to town, the treasure and Injun Joe became less important for him.",
  },
  {
    question: 'Where had the two men gone? What did they want to do?',
    answer:
      'The two men moved up the river street three blocks and took a path that led up Cardiff Hill. They wanted to harm Widow Douglas.',
  },
  {
    question: 'Why had the two men wanted to harm the widow?',
    answer:
      "Widow Douglas's husband had once jailed Injun Joe for living like a tramp and also got him horsewhipped in front of the whole town. So, Injun Joe and his partner wished to inflict all sorts of torture on Widow Douglas as revenge.",
  },
  {
    question: 'What did Tom do when he heard a far-off shout?',
    answer:
      "Tom held Becky's hand slowly and joyfully started walking towards the sound in the hope of meeting his friends again.",
  },
  {
    question: 'How did Tom find a way out of the cave?',
    answer:
      'While bravely exploring the passages to find a way out of the cave, Tom sighted a small spot that looked like daylight. As he pushed himself through the hole, he found the Mississippi rolling by. Thus, he found a way out of the cave.',
  },
];
