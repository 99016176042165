export const questionsAndAnswersNDLessonThree = [
  {
    question: "What was the effect of the medicine on Peter, Aunt Polly's cat?",
    answer:
      "When Tom opened the cat's mouth and poured down the medicine, Peter, the cat, sprang a couple of yards in the air, let off a whoop, and began running round and round the room, banging against furniture and upsetting the flower pots. He did double somersaults and flew through the open window.",
  },
  {
    question:
      'Aunt Polly did not scold Tom even when she found him laughing at the behaviour of the cat. Why?',
    answer:
      'The old lady stood amazed while Tom lay on the floor laughing his heart out at the cat. Aunt Polly did not scold Tom as he made her realize how strong the medicine was, and she felt sorry for making him drink it.',
  },
  {
    question:
      'Why was Joe Harper also feeling miserable? Who else joined them in this adventure?',
    answer:
      'Joe Harper was as miserable as Tom because his mother had beaten him for drinking some cream, which he had not even tasted. Huckleberry Finn joined them in this adventure.',
  },
  {
    question: 'Where did they decide to go?',
    answer:
      'Three miles below St. Petersburg, there was a long, narrow, wooded island in the Mississippi River. It was called Jackson Island, and it was uninhabited. This was the place where they decided to go.',
  },
  {
    question:
      'How did the boys feel when they realized that the shots were fired for them?',
    answer:
      'The boys felt like heroes in an instant. They were missed. People mourned them. People shed tears over them. And best of all, they were the talk of the town. They felt it was worthwhile to be a pirate, after all.',
  },
  {
    question: 'Describe the dream Tom told Aunt Polly.',
    answer:
      "When Aunt Polly asked Tom why he hadn't given her a hint that he was alive, Tom, instead of telling the truth, made up a story about a dream he had on Jackson Island. He said that on Wednesday night he had dreamt about his aunt, Mary, Sid, and Joe's mother sitting there and crying. Actually, he had witnessed this scene when he had secretly come home that Wednesday.",
  },
];
