export const questionsAndAnswersLessonEleven = [
  {
    question: 'According to the speaker, what has vanished from his life?',
    answer:
      'According to the speaker, old memories have vanished from his life.',
  },
  {
    question: 'How does the speaker describe his future?',
    answer:
      'The speaker describes his future as being as new as a clean slate.',
  },
  {
    question: 'What does the speaker say about his future plans?',
    answer:
      'The speaker states that now he is free from all boundaries of the past, he will start his life in a new manner. He is determined to perform his best to achieve his goal.',
  },
  {
    question: 'Whom and where does the speaker want to greet first?',
    answer: 'The speaker wants to greet his new friends at his welcoming ball.',
  },
  {
    question: 'What does the speaker want to forget while making a new start?',
    answer:
      'The speaker wants to forget his past memories while making a new start, and he wants to dedicate himself to working for mankind.',
  },
];
