export const questionsAndAnswersNDLessonFour = [
  {
    question: 'Why did Tom come home in a "dreary mood"?',
    answer:
      "Tom came home in a dreary mood as he came to know Becky's decision to get him whipped by the professor and hate him forever.",
  },
  {
    question: 'Why did Aunt Polly forgive Tom in the end?',
    answer:
      "At the end, Aunt Polly forgave Tom as she had found the proof of Tom's story—the bark. It had a message for her that Tom was still alive.",
  },
  {
    question: 'Why was Tom light-hearted when he went to school again?',
    answer:
      "Aunt Polly's manner and her affection towards Tom lifted his spirits and made him light-hearted and happy.",
  },
  {
    question: 'What did Tom remember when he went to bed?',
    answer:
      'The night when Tom was severely beaten and detained by Mr. Dobbins, Tom remembered Becky\'s last words, "Tom, how could you be so noble!" and went to bed.',
  },
  {
    question: 'What did they do to show their concern for Potter?',
    answer:
      'To show their concern for Potter, Huck and Tom went to the cell and gave him some tobacco and matches. They also wished they could get him out of the cell.',
  },
  {
    question:
      'Who was the new witness on the day of the verdict? Why was he called by the Lawyer?',
    answer:
      'On the day of the verdict, Tom Sawyer was the new witness. He was called by the lawyer as he wanted to change Potter\'s plea to "not guilty."',
  },
  {
    question: 'Where did Tom and Huck go to hunt for the treasure? Why?',
    answer:
      'Tom and Huck went to the haunted house up on the Still-House Hill because they felt that treasure is mostly hidden under the floor of haunted houses.',
  },
  {
    question:
      'Whom did they see in the house on Cardiff Hill? What were they doing there?',
    answer:
      'Tom and Huck saw the deaf and dumb Spaniard and Injun Joe. They were digging the ground to search for some treasure.',
  },
  {
    question: 'What resolution did Tom and Huck make?',
    answer:
      'Tom and Huck made a resolution to keep a watch on the Spaniard and follow him to Number Two, wherever that might be.',
  },
];
