export const questionsAndAnswersLessonEighteen = [
  {
    question: 'Why did Sinbad leave Baghdad?',
    answer:
      'Sinbad left Baghdad because he wished to see more countries and cities, and to buy and sell in faraway countries.',
  },
  {
    question: 'How did Sinbad get stranded on an island?',
    answer:
      'Sinbad was stranded on an island after he went to explore it and fell asleep by mistake.',
  },
  {
    question: 'How did Sinbad escape from the island?',
    answer:
      "Sinbad found a big roc on the island. He waited for the bird to fall asleep in its nest. Then, he tied one end of his turban around the bird's leg and held on to the other end. When the bird flew into the sky, it took Sinbad with it. This was how he escaped from the island.",
  },
  {
    question: 'How did Sinbad escape from the valley of diamonds?',
    answer:
      'Sinbad tied one end of his turban around himself and the other end around a piece of meat in the valley. One of the birds flew down, picked up the meat, and flew out of the valley with it and Sinbad. This was how he escaped from the valley of diamonds.',
  },
  {
    question: 'How did Sinbad become a very rich man in the end?',
    answer:
      'Before he escaped from the valley, Sinbad had filled his bag with many big diamonds. After he escaped, the merchants helped him sell all his diamonds, and so, he became a very rich man.',
  },
];
