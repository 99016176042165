export const questionsAndAnswersNDLessonOne = [
  {
    question:
      'How many March girls were there and who was the prettiest among them?',
    answer:
      'There were four March girls, and Margaret, the eldest, who was 16, was the prettiest among them.',
  },
  {
    question: 'Why was their father away?',
    answer:
      'Their father was far away, serving as a Chaplain in the army at Washington.',
  },
  {
    question: 'Who was Mrs. March?',
    answer:
      'Mrs. March, the mother of the four March girls, was a social worker who spent her time helping the poor and the needy.',
  },
  {
    question:
      'Describe the presents Mrs. March received from each of her daughters.',
    answer:
      'Mrs. March received a pair of gloves, slippers, handkerchiefs, and a bottle of cologne (perfume) from each of her daughters.',
  },
  {
    question: 'Who sent them a sumptuous supper and why?',
    answer:
      'Old Mr. Lawrence, the Lawrence boy’s grandfather, sent them a sumptuous supper as he was pleased to know how they had offered their breakfast and helped the poor woman.',
  },
  {
    question: 'What made Jo say that she always spoiled things?',
    answer:
      "Jo was helping Meg curl her hair with a pair of hot tongs. However, the tongs were too hot, and Meg's hair came out with the papers. This made Jo say that she always spoiled things.",
  },
];
