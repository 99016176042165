import { class1Subjects } from '../subjects/class1Subjects.js';
import { class2Subjects } from '../subjects/class2Subjects.js';
import { class5Subjects } from '../subjects/class5Subjects.js';

export const greenwoodClasses = [
  {
    className: 'Class 1',
    color: '#FFDDC1',
    subjects: class1Subjects,
  },
  {
    className: 'Class 2',
    color: '#FFD700',
    subjects: class2Subjects,
  },
  {
    className: 'Class 5',
    color: '#FFD700',
    subjects: class5Subjects,
  },
];
