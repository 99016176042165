export const teluguAlphabet = [
  'అ',
  'ఆ',
  'ఇ',
  'ఈ',
  'ఉ',
  'ఊ',
  'ఋ',
  'ఎ',
  'ఏ',
  'ఐ',
  'ఒ',
  'ఓ',
  'ఔ',
  'అం',
  'అః',
];

export const teluguConsonants = [
  'క',
  'ఖ',
  'గ',
  'ఘ',
  'ఙ',
  'చ',
  'ఛ',
  'జ',
  'ఝ',
  'ఞ',
  'ట',
  'ఠ',
  'డ',
  'ఢ',
  'ణ',
  'త',
  'థ',
  'ద',
  'ధ',
  'న',
  'ప',
  'ఫ',
  'బ',
  'భ',
  'మ',
  'య',
  'ర',
  'ల',
  'వ',
  'శ',
  'ష',
  'స',
  'హ',
  'ళ',
  'క్ష',
  'ఱ',
];
