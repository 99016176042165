export const questionsAndAnswersNDLessonThree = [
  {
    question: "What happened to the king's children? Who invited Meg?",
    answer:
      "The king's children were sick with measles. Since Meg was free, she was invited by her new friend, Annie Moffat, whom she met at the New Year's Eve dance. Annie invited Meg to spend two weeks with her.",
  },
  {
    question: 'What did Meg confess to Mrs. March?',
    answer:
      "Meg confessed to Mrs. March that she had given in to vanity at the Moffats' party. She wore a blue dress given by Belle Moffat instead of her old white dress.",
  },
  {
    question: 'Why did Mrs. March regret allowing Meg to go to Moffat’s house?',
    answer:
      "Mrs. March regretted allowing Meg to stay at the Moffats' house because, although they were kind, they were also vulgar and ill-bred, which could have had a negative influence on Meg.",
  },
  {
    question: 'Describe the Pickwick Club.',
    answer:
      'The Pickwick Club was a secret society and newsletter-writing club where the girls contributed articles, stories, and poetry.',
  },
  {
    question: 'Why was Jo glad that Laurie did not visit the saloon?',
    answer:
      'Jo was glad that Laurie did not visit the saloon because if he went to the wrong places, her mother would not allow him to visit their home anymore.',
  },
  {
    question:
      "What was Jo's secret which she told Laurie, and what was his reaction?",
    answer:
      'Jo revealed that she had submitted two of her stories to a newspaper editor and was waiting for a response next week. Laurie was delighted upon hearing this and told Jo that she was going to be famous like Shakespeare.',
  },
];
