import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getAllSchools } from '../db/indexedDB.js';
import { schoolDetails } from '../ssc/data/schools.js';

const ClassComponent = () => {
  const { schoolName, className } = useParams(); // Get selected school & class from URL
  const navigate = useNavigate();
  const [dbSubjects, setDbSubjects] = useState([]);

  useEffect(() => {
    fetchClassSubjects();
  }, [schoolName, className]);

  const fetchClassSubjects = async () => {
    const data = await getAllSchools();
    const schoolFromDB = data.find((s) => s.schoolName === schoolName);
    const classFromDB = schoolFromDB?.classes.find(
      (c) => c.className === className
    );
    setDbSubjects(classFromDB ? classFromDB.subjects : []);
  };

  // Find static class data
  const staticSchoolData = schoolDetails.find(
    (s) => s.schoolName === schoolName
  );
  const staticClassData = staticSchoolData?.classes.find(
    (c) => c.className === className
  );
  const staticSubjects = staticClassData ? staticClassData.subjects : [];

  // Combine static and dynamic subjects
  const allSubjects = [...staticSubjects, ...dbSubjects];

  return (
    <Container className="py-4">
      {/* Back Button */}
      <Button
        onClick={() => navigate(`/kids/ssc/${schoolName}`)}
        variant="secondary"
        className="mb-3"
      >
        🔙 Back to {schoolName} Classes
      </Button>

      {/* Add Subject Data Button */}
      <Button
        onClick={() =>
          navigate(`/kids/ssc/${schoolName}/${className}/add-subject`)
        }
        variant="primary"
        className="mb-3 ms-2"
      >
        ➕ Add Subject
      </Button>

      <h2 className="text-center mb-4">{className} - Select a Subject</h2>

      <Row className="justify-content-center">
        <Col xs={6} sm={4} md={3} className="mb-4 text-center">
          <Card
            className="p-3 shadow-sm cursor-pointer"
            onClick={() => {
              navigate(`/kids/ssc/${schoolName}/${className}/textBooks`);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Card.Body>
              <h3 className="fw-bold">Text Books</h3>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="justify-content-center">
        {allSubjects.length > 0 ? (
          allSubjects.map(({ subjectName, color }, index) => (
            <Col xs={6} sm={4} md={3} className="mb-4 text-center" key={index}>
              <Card
                className="p-3 shadow-sm cursor-pointer"
                onClick={() =>
                  navigate(
                    `/kids/ssc/${schoolName}/${className}/${subjectName}`
                  )
                }
                style={{ backgroundColor: color, cursor: 'pointer' }}
              >
                <Card.Body>
                  <h3 className="fw-bold">{subjectName}</h3>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <p className="text-center">No subjects available. Add a new one!</p>
        )}
      </Row>
    </Container>
  );
};

export default ClassComponent;
