import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Card, ListGroup } from 'react-bootstrap';
import {
  getAllSchools,
  getAllClasses,
  addSubject,
  deleteSubject,
} from '../../db/indexedDB.js';

const SSCSubjectManager = () => {
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [subjectName, setSubjectName] = useState('');
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    fetchSchools();
  }, []);

  const fetchSchools = async () => {
    const data = await getAllSchools();
    setSchools(data);
    if (data.length > 0) {
      setSelectedSchool(data[0].schoolName); // Auto-select the first school
      fetchClasses(data[0].schoolName);
    }
  };

  const fetchClasses = async (schoolName) => {
    if (!schoolName) return;
    const data = await getAllClasses(schoolName);
    setClasses(data);
  };

  const handleSchoolChange = (e) => {
    const selectedSchool = e.target.value;
    setSelectedSchool(selectedSchool);
    setSelectedClass('');
    setSubjects([]);
    fetchClasses(selectedSchool);
  };

  const handleClassChange = (e) => {
    const className = e.target.value;
    setSelectedClass(className);

    const selectedClassObj = classes.find((cls) => cls.className === className);
    setSubjects(selectedClassObj ? selectedClassObj.subjects : []);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedSchool || !selectedClass || !subjectName.trim()) return;

    await addSubject(selectedSchool, selectedClass, subjectName);

    // Update subjects state immediately
    setSubjects((prevSubjects) => [
      ...prevSubjects,
      { subjectName, lessons: [] },
    ]);

    setSubjectName('');
  };

  const handleDeleteSubject = async (subjectName) => {
    await deleteSubject(selectedSchool, selectedClass, subjectName);

    // Remove deleted subject from state immediately
    setSubjects((prevSubjects) =>
      prevSubjects.filter((subject) => subject.subjectName !== subjectName)
    );
  };

  return (
    <Container className="py-4">
      <h1 className="text-center mb-4">📖 Manage Subjects</h1>

      {/* Select School */}
      <Card className="p-4 shadow mb-4">
        <Form.Group className="mb-3">
          <Form.Label>Select School</Form.Label>
          <Form.Select onChange={handleSchoolChange} value={selectedSchool}>
            {schools.map((school) => (
              <option key={school.schoolName} value={school.schoolName}>
                {school.schoolName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Card>

      {/* Select Class */}
      {selectedSchool && (
        <Card className="p-4 shadow mb-4">
          <Form.Group className="mb-3">
            <Form.Label>Select Class</Form.Label>
            <Form.Select onChange={handleClassChange} value={selectedClass}>
              <option value="">Choose a Class</option>
              {classes.map((cls) => (
                <option key={cls.className} value={cls.className}>
                  {cls.className}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Card>
      )}

      {/* Display Existing Subjects */}
      {selectedClass && (
        <Card className="p-4 shadow mb-4">
          <h4 className="text-center mb-3">
            📂 Existing Subjects in {selectedClass}
          </h4>
          {subjects.length > 0 ? (
            <ListGroup>
              {subjects.map((subject) => (
                <ListGroup.Item
                  key={subject.subjectName}
                  style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <span>{subject.subjectName}</span>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDeleteSubject(subject.subjectName)}
                  >
                    ❌ Delete
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <p className="text-center">
              No subjects available. Add a new one below.
            </p>
          )}
        </Card>
      )}

      {/* Add New Subject Form */}
      {selectedClass && (
        <Card className="p-4 shadow">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Subject Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Subject Name"
                value={subjectName}
                onChange={(e) => setSubjectName(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100">
              ➕ Add Subject
            </Button>
          </Form>
        </Card>
      )}
    </Container>
  );
};

export default SSCSubjectManager;
