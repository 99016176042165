import React, { useState, useRef, useEffect } from 'react';
import * as pdfjs from 'pdfjs-dist/build/pdf.mjs';
import { Button, Container, Form } from 'react-bootstrap';
import 'pdfjs-dist/web/pdf_viewer.css';
import { useParams } from 'react-router-dom';

import {
  startSelection,
  updateSelection,
  stopSelection,
  speakSelectedText,
} from './selectionHelpers.js';
import { handleFileUpload, loadDefaultPDF } from './pdfHelpers.js';
import {
  handleCanvasClick,
  renderPage,
  // eslint-disable-next-line no-unused-vars
  scrollToHighlightedText,
  speakFromClickedItem,
} from './pdfRenderHelpers.js';

/**
 * Make sure you have pdf.worker.min.mjs (or pdf.worker.js) in your public folder.
 * This is required for PDF.js to function in a web environment.
 */
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

const usePageImageData = () => {
  const pageImageDataRef = useRef(null);

  const getPageImageData = () => pageImageDataRef.current;

  const setPageImageData = (data) => {
    pageImageDataRef.current = data;
  };

  return { getPageImageData, setPageImageData };
};

const PdfReaderLayoutSpeaking = ({
  defaultPDFPath = '/pdfs/ssc/class6/6th-EM-English.pdf',
}) => {
  const { fileName, speakingLanguage } = useParams();
  // State: track PDF pages, current page, and loaded document
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfDoc, setPdfDoc] = useState(null);

  const [inputPage, setInputPage] = useState(pageNumber);

  // Keep track of the current page viewport (for scale, etc.)
  const [viewport, setViewport] = useState(null);

  // The single canvas for PDF rendering + highlights
  const canvasRef = useRef(null);

  /**
   * Two arrays for storing text bounding boxes:
   *   textItemsRef:   baseline references for reading/speaking
   *   hlTextItemsRef: bounding boxes used for highlighting
   */
  const textItemsRef = useRef([]);
  const hlTextItemsRef = useRef([]);

  // Store the rendered page as ImageData so we can restore it to remove highlights
  const { getPageImageData, setPageImageData } = usePageImageData();

  // Store the index of the last clicked text item (or null if none)
  // eslint-disable-next-line no-unused-vars
  const [clickedItemIndex, setClickedItemIndex] = useState(null);

  const languagesMapping = {
    English: 'en-US',
    Telugu: 'te-IN',
    Hindi: 'hi-IN',
  };

  // Speech Control State

  const [isSpeaking, setIsSpeaking] = useState(false);
  const [language, setLanguage] = useState(
    speakingLanguage ? languagesMapping[speakingLanguage] : 'en-US'
  );
  const [speed, setSpeed] = useState(0.7);

  const [selectedArea, setSelectedArea] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startPoint, setStartPoint] = useState(null);

  useEffect(() => {
    // Load a default PDF file on component mount
    if (fileName) {
      loadDefaultPDF(
        pdfjs,
        fileName,
        setPdfDoc,
        setNumPages,
        setPageNumber,
        renderPage,
        canvasRef,
        setPageImageData,
        viewport,
        setViewport,
        textItemsRef,
        hlTextItemsRef
      );
    } else if (defaultPDFPath) {
      loadDefaultPDF(
        pdfjs,
        defaultPDFPath,
        setPdfDoc,
        setNumPages,
        setPageNumber,
        renderPage,
        canvasRef,
        setPageImageData,
        viewport,
        setViewport,
        textItemsRef,
        hlTextItemsRef
      );
    }
  }, []);

  /**
   * Restore the baseline PDF image on the canvas to remove any drawn highlights.
   */
  const restorePageImage = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const imageData = getPageImageData();
    if (!imageData) {
      console.warn('No saved page image data to restore.');
      return;
    }

    const context = canvas.getContext('2d');
    context.putImageData(imageData, 0, 0);
  };

  /**
   * Clear highlight by restoring the saved page image.
   */
  const clearHighlight = () => {
    restorePageImage();
  };

  /**
   * Highlights a text item by its index & scrolls to keep it visible.
   */
  const updateHighlightByIndex = (itemIndex) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // If we haven't rendered anything yet, do nothing
    if (!viewport) {
      console.warn('No viewport found. Have we rendered a page yet?');
      return;
    }

    // Before drawing a new highlight, restore the original PDF
    restorePageImage();

    const context = canvas.getContext('2d');
    // context.fillStyle = 'rgba(255, 255, 0, 0.5)'; // Most commonly used because it stands out but does not overpower text.

    context.fillStyle = 'rgba(0, 255, 128, 0.2)'; // Highly visible and does not strain the eyes.

    // Find the item in our highlight array
    const item = hlTextItemsRef.current[itemIndex];
    if (!item) {
      console.warn(`No text item found at index ${itemIndex}.`);
      return;
    }

    // Because PDF text y is the baseline, shift upward by item.height
    const x = item.x;
    const y = item.y - item.height;
    const width = item.width;
    const height = item.height;

    // Extra Padding Padding
    const paddingBottom = item.height / 8;

    // Draw the highlight rectangle
    context.fillRect(x, y, width, height + paddingBottom);

    // Draw the finder emoji pointing to the x and y position.
    // The emoji is offset to the left and vertically centered relative to the highlight.
    const finderEmoji = '👆'; // 👆 👆🏾
    // const finderEmoji = '👉';
    const emojiFontSize = 60;
    // Set the font size; adjust the size if needed.
    context.font = `bold ${emojiFontSize}px sans-serif`;
    // Calculate offsets for the emoji position.
    const emojiX = x; // 35 pixels to the left of the highlight
    const emojiY = y + height + emojiFontSize; // vertically centered and nudged downward

    // Add a Shadow Effect
    context.shadowColor = 'rgba(0, 0, 0, 0.7)'; // Dark shadow
    context.shadowBlur = 5; // Softens the shadow
    context.shadowOffsetX = 2; // Shift shadow right
    context.shadowOffsetY = 2; // Shift shadow down

    // Use a Stroke Around the Emoji
    context.lineWidth = 3; // Stroke thickness
    context.strokeStyle = 'black'; // Dark border
    context.strokeText(finderEmoji, emojiX, emojiY); // Outline first
    context.fillText(finderEmoji, emojiX, emojiY);

    // Scroll the viewport to make the word visible (if needed)
    // scrollToHighlightedText(canvasRef, viewport, textItemsRef, itemIndex);
  };

  /**
   * Stop any ongoing speech and clear highlights.
   */
  const stopSpeaking = () => {
    console.log('Stopping speech...');
    window.speechSynthesis.cancel();
    setIsSpeaking(false);
    clearHighlight();
  };

  /**
   * Go to the next page if we're not on the last one.
   */
  const goToNextPage = async () => {
    setSelectedArea(null);
    if (!pdfDoc) {
      console.warn('No PDF document loaded. Cannot go to the next page.');
      return;
    }
    if (pageNumber < numPages) {
      stopSpeaking();
      const newPageNum = pageNumber + 1;
      setPageNumber(newPageNum);
      await renderPage(
        pdfDoc,
        newPageNum,
        canvasRef,
        setPageImageData,
        viewport,
        setViewport,
        textItemsRef,
        hlTextItemsRef
      );
      setClickedItemIndex(null); // reset any selection
    }
  };

  /**
   * Go to the previous page if we're not on the first one.
   */
  const goToPrevPage = async () => {
    setSelectedArea(null);
    if (!pdfDoc) {
      console.warn('No PDF document loaded. Cannot go to the previous page.');
      return;
    }
    if (pageNumber > 1) {
      stopSpeaking();
      const newPageNum = pageNumber - 1;
      setPageNumber(newPageNum);
      await renderPage(
        pdfDoc,
        newPageNum,
        canvasRef,
        setPageImageData,
        viewport,
        setViewport,
        textItemsRef,
        hlTextItemsRef
      );
      setClickedItemIndex(null); // reset any selection
    }
  };

  const goToPage = async (page) => {
    // Added as quick fix for goToPage not workin when page is 1
    goToNextPage();
    setSelectedArea(null);
    if (!pdfDoc) {
      console.warn('No PDF document loaded. Cannot go to the previous page.');
      return;
    }
    if (pageNumber > 1 && pageNumber < numPages) {
      stopSpeaking();
      const newPageNum = page;
      setPageNumber(newPageNum);
      await renderPage(
        pdfDoc,
        newPageNum,
        canvasRef,
        setPageImageData,
        viewport,
        setViewport,
        textItemsRef,
        hlTextItemsRef
      );
      setClickedItemIndex(null); // reset any selection
    }
  };

  // Helper functions to convert touch events to a compatible event object
  const getTouchEventData = (touchEvent) => {
    // Returns the first touch point from the event.
    return touchEvent.touches[0] || touchEvent.changedTouches[0];
  };

  return (
    <Container fluid className="p-4 text-center">
      {/* 📌 Fixed Top Control Bar */}
      <div
        className="fixed-top bg-white shadow-sm py-2 px-4 d-flex align-items-center justify-content-between flex-wrap"
        style={{
          width: '100%',
          zIndex: 1000, // Keeps it on top
          borderBottom: '2px solid #ddd',
        }}
      >
        {/* 🔄 Navigation & Controls */}
        <div className="d-flex align-items-center gap-3 flex-wrap">
          {/* 🌐 Language Selection */}
          <Form.Group className="d-flex align-items-center gap-2">
            <Form.Label className="fw-bold mb-0">🌍</Form.Label>
            <Form.Select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              style={{ maxWidth: '180px' }}
            >
              <option value="en-US">English (US)</option>
              <option value="en-IN">English (IN)</option>
              <option value="te-IN">Telugu</option>
              <option value="hi-IN">Hindi</option>
            </Form.Select>
          </Form.Group>

          {/* 📄 Page Indicator & Debugging Info */}
          <div className="d-flex align-items-center gap-3">
            {numPages > 0 && (
              <p className="mb-0 fw-bold text-dark">
                📄 Page <span className="text-primary">{pageNumber}</span> of{' '}
                <span className="text-success">{numPages}</span>
              </p>
            )}

            {/* UNCOMMENT Only for debugging purpose */}
            {/* {clickedItemIndex !== null && (
            <p className="mb-0 text-muted">
              🔍 Last Clicked Index:{' '}
              <span className="fw-bold">{clickedItemIndex}</span>
            </p>
          )} */}
          </div>

          {/* 🔢 Go to Page */}
          <Form.Group className="d-flex align-items-center gap-2">
            <Form.Label className="fw-bold mb-0">Jump to Page</Form.Label>
            <Form.Control
              type="number"
              min={1}
              max={numPages}
              value={inputPage}
              onChange={(e) => setInputPage(e.target.value)}
              style={{ width: '80px' }}
            />
            <Button
              onClick={() => {
                const page = parseInt(inputPage);
                if (!isNaN(page) && page >= 1 && page <= numPages) {
                  goToPage(page);
                }
              }}
              variant="success"
            >
              Go
            </Button>
          </Form.Group>

          {/* 🎤 Speech Control */}
          {isSpeaking ? (
            <Button onClick={stopSpeaking} variant="danger">
              🛑 Stop Speaking
            </Button>
          ) : (
            <Button
              onClick={() =>
                speakSelectedText(
                  window,
                  canvasRef,
                  getPageImageData,
                  textItemsRef,
                  updateHighlightByIndex,
                  language,
                  speed,
                  setIsSpeaking,
                  clearHighlight,
                  selectedArea,
                  viewport,
                  setClickedItemIndex,
                  speakFromClickedItem
                )
              }
              variant="danger"
            >
              🔊 Start Speaking
            </Button>
          )}

          {/* ⏪ Navigation Buttons */}
          <Button
            onClick={goToPrevPage}
            disabled={pageNumber <= 1 || !pdfDoc}
            variant="primary"
          >
            ◀️ Previous
          </Button>
          <Button
            onClick={goToNextPage}
            disabled={pageNumber >= numPages || !pdfDoc}
            variant="primary"
          >
            Next ▶️
          </Button>

          {/* 🔊 Speed Control */}
          <Form.Group className="d-flex align-items-center gap-2">
            <Form.Label className="fw-bold mb-0">🔊 Speed</Form.Label>
            <Form.Range
              min="0.5"
              max="2.0"
              step="0.1"
              value={speed}
              onChange={(e) => setSpeed(parseFloat(e.target.value))}
              style={{ width: '100px' }} // Adjusted width for better appearance
            />
            <span className="fw-bold text-primary">{speed.toFixed(1)}x</span>{' '}
            {/* Display Speed */}
          </Form.Group>
        </div>
      </div>

      {/* 🔳 Content Frame (Prevents Overlap & Centers Content) */}
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ paddingTop: '50px', minHeight: '100vh' }}
      >
        {/* 📂 PDF Upload Field */}
        <div
          className="border rounded shadow-sm p-3 mb-4"
          style={{
            maxWidth: '600px',
            width: '100%',
            backgroundColor: '#f8f9fa',
          }}
        >
          <Form.Group controlId="formFile">
            <Form.Label className="fw-bold">📂 Upload PDF File</Form.Label>
            <Form.Control
              type="file"
              accept="application/pdf"
              onChange={(event) =>
                handleFileUpload(
                  event,
                  pdfjs,
                  setPdfDoc,
                  setNumPages,
                  setPageNumber,
                  renderPage
                )
              }
            />
          </Form.Group>
        </div>

        {/* 🖼️ PDF Display (Canvas Frame) */}
        <div className="border p-4 mt-3" style={{ position: 'relative' }}>
          <canvas
            ref={canvasRef}
            onMouseDown={(event) =>
              startSelection(
                event,
                canvasRef,
                viewport,
                setStartPoint,
                setIsDragging
              )
            }
            onMouseMove={(event) =>
              updateSelection(
                event,
                canvasRef,
                viewport,
                isDragging,
                startPoint,
                setSelectedArea
              )
            }
            onMouseUp={(event) =>
              stopSelection(
                event,
                selectedArea,
                setIsDragging,
                handleCanvasClick(
                  event,
                  window,
                  canvasRef,
                  getPageImageData,
                  textItemsRef,
                  updateHighlightByIndex,
                  setClickedItemIndex,
                  language,
                  speed,
                  setIsSpeaking,
                  clearHighlight
                )
              )
            }
            // Touch event handlers for mobile devices
            onTouchStart={(event) => {
              const touch = getTouchEventData(event);
              startSelection(
                touch,
                canvasRef,
                viewport,
                setStartPoint,
                setIsDragging
              );
            }}
            onTouchMove={(event) => {
              const touch = getTouchEventData(event);
              updateSelection(
                touch,
                canvasRef,
                viewport,
                isDragging,
                startPoint,
                setSelectedArea
              );
            }}
            onTouchEnd={(event) => {
              const touch = getTouchEventData(event);
              stopSelection(
                touch,
                selectedArea,
                setIsDragging,
                handleCanvasClick(
                  touch,
                  window,
                  canvasRef,
                  getPageImageData,
                  textItemsRef,
                  updateHighlightByIndex,
                  setClickedItemIndex,
                  language,
                  speed,
                  setIsSpeaking,
                  clearHighlight
                )
              );
            }}
            style={{ width: '100%', border: '1px solid black' }}
          />
          {selectedArea && viewport && (
            <div
              style={{
                position: 'absolute',
                left:
                  selectedArea.x *
                  (canvasRef.current.clientWidth / viewport.width),
                top:
                  selectedArea.y *
                  (canvasRef.current.clientHeight / viewport.height),
                width:
                  selectedArea.width *
                  (canvasRef.current.clientWidth / viewport.width),
                height:
                  selectedArea.height *
                  (canvasRef.current.clientHeight / viewport.height),
                border: '2px dashed red',
                pointerEvents: 'none',
              }}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default PdfReaderLayoutSpeaking;
