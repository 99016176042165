export const questionsAndAnswersNDLessonSix = [
  {
    question: 'Where was Injun Joe found and in what condition?',
    answer:
      'Injun Joe was found dead in the cave with his face close to the door as if his eyes wanted to see the light and the cheer of the free world outside.',
  },
  {
    question:
      "Describe Tom's feelings for Injun Joe's death when he saw him for the last time.",
    answer:
      "Tom felt sorry at Injun Joe's death, but at the same time, he experienced a sigh of relief as Tom always had a fear that Injun Joe would kill him for testifying against him in the court.",
  },
  {
    question: 'What did the boys decide to do with the treasure?',
    answer:
      "Tom and Huck planned to hide the treasure in Widow Douglas' woodshed. They decided to count the money the next morning and divide it among themselves.",
  },
  {
    question: 'Why did Aunt Polly blush crimson red?',
    answer:
      'Aunt Polly blushed crimson red because she felt embarrassed by the way Tom and Huck appeared. They were dusty while all the other guests at the party were dressed in their best.',
  },
  {
    question: 'Why did Tom interrupt Mrs. Douglas?',
    answer:
      'Mrs. Douglas wished to educate Huck, provide shelter for him, and later set up a business for him when she had enough money. Tom interrupted her, saying that Huck would not need money as he was rich.',
  },
  {
    question: "What was the effect of Tom's actions on the gathering?",
    answer:
      'When Mrs. Douglas expressed her interest in adopting and educating Huck, Tom interrupted, saying Huck was rich enough to take good care of himself. The gathering was initially quiet, looked at each other in surprise, and then at Huck inquiringly.',
  },
  {
    question: "Why did Huck suffer in Mrs. Douglas' house?",
    answer:
      "Huck was not used to being washed, dressed, and combed well every day. Mrs. Douglas' servants kept him neat and tidy. He was given spotless, clean sheets to sleep in, use a knife and fork, a napkin, a cup and plate to eat from, and he was taught to speak properly and attend church. All these tasks seemed difficult for Huck, hence, he suffered in Mrs. Douglas' house.",
  },
];
