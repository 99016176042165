import React from 'react';
import { useParams } from 'react-router-dom';
import SyllabusForm from './SyllabusForm.js';
import PreschoolSyllabus from './preSchool/PreschoolSyllabus.js';
import CBSESyllabus from './cbsc/CBSESyllabus.js';
import ICSESyllabus from './icsc/ICSESyllabus.js';
import SSCSchools from './ssc/SSCSchools.js';

const SyllabusManager = () => {
  const { syllabus } = useParams(); // Get syllabus type from URL

  // Define valid syllabus types
  //   const validSyllabuses = ['SSC', 'CBSE', 'ICSE', 'Preschool'];

  return (
    <div>
      {/* <h1>Syllabus is {syllabus}</h1> */}
      {/* Navigate to appropriate component */}
      {syllabus === 'preschool' ? (
        <PreschoolSyllabus />
      ) : syllabus === 'ssc' ? (
        <SSCSchools />
      ) : syllabus === 'cbse' ? (
        <CBSESyllabus />
      ) : syllabus === 'icse' ? (
        <ICSESyllabus />
      ) : (
        <SyllabusForm syllabusType={syllabus} />
      )}
    </div>
  );
};

export default SyllabusManager;
