export const questionsAndAnswersNDLessonSix = [
  {
    question:
      'Why did Beth laugh merrily when she looked out of her window on Christmas morning?',
    answer:
      'Beth laughed merrily when she saw the snow-maiden that Jo and Laurie had created, with holly in her hair and a basket of fruits in her hands.',
  },
  {
    question: 'Describe the Christmas presents the girls received.',
    answer:
      'Jo received her favorite storybook, Amy got an engraved copy of Madonna and Child, Meg received her first silk dress, and Beth was given some delicious grapes.',
  },
  {
    question: 'Who brought the best gift? What was the gift?',
    answer:
      "Laurie brought the best gift, which was Mr. March, the girls' father.",
  },
  {
    question: 'How did Aunt March help Meg in making up her mind?',
    answer:
      'Aunt March threatened Meg, saying that she would not give her a single penny if she married Mr. Brooke. Meg disliked being ordered and, as a result, made up her mind to marry Brooke.',
  },
  {
    question: "How did Jo's parents and sisters react to her awful news?",
    answer:
      "Jo's parents left the room immediately, while her sisters were delighted upon hearing her awful news.",
  },
];
