export const questionsAndAnswersLessonFour = [
  {
    question: 'Describe Gregor after the transformation.',
    answer:
      'Gregor had changed into a monstrous verminous bug. He had a hard back now and a brown arched abdomen divided into bow-like sections. His numerous thin legs flickered helplessly, and he was unable to turn left or right in bed.',
  },
  {
    question:
      "What was Gregor's profession? Describe what he felt about his job.",
    answer:
      'Gregor was a travelling salesman. He was not happy with his job. He found it quite stressful, for he had to deal with uncertain train schedules, bad food, and short-term, insincere business connections.',
  },
  {
    question:
      'Why did Gregor continue with his job? What was his goal in life?',
    answer:
      "Gregor continued with his job for his parents' sake; else he would have quit a long time ago. His goal in life was to save enough money to pay off his parents' debt. This would take him another five or six years to accomplish.",
  },
  {
    question:
      'What was Gregor thinking about when his mother called out to him?',
    answer:
      "Gregor was thinking that the sample collection of cloth goods hadn't been packed yet and he himself was not feeling rested. He also realized that there would be an argument with the manager at work for showing up late.",
  },
  {
    question: "Why couldn't Gregor get out of his bed?",
    answer:
      'Gregor could not get out of his bed because he was now unusually wide. He needed arms and hands to push himself upright, but he had only many small limbs over which he had no control.',
  },
];
