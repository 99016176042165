export const numberSystemLessonOneExampleOne = [
  // Example 1
  { number: 856 },
  { number: 7493 },
  { number: 23648 },
  { number: 65019 },
  { number: 92006 },
  { number: 19090 },
];

export const numberSystemLessonOneExampleTwo = [
  //   Example 2
  { number: 715496 },
  { number: 52807060 },
  { number: 3406815 },
  { number: 90624078 },
  { number: 479156824 },
];

export const numberSystemLessonOneExampleThree = [
  //   Example 3
  { number: 78765 },
];

export const numberSystemLessonOneExampleFour = [
  //   Example 4
  { number: 2374186 },
];

export const numberSystemLessonOneExampleFive = [
  //   Example 5
  { number: 93784516 },
];

export const numberSystemLessonOneExercise1A2 = [
  //   Exercise 1A
  { number: 808080 },
  { number: 1507063 },
  { number: 8708109 },
  { number: 21405063 },
  { number: 30303103 },
  { number: 100606368 },
];
