export const questionsAndAnswersNDLessonTwo = [
  {
    question:
      'Who was Huckleberry Finn? What did Tom and he decide to do at night?',
    answer:
      "Huckleberry Finn was Tom's friend. He never went to school as his father was a drunkard. He was an idler and badly dressed. Both of them decided to sneak out at night and meet in the graveyard.",
  },
  {
    question: "How did Tom try to catch Becky Thatcher's attention?",
    answer:
      "Tom tried to grab Becky Thatcher's attention by offering her a peach. When she refused to accept, he drew something on his slate, covering it with his left hand. This act aroused her curiosity, and she started a conversation with Tom.",
  },
  {
    question: 'How did Tom and Joe Harper amuse themselves in the class?',
    answer:
      "As Tom was getting sleepy and distracted, he took out a box with a little tick inside it. Tom watched it crawling. Joe Harper too joined him by poking at it with pins. They drew a partition on the slate and watched it moving into each other's territory. This way, they had fun in the class.",
  },
  {
    question: 'What did Injun Joe tell Potter?',
    answer:
      'Injun Joe told Potter that he had murdered the doctor in a fit of rage. The doctor had hit Potter with a tombstone, and Potter had staggered in a drunken state and stabbed him.',
  },
  {
    question: 'What were the two things that left Tom upset?',
    answer:
      "Firstly, Aunt Polly was heartbroken that Tom had sneaked out and stayed outdoors the whole night. Her tears made Tom sad. He apologized to Aunt Polly and went to school. Secondly, at school, Becky returned his brass knob in a paper. These two things filled Tom's heart with sorrow.",
  },
  {
    question: 'How did Tom "ease his conscience"?',
    answer:
      'Tom felt guilty about the innocent Potter being in jail. Whenever he could, he smuggled small gifts to Potter through the prison window. These gifts greatly helped Tom to ease his conscience.',
  },
];
