import { openDB } from 'idb';

const DB_NAME = 'SSCSchoolDB';
const DB_VERSION = 1;
const SCHOOL_STORE = 'ssc_schools';

export const initDB = async () => {
  return openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(SCHOOL_STORE)) {
        const store = db.createObjectStore(SCHOOL_STORE, {
          keyPath: 'schoolName',
        });
        store.createIndex('schoolName', 'schoolName', { unique: true });
      }
    },
  });
};

/** 📌 Get all schools */
export const getAllSchools = async () => {
  const db = await initDB();
  return db.getAll(SCHOOL_STORE);
};

/** 📌 Create a new school with SSC syllabus as default */
export const addSchool = async (schoolName) => {
  const db = await initDB();
  const existingSchool = await db.get(SCHOOL_STORE, schoolName);

  if (!existingSchool) {
    await db.put(SCHOOL_STORE, {
      schoolName,
      syllabus: 'SSC', // Default syllabus
      classes: [],
    });
  }
};

/** 📌 Delete a school */
export const deleteSchool = async (schoolName) => {
  const db = await initDB();
  await db.delete(SCHOOL_STORE, schoolName);
};

/** 📌 Get all classes in a school */
export const getAllClasses = async (schoolName) => {
  const db = await initDB();
  const school = await db.get(SCHOOL_STORE, schoolName);
  return school ? school.classes : [];
};

/** 📌 Create a new class under a school */
export const addClass = async (schoolName, className, classColor) => {
  const db = await initDB();
  const existingSchool = await db.get(SCHOOL_STORE, schoolName);

  if (existingSchool) {
    const existingClass = existingSchool.classes.find(
      (cls) => cls.className === className
    );

    if (!existingClass) {
      const updatedSchool = {
        ...existingSchool,
        classes: [
          ...existingSchool.classes,
          { className, color: classColor, subjects: [] },
        ],
      };

      await db.put(SCHOOL_STORE, updatedSchool);
    }
  }
};

/** 📌 Delete a class from a school */
export const deleteClass = async (schoolName, className) => {
  const db = await initDB();
  const existingSchool = await db.get(SCHOOL_STORE, schoolName);

  if (existingSchool) {
    const updatedSchool = {
      ...existingSchool,
      classes: existingSchool.classes.filter(
        (cls) => cls.className !== className
      ),
    };

    await db.put(SCHOOL_STORE, updatedSchool);
  }
};

/** 📌 Add a subject to a class */
export const addSubject = async (
  schoolName,
  className,
  subjectName,
  subjectColor
) => {
  const db = await initDB();
  const existingSchool = await db.get(SCHOOL_STORE, schoolName);

  if (existingSchool) {
    const updatedClasses = existingSchool.classes.map((cls) =>
      cls.className === className
        ? {
            ...cls,
            subjects: [
              ...cls.subjects,
              { subjectName, color: subjectColor, lessons: [] },
            ],
          }
        : cls
    );

    const updatedSchool = { ...existingSchool, classes: updatedClasses };
    await db.put(SCHOOL_STORE, updatedSchool);
  }
};

/** 📌 Delete a subject from a class */
export const deleteSubject = async (schoolName, className, subjectName) => {
  const db = await initDB();
  const existingSchool = await db.get(SCHOOL_STORE, schoolName);

  if (existingSchool) {
    const updatedClasses = existingSchool.classes.map((cls) =>
      cls.className === className
        ? {
            ...cls,
            subjects: cls.subjects.filter(
              (subject) => subject.subjectName !== subjectName
            ),
          }
        : cls
    );

    const updatedSchool = { ...existingSchool, classes: updatedClasses };
    await db.put(SCHOOL_STORE, updatedSchool);
  }
};

/** 📌 Add a lesson to a subject */
export const addLesson = async (
  schoolName,
  className,
  subjectName,
  lessonName,
  lessonColor
) => {
  const db = await initDB();
  const existingSchool = await db.get(SCHOOL_STORE, schoolName);

  if (existingSchool) {
    const updatedClasses = existingSchool.classes.map((cls) =>
      cls.className === className
        ? {
            ...cls,
            subjects: cls.subjects.map((subject) =>
              subject.subjectName === subjectName
                ? {
                    ...subject,
                    lessons: [
                      ...subject.lessons,
                      { lessonName, color: lessonColor, activities: [] },
                    ],
                  }
                : subject
            ),
          }
        : cls
    );

    const updatedSchool = { ...existingSchool, classes: updatedClasses };
    await db.put(SCHOOL_STORE, updatedSchool);
  }
};

/** 📌 Delete a lesson from a subject */
export const deleteLesson = async (
  schoolName,
  className,
  subjectName,
  lessonName
) => {
  const db = await initDB();
  const existingSchool = await db.get(SCHOOL_STORE, schoolName);

  if (existingSchool) {
    const updatedClasses = existingSchool.classes.map((cls) =>
      cls.className === className
        ? {
            ...cls,
            subjects: cls.subjects.map((subject) =>
              subject.subjectName === subjectName
                ? {
                    ...subject,
                    lessons: subject.lessons.filter(
                      (lesson) => lesson.lessonName !== lessonName
                    ),
                  }
                : subject
            ),
          }
        : cls
    );

    const updatedSchool = { ...existingSchool, classes: updatedClasses };
    await db.put(SCHOOL_STORE, updatedSchool);
  }
};

/** 📌 Add an activity to a lesson */
export const addActivity = async (
  schoolName,
  className,
  subjectName,
  lessonName,
  activity
) => {
  const db = await initDB();
  const existingSchool = await db.get(SCHOOL_STORE, schoolName);

  if (existingSchool) {
    const updatedClasses = existingSchool.classes.map((cls) =>
      cls.className === className
        ? {
            ...cls,
            subjects: cls.subjects.map((subject) =>
              subject.subjectName === subjectName
                ? {
                    ...subject,
                    lessons: subject.lessons.map((lesson) =>
                      lesson.lessonName === lessonName
                        ? {
                            ...lesson,
                            activities: [...lesson.activities, activity],
                          }
                        : lesson
                    ),
                  }
                : subject
            ),
          }
        : cls
    );

    const updatedSchool = { ...existingSchool, classes: updatedClasses };
    await db.put(SCHOOL_STORE, updatedSchool);
  }
};

/** 📌 Delete an activity from a lesson */
export const deleteActivity = async (
  schoolName,
  className,
  subjectName,
  lessonName,
  activityName
) => {
  const db = await initDB();
  const existingSchool = await db.get(SCHOOL_STORE, schoolName);

  if (existingSchool) {
    const updatedClasses = existingSchool.classes.map((cls) =>
      cls.className === className
        ? {
            ...cls,
            subjects: cls.subjects.map((subject) =>
              subject.subjectName === subjectName
                ? {
                    ...subject,
                    lessons: subject.lessons.map((lesson) =>
                      lesson.lessonName === lessonName
                        ? {
                            ...lesson,
                            activities: lesson.activities.filter(
                              (activity) =>
                                activity.activityName !== activityName
                            ),
                          }
                        : lesson
                    ),
                  }
                : subject
            ),
          }
        : cls
    );

    const updatedSchool = { ...existingSchool, classes: updatedClasses };
    await db.put(SCHOOL_STORE, updatedSchool);
  }
};
