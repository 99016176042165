export const questionsAndAnswersNDLessonTwo = [
  {
    question:
      "Describe the work Jo had to do at Aunt March's place? What was the real attraction at March's place?",
    answer:
      "Jo helped Aunt March, who was lame and needed an active person to look after her. The real attraction at Aunt March's place was her library. Jo loved curling up in a chair and reading poetry and history while Aunt March took a nap.",
  },
  {
    question: 'Why was Susie Perkins punished by her teacher, Mr. Davis?',
    answer:
      'Mr. Davis punished Susie Perkins by pulling her ear and making her stand for half an hour. He caught Susie with a drawing of him with a huge nose and a hump, which he found disrespectful.',
  },
  {
    question: "Why didn't old Mr. Laurence like Laurie to play the piano?",
    answer:
      "Mr. Laurence didn't like Laurie to play the piano because Laurie's father had married an Italian lady who was a musician, which displeased him. After their death, Mr. Laurence became very protective of Laurie and did not want him to become a musician like his mother.",
  },
  {
    question:
      'What was the present Beth received from Mr. Laurence? Whom did it belong to?',
    answer:
      "Mr. Laurence replaced the March family's old broken piano with a luxurious cabinet piano that had once belonged to his deceased granddaughter.",
  },
  {
    question: 'Why does Amy borrow money from Meg?',
    answer:
      'Amy borrows money from Meg to buy some pickled limes, saying that limes were the latest fashion at her school.',
  },
  {
    question: 'Why was Jo furious? How was Amy responsible?',
    answer:
      "Jo was furious when she discovered that her book—a diary containing several little fairy tales that she had been writing for years—had gone missing. Amy admitted that she had thrown Jo's book into the fire. This made Jo extremely angry, and she declared that she would never forgive Amy for that act.",
  },
];
