export const questionsAndAnswersLessonEleven = [
  {
    question: 'Describe the appearance of the horse.',
    answer:
      'The horse was old, lame, and half blind. He had a shaggy coat and looked tired. He was sad about the fact that he is old now.',
  },
  {
    question:
      "Were those days happy for the horse in the lines 'things of the day that used to be'. Support your answer.",
    answer:
      'Yes, the horse was happy in those days when he used to travel with his master, who was fond of him, through the woods and lanes.',
  },
  {
    question: 'Do you think the horse is sad now?',
    answer:
      'Yes, the horse is sad now as he had become old and his master prefers motor cars to him. He sadly remembers his good old days.',
  },
  {
    question: 'What gesture made the horse sigh?',
    answer:
      "The kind touch of the friendly passer-by's hand on his mane made the horse sigh. The horse was happy when a friendly soul stopped to say a friendly word to him.",
  },
  {
    question:
      'What message does the speaker convey to the reader through the poem?',
    answer:
      'The message the speaker conveys to the reader through the poem is to be kind.',
  },
];
