export const questionsAndAnswersLessonSeventeen = [
  {
    question:
      'How long ago was the road shut? Why would you not know it was there once?',
    answer:
      'The road was shut seventy years ago. We would not know that it was there once because the way is totally covered with coppice and heath and thin anemones. They have planted trees on the road after shutting it.',
  },
  {
    question: 'How did the speaker describe the late summer evening?',
    answer:
      'The speaker describes the late summer evening as one having cool night air blowing around the pools filled with trouts.',
  },
  {
    question: 'Why did the speaker say that the badgers roll at ease?',
    answer:
      '"The badgers roll at ease" means that the badgers are comfortable as it is their natural habitat, and now they can get ample food to eat and plenty of space to rest.',
  },
  {
    question:
      'What two things would you hear if you enter the woods on a late summer evening?',
    answer:
      'The two things we could hear upon entering the woods on a late summer evening are the beat of a horse’s feet and the swish of a skirt in the dew.',
  },
  {
    question:
      'Is there an element of ghostly in the poem? Pick out the lines that establish this and explain how.',
    answer:
      'Yes, there is an element of the ghostly in the poem. In the lines: "You will hear the beat of a horse\'s feet, And the swish of a skirt in the dew, Steadily cantering through The misty solitudes." As though they perfectly knew The old lost road through the woods." These lines evoke an eerie and spooky feeling in the reader\'s mind.',
  },
  {
    question: 'Why did otters fear men if they saw them?',
    answer:
      'The otters would fear men if they saw them because they are not accustomed to seeing so many humans around in the woods. They have no idea what harm mankind can bring to them.',
  },
];
