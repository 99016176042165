export const questionsAndAnswersLessonTwo = [
  {
    question: "What effect did the woman's song have on the speaker?",
    answer:
      "The woman's song had a deep effect on the speaker as it led him to think of his mother and memories of his childhood.",
  },
  {
    question: 'What was the child doing?',
    answer:
      "The child was sitting under the piano pressing his mother's small, poised feet while she sang.",
  },
  {
    question: "Why did the speaker's heart weep?",
    answer:
      "The speaker's heart beats as he missed his childhood days with his mother.",
  },
  {
    question:
      'What kind of songs did the speaker say were sang and on what day?',
    answer:
      'Songs with booming and tinkling sounds were sung on Sunday evenings in the cosy parlour of their house.',
  },
  {
    question: 'What did the speaker long for?',
    answer: 'The speaker longs for his past childhood days.',
  },
];
