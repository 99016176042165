import { greenwoodClasses } from './schoolData/greenwood/classes/greenwoodClasses.js';
import { springfieldClasses } from './schoolData/springfield/classes/springfieldClasses.js';
import { stJosephsHighSchoolClasses } from './schoolData/stJosephsHighSchool/classes/stJosephsHighSchoolClasses.js';
import { telanganaGovernmentDistributon } from './schoolData/telanganaGovernmentDistributon/classes/telanganaGovernmentDistributon.js';

export const schoolDetails = [
  {
    schoolName: 'Telangana Government Distributon',
    color: '#A3E4D7',
    classes: telanganaGovernmentDistributon,
  },
  {
    schoolName: 'St Josephs High School',
    color: '#A3E4D7',
    classes: stJosephsHighSchoolClasses,
  },
  {
    schoolName: 'Greenwood High',
    color: '#A3E4D7',
    classes: greenwoodClasses,
  },
  {
    schoolName: 'Springfield Public',
    color: '#F9E79F',
    classes: springfieldClasses,
  },
];
