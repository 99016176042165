import { englishLessons } from '../lessons/class3/englishLessons.js';
import { socialStudiesLessons } from '../lessons/class3/socialStudiesLessons.js';

export const class3Subjects = [
  {
    subjectName: 'English',
    color: '#FFB6C1',
    lessons: englishLessons,
  },
  {
    subjectName: 'Social Studies',
    color: '#FFB6C1',
    lessons: socialStudiesLessons,
  },
];
