import React, { useState, useRef } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';

const ScreenRecorder = ({ showRecordingButton, onRecordingComplete }) => {
  const [recording, setRecording] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [videoUrl, setVideoUrl] = useState(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);

  const startRecording = async () => {
    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true,
      });
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 44100,
        },
      });

      const combinedStream = new MediaStream([
        ...screenStream.getVideoTracks(),
        ...screenStream.getAudioTracks(),
        ...audioStream.getAudioTracks(),
      ]);

      mediaRecorderRef.current = new MediaRecorder(combinedStream, {
        mimeType: 'video/webm',
      });

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: 'video/webm' });
        const url = URL.createObjectURL(blob);
        setVideoUrl(url);
        if (onRecordingComplete) {
          onRecordingComplete(blob);
        }

        // Create a timestamped filename
        const now = new Date();
        now.setMinutes(now.getMinutes() + 330); // Adjusting for IST (+5:30 hours)
        const timestamp = now.toISOString().replace(/[:.-]/g, '_');
        const filename = `recording_${timestamp}_IST.webm`;

        // Create a download link
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };

      mediaRecorderRef.current.start();
      setRecording(true);
    } catch (error) {
      console.error('Error starting recording: ', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && recording) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          {showRecordingButton && (
            <Button
              variant={recording ? 'danger' : 'primary'}
              onClick={recording ? stopRecording : startRecording}
            >
              {recording ? 'Stop Recording' : 'Start Recording'}
            </Button>
          )}
        </Col>
      </Row>
      {/* Comment Showing Viewo Preview For Now */}
      {/* {videoUrl && (
        <Row>
          <Col>
            <video src={videoUrl} controls width="100%" />
          </Col>
        </Row>
      )} */}
    </Container>
  );
};

export default ScreenRecorder;
