import { SchoolActivityTypes } from '../../../../../../../enums/constants.js';

export const lessons6Exercise6A = {
  lessonName: 'Lesson 6: Exercise 6 A',
  color: '#90EE90',
  activities: [
    {
      activityName: 'Simplify',
      activityType: SchoolActivityTypes.MATHS_PRACTICE_EXERCISE_SOLUTION,
      list: [
        {
          question: '(i) 24 - 20 ÷ 5 x 2',
          prompt: '6\\6 A\\1_Simplify__i__24___20___5_x_2.js',
        },
        {
          question: '(ii) 19 + 12 ÷ 6 - 2 x 3',
          prompt: '6\\6 A\\1_Simplify__ii__19___12___6___2_x_3.js',
        },
        {
          question: '(iii) 21 - 12 ÷ 4 - 3 x (-7)',
          prompt: '6\\6 A\\1_Simplify__iii__21__12___4___3_x___7_.js',
        },
        {
          question: '(iv) 23 + 18 ÷ 6 + 3 x (4)',
          prompt: '6\\6 A\\1_Simplify__iv__23___18___6___3_x__4_.js',
        },
        {
          question: '(v) 30 - {8 ÷ (2 x 3 - 4)}',
          prompt: '6\\6 A\\1_Simplify__v__30____8____2_x_3___4__.js',
        },
        {
          question: '(vi) 9 - {5 x 3 + 12 x 16 ÷ (–8)}',
          prompt: '6\\6 A\\1_Simplify__vi__9____5_x_3___12_x_16_____8__.js',
        },
        {
          question: '(vii) 16 - {9 - 24 ÷ (8 + 6 x 2 - 16)}',
          prompt:
            '6\\6 A\\1_Simplify__vii__16____9___24____8___6_x_2___16__.js',
        },
        {
          question: '(viii) 12 - [13 - {9 + 10 ÷ (7 - 2)}]',
          prompt: '6\\6 A\\1_Simplify__viii__12____13____9___10____7___2___.js',
        },
        {
          question: '(ix) 4 - [16 - {6 + 12 ÷ (9 - 6)}]',
          prompt: '6\\6 A\\1_Simplify__ix__4____16____6___12____9___6___.js',
        },
        {
          question: '(x) 28 - [15 - {8 + 20 ÷ (7 - 8 - 6) }]',
          prompt:
            '6\\6 A\\1_Simplify__x__28____15____8___20____7___8__6____.js',
        },
      ],
      lang: 'English',
      color: '#ccffcc',
    },
  ],
};
