export const questionsAndAnswersLessonFifteen = [
  {
    question: 'Who was the man who came to visit Holmes? What did the man do?',
    answer:
      'The man who had come to visit Holmes was Mr. Alexander Holder. He was the head of the Bank of Holder and Stevenson located in Threadneedle Street in London. He had reluctantly agreed to a secret transaction which involved a lot of money and a priceless item of jewellery.',
  },
  {
    question: 'How had Mr. Holder acquired the Crown of Diamonds?',
    answer:
      'Mr. Holder had acquired the Crown of Diamonds as an assurance against a loan of fifty thousand pounds from one of the noblest personages in England, who had promised to repay the money in four days.',
  },
  {
    question:
      "How many people lived in or frequently visited Mr. Holder's house? Name them.",
    answer:
      "Mr. Holder lived in a house called Fairbank in Streatham with his son Arthur and his orphaned niece, Mary. The only other people in the house were his trusted servants. He had only one frequent visitor, who was Arthur's friend, Sir George Burnwell.",
  },
  {
    question:
      'Who was arrested on suspicion of having stolen three of the diamonds? How did the person react when he was arrested?',
    answer:
      'Arthur was arrested on suspicion of having stolen three of the diamonds. Arthur did not claim to be innocent and went quietly with the police.',
  },
  {
    question: 'What did Holmes dress up as and why?',
    answer:
      "Holmes dressed up as a beggar, went to Burnwell's house, and asked the servant for some old clothes. Holmes was given some clothes and shoes from which he collected valuable evidence to solve the case.",
  },
  {
    question: 'How did Holmes solve the case?',
    answer:
      "Holmes first compared the shoeprints in the snow with Burnwell's shoes, and they were a perfect match. This proved that Burnwell was the thief. Holmes then took back the stolen diamonds from Burnwell for three thousand pounds. The case was solved, and both parties were saved from public disgrace.",
  },
];
